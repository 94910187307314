$gray_cultured: #f5f6fa;
$white: #ffffff;
$white_20: rgba(255, 255, 255, 0.2);
$white_4: rgba(255, 255, 255, 0.4);
$celtic-blue: #276cbd;
$bright_gray: #eeeeee;
$color_outer_space_approx: #222b2e;
$green-blue: #206cb4;
$magic_potion: #ff445a;
$black_8: rgba(0, 0, 0, 0.08);
$black_coral: #59626b;
$bright_gray-1: #e6edf1;
$ghost-white: #f9fafa;
$mercury: #e5e5e5;
$color_alto_approx: #dddddd;
$color_endeavour_30_approx: rgba(0, 105, 181, 0.3);
$color_gray_chateau_approx: #95a0b1;
$color_bittersweet_approx: #fc5f5f;
$color_endeavour_approx: #0069b5;
$black: #000000;
$color_ocean_green_approx: #43b886;
$color_cerise_red_approx: #ef3e6d;
$color_bright_sun_approx: #29d7df;
$color_pacific_blue_approx: #0090d0;
$color_buttercup_approx: #f4b616;
$color_gamboge_approx: #dba20f;
$concrete: #f2f2f2;
$color_link_water_approx: #edf4fa;
$color_yellow_orange_approx: #f99f42;
$color_cornflower_blue_approx: #518ef8;
$color_mischka_approx: #d5d8e7;
$color_marigold_yellow_approx: #f6e176;
$color_iron_approx: #d9dcde;
$color_mango_tango_approx: #da7c00;
$color_outer_space_50_approx: rgba(34, 43, 46, 0.5);
$color_nevada_approx: #606f80;
$color_japanese_laurel_approx: #008000;
$color_heather_approx: #b7c1cc;
$black_15: rgba(0, 0, 0, 0.15);
$black_6: rgba(0, 0, 0, 0.6);
$black_06: rgba(0, 0, 0, 0.06);
$black_20: rgba(0, 0, 0, 0.06);
$color_cutty_sark_approx: #5c6363;
$color_theme_blue_color: #2376c4;
$color_theme_blue_color_hover: #3d85ca;
$blue_denim: #2775bd;
$spaces_black: #525e6b;
$alice_light_blue: #fafbfd;
$alice_light_blue_2: #f6f8fa;
$abbey_light: #4e5558;
$light_blue_04: rgba(39, 108, 189, 0.04);
$light_blue_10: rgba(39, 108, 189, 0.1);
$light_blue2_08: rgba(35, 118, 196, 0.06);
$dark_gray70_left_20: #5d6366;
$light_grey_pagination: #ececec;
$chat_close_btn_shadow: #b9b9b9;
$bonuses_range_slider: #e1e1e1;
$blue_shade_grey: #b0b8c5;
$submit_btn_green: #36ce8d;
$submit_btn_green_hover: #27ae75;
$cancel_btn_gray: #dee0e0;
$blue_commision_profile: #27a8f0;
$lost_btn_bg_color: #e2e9ef;
$navy_blue: #31384f;
$white_gradient: rgba(248, 237, 243, 0.5);
$gray_gradient: rgba(229, 231, 243, 0.6);
$green_gradient: rgb(99, 181, 183);
$blue_gradient: rgb(55, 144, 216);

/* Breakpoint define*/
$breakpoints: (
  'desktop-lg': (
    max-width: 1779px,
  ),
  'desktop-md': (
    max-width: 1440px,
  ),
  'desktop-xs': (
    max-width: 1199px,
  ),
  'tablet-xl': (
    max-width: 1024px,
  ),
  'tablet-md': (
    max-width: 991px,
  ),
  'phone-xl': (
    max-width: 767px,
  ),
  'phone-md': (
    max-width: 575px,
  ),
  'phone-sm': (
    max-width: 374px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@import './reuse';

html {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: $gray_cultured;
  padding-top: 73px;
  font-family: 'Roboto';
  font-weight: 400;
  color: $color_outer_space_approx;
  @include respond-to('phone-md') {
    padding-top: 50px;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0px;
  font-weight: normal;
}

a,
button,
input[type='submit'] {
  cursor: pointer;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('./fonts/Roboto/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('./fonts/Roboto/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('./fonts/Roboto/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('./fonts/Roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('./fonts/Roboto/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('./fonts/Roboto/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('./fonts/Roboto/Roboto-Bold.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*common-css*/

input,
select,
textarea {
  outline: none !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.card_design {
  background-color: $white;
  border-radius: 16px;
  // margin-bottom: 40px;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
}

.custom_date_field,
.cs_form_row .custom_date_field {
  position: relative;
}

.custom_date_field img,
.cs_form_row .custom_date_field img {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 1;
}

.custom_date_field.with_label img,
.cs_form_row .custom_date_field.with_label img {
  top: 35px;
}

.custom_date_field input,
.cs_form_row .custom_date_field input {
  padding-left: 38px;
}

.add_people_btn,
.add_bonus_btn {
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 400;
  color: $white;
  border: 1px solid #2a90ea;
  border-radius: 5px;
  text-decoration: none;
  padding: 8px 18px;
  font-size: 12px;
  transition: 0.5s;
  cursor: pointer;
  background-color: #2a90ea;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
}

.add_people_btn:hover,
.add_bonus_btn:hover {
  background-color: #2382d7;
  border: 1px solid #2382d7;
}

.cs_tab {
  display: none;

  &.tab-active {
    display: block;
  }
}

.cs_tab_menu {
  ul {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    overflow: auto;

    li {
      margin-right: 7px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .tab-a {
    font-family: 'Roboto';
    display: inline-block;
    background-color: transparent;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 12px;
    padding: 6px 13px;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid $bright_gray;
    color: $color_outer_space_approx;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 8px;
    box-shadow: inset 0 3px 7px rgba(154, 160, 185, 0.05);

    .count {
      margin-left: 5px;
      color: $green-blue;
      transition: 0.5s;
    }

    &.active-a {
      background-color: #2a90ea;
      border: 1px solid #2a90ea;
      color: $white;

      .count {
        color: $white;
      }
    }
  }
}

.fm_error {
  display: inline-block;
  font-size: 12px;
  margin-top: 5px;
}

// custom-loader
.process {
  height: 0;
  width: 0;
  padding: 6px;
  border-radius: 22px;
  -webkit-animation: custom_loader 1s infinite linear;
  position: relative;
  border: 3px solid $white_4;
  border-right-color: $white;
  position: absolute;
  top: 11px;
  left: 22px;
  transform: translateY(-50%);
}

@keyframes custom_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

label {
  display: inline-block;
  margin-bottom: 10px;
}

.plus_cs_btn {
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 400;
  color: $white;
  border: 1px solid $submit_btn_green;
  border-radius: 5px;
  text-decoration: none;
  padding: 7px 13px;
  font-size: 12px;
  transition: 0.5s;
  cursor: pointer;
  background-color: $submit_btn_green;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

  &:hover {
    background-color: $submit_btn_green_hover;
    border: 1px solid $submit_btn_green_hover;
    text-decoration: none;
  }
}

// custom-loader-end

.table_default .scrollbar-container {
  padding-bottom: 20px;
  overflow-x: auto;
}

.package_cs_select_box > div {
  margin-top: 0px;
}

.add_people_repeat_row .package_cs_select_box > div {
  margin-top: 8px;
}

.package_cs_select_box > div > div:first-of-type {
  border: 2px solid $bright_gray-1;
  min-height: 33px;
  background-color: transparent;
  border-radius: 5px;
  line-height: normal;
  box-shadow: unset;
  color: $black;
  font-weight: 300;
  font-family: 'Roboto';
}

.package_cs_select_box > div > div:first-of-type > div:first-of-type {
  padding: 0px 0px 0px 16px;
}

.package_cs_select_box > div > div:first-of-type > div:last-child > span:first-child {
  display: none;
}

.package_cs_select_box > div:first-of-type {
  padding: 0px;
  color: $color_outer_space_approx;
  font-size: 14px;
}

.react-datepicker__triangle {
  left: -15px !important;
}

.package_cs_select_box > div > div[class$='menu'] {
  z-index: 2;
}

// datepicker_design
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $celtic-blue !important;
  border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: $celtic-blue !important;
  border-radius: 50% !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $celtic-blue !important;
  border-radius: 50% !important;
}

.react-datepicker__header {
  background-color: $celtic-blue !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__header .react-datepicker__day-name {
  color: $white !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: $white !important;
  border-width: 2px 2px 0 0 !important;
  height: 7px !important;
  width: 7px !important;
  top: 8px !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: $celtic-blue !important;
}

// datepicker_design_end

.noData_found {
  padding: 30px 15px;
  text-align: center;

  img {
    width: 60px;
    height: auto;
  }

  .no_data_found_text {
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 5px;
  }
}

.noData_found.chat_modualC {
  img {
    width: 46px;
  }
}

.noData_found.no_highlights {
  img {
    width: 70px;
  }

  .no_data_found_text {
    font-size: 22px;
    margin-top: 10px;
    font-weight: 700;
  }
}

.noData_found.no_individual_bonuses,
.noData_found.no_team_bonuses {
  width: 100%;
}

.noData_found.no_contests {
  img {
    width: 70px;
  }

  .no_data_found_text {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
  }
}

// pagination
.pagination {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px;
  justify-content: center;

  .page-item {
    width: 30px;
    height: 30px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;

      .page-link {
        font-size: 0px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 10px;
          left: 9px;
          border-left: 2px solid $black;
          border-bottom: 2px solid $black;
          transform: rotate(-135deg);
          width: 7px;
          height: 7px;
        }
      }
    }

    .page-link {
      width: 100%;
      height: 100%;
      padding: 0px;
      border-radius: 50%;
      background-color: $light_grey_pagination;
      font-size: 14px;
      font-weight: 500;
    }

    &:first-child {
      .page-link {
        font-size: 0px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 10px;
          left: 11px;
          border-left: 2px solid $black;
          border-bottom: 2px solid $black;
          transform: rotate(45deg);
          width: 7px;
          height: 7px;
        }
      }
    }
  }

  .page-item.active-table-page {
    .page-link {
      background-color: $color_endeavour_approx;
      color: $white;
    }
  }
}

// pagination-end

.custom_file_upload {
  img {
    display: inline-block;
    width: 60px;
    position: relative;
    left: -2px;
  }

  .title {
    border: 1px solid $color_theme_blue_color;
    border-radius: 100px;
    padding: 10px 10px;
    color: $color_theme_blue_color;
    display: inline-block;
    font-size: 14px;
    padding: 10px 20px;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      background-color: $color_theme_blue_color;
      color: $white;
    }
  }

  .upload_file_btn {
    background-color: $white;
    border: 1px solid $color_theme_blue_color !important;
    color: $color_theme_blue_color;
    border: none;
    font-weight: 400;
    font-size: 12px;
    border-radius: 100px;
    padding: 8px 25px 9px 25px;
    cursor: pointer;
    margin-right: 9px;
  }

  &.custom_file_upload_success {
    padding: 10px 10px 10px 10px;
    position: relative;

    .close_btn {
      display: inline-block;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 2px 2px 15px $chat_close_btn_shadow;
      font-size: 20px;
      line-height: 26px;
      transform: rotate(45deg);
      position: absolute;
      top: 15px;
      right: 15px;

      span {
        position: relative;
        left: 0px;
        top: -2px;
      }
    }

    .img_upload_row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
    }

    .img_upload_wrapper {
      width: 100%;

      .inner-wrapper {
        width: 100%;
        padding-top: 40%;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        object-fit: cover;
        object-position: center;
        border: 2px solid $bright_gray;
        border-radius: 10px;
      }
    }

    .video_upload_wrapper {
      width: 100%;
      height: 100%;

      video {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

// site_loader_full
.site_goals_loader {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: calc(100vh - 74px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 12;

  img {
    width: 100px;
    height: auto;
    filter: grayscale(1) brightness(10);
  }

  svg {
    width: 300px;
    stroke-dasharray: 3000;
    animation-name: fillsvg;
    animation-duration: 3s;
    fill: transparent;
    stroke: $white;
    stroke-width: 2;
    animation-iteration-count: infinite;
  }
}

@keyframes fillsvg {
  0% {
    stroke-dashoffset: 3000;
  }

  45% {
    stroke-dashoffset: 2000;
  }

  90% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
    fill: $white;
  }
}

// site_loader_full_end

.loadMore_wrapper {
  text-align: center;
  width: 100%;

  .load_more_btn {
    display: inline-block;
    font-weight: 700;
    color: $white;
    border: 1px solid $color_buttercup_approx;
    border-radius: 5px;
    text-decoration: none;
    padding: 8px 18px;
    font-size: 15px;
    transition: 0.5s;
    cursor: pointer;
    background-color: $color_buttercup_approx;
    box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
    margin-top: 20px;
  }
}

.submit-btn-with-process {
  position: relative;
  display: inline-block;

  .process {
    top: 13px;
    left: 35px;
  }
}

.submit-btn-with-process.loader_active input[type='submit'] {
  padding-left: 60px !important;
}

/*common-css-end*/

.main_wrapper {
  .left_sidebar {
    width: 256px;
    height: calc(100vh - 73px);
    position: fixed;
    top: 0;
    left: 0px;
    min-height: 100vh;
    background: $navy_blue;

    .inner_sidebar {
      height: calc(100% - 123px);
    }
  }

  .rightC_wrapper {
    width: calc(100% - 256px);
    padding: 30px 34px 30px 68px;
    position: relative;
    left: 256px;
  }
}

/*header*/

.site_header {
  border-bottom: 1px solid $bright_gray;
  padding: 18px 24px;
  background-color: $white;
  position: fixed;
  top: 0px;
  left: 256px;
  right: 0px;
  z-index: 9;
  @media screen and (max-width: 991px) {
    padding: 18px 15px;
  }

  &.auth_header {
    left: 0;
  }

  .adminName {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      position: relative;
      top: -2px;
    }

    p {
      margin-bottom: 0px;
      color: $blue_denim;
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
    }
  }

  .inner_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .inner_header_left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .adminName {
        margin-right: 15px;
        @media screen and (max-width: 575px) {
          margin-right: 10px;
          p {
            display: none;
          }
        }
      }
    }

    .navbar_logo {
      cursor: pointer;
      width: 80px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .right_wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;

    .mobile_toggle_btn {
      position: absolute;
      top: 7px;
      right: 0px;
      width: 20px;
      padding: 0px;
      display: none;
      height: 15px;

      .line {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $green-blue;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &.close {
        .line:nth-child(1) {
          transform: rotate(45deg);
          position: relative;
          top: 2px;
        }

        .line:nth-child(3) {
          transform: rotate(-45deg);
          position: relative;
          top: -4px;
        }

        .line:nth-child(2) {
          display: none;
        }
      }
    }

    .nf_wrapper {
      position: relative;
      margin-left: 10px;
    }

    .inviteTeamBtn {
      background-color: transparent;
      border-radius: 5px;
      display: inline-flex;
      align-items: center;
      color: $green-blue;
      font-size: 12px;
      font-weight: 400;
      padding: 6px 14px;
      transition: 0.5s;
      margin-right: 20px;
      @media screen and (max-width: 575px) {
        font-size: 0px;
      }
      &:hover {
        color: $color_theme_blue_color_hover;
      }

      img {
        display: inline-block;
        margin-right: 8px;
      }
    }

    .notofication_icon {
      display: inline-block;
      text-decoration: none;
      position: relative;

      .notofication_count {
        color: $white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background-color: #ef3e6d;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 10px;
        line-height: normal;
        position: absolute;
        top: -7px;
        right: -1px;
      }
    }
  }

  .userD_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding-right: 18px;
    margin-left: 30px;
    text-decoration: none;
    cursor: pointer;

    .d_arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }

    .user_img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    .user_name {
      font-weight: 500;
      font-size: 12px;
      color: $color_outer_space_approx;
      display: inline-block;
      margin-left: 12px;
      max-width: 250px;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;

      .emailOf_user {
        display: block;
        font-weight: 400;
        color: $color_cutty_sark_approx;
        font-size: 10px;
        line-height: normal;
      }
    }
  }

  .user_dropdown_wrapper {
    position: relative;

    .user_dropdown_menu {
      position: absolute;
      top: 44px;
      width: 206px;
      right: 0px;
      background-color: $white;
      box-shadow: 4px 8px 36px $black_8;
      border-radius: 8px;
      padding: 15px;
      display: none;

      @include respond-to('phone-md') {
        left: 0px;
      }
      &.show {
        display: block;
      }

      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0px;
          }
        }

        span.wrapA {
          display: flex;
          align-items: center;
          text-decoration: none;
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;

          &:hover {
            .menu_label {
              color: $color_outer_space_approx;
            }

            .img_wrapper img {
              filter: grayscale(1) brightness(0.2);
            }
          }
        }
      }

      .menu_label {
        font-size: 12px;
        color: $black_coral;
      }

      ul .img_wrapper {
        width: 26px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 5px;

        img {
          object-fit: contain;
          // width:20px;
          // height:20px;
          // padding:1px
        }
      }

      .menus_group {
        border-bottom: 1px solid $bright_gray-1;
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0px;
          padding-bottom: 0px;
          border-bottom: none;
        }
      }
    }
  }

  .search_box {
    position: relative;

    img {
      position: absolute;
      top: 11px;
      left: 14px;
      right: auto;
      cursor: pointer;
    }
  }

  .search_box input.textinput {
    width: 300px;
    height: 35px;
    font-weight: 500;
    font-family: 'Roboto';
    color: $color_outer_space_approx;
    border-radius: 50px;
    background-color: $ghost-white;
    border: none;
    background-position: 12px 11px;
    background-repeat: no-repeat;
    padding-left: 34px;
    border: 1px solid $ghost-white;
    transition: 0.4s;
    @media screen and (max-width: 991px) {
      width: 210px;
    }
    &:focus {
      border: 1px solid $mercury;
    }

    &::placeholder {
      color: $color_alto_approx;
      opacity: 1;
    }
  }
}

.main_wrapper:after,
.main_wrapper:before {
  content: '';
  width: 19px;
  height: 19px;
  display: inline-block;
  position: fixed;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC+SURBVHgBzc/PCcIwFAbwr6+9eakT6AZGUARPGcAiOEFH6ApO0BFcwaPHeusxG5gRvCkUjIkUKaJSknfoD0L+vOQLDxis+Xo3ARMyzX0MJmSHABMCEWMYohmYvNoUUqZg4MJS3EY5GFA7bcGgDYMUi41EIHqv4uiAQNRZT8UyKxGAPnaFWGUFPNGXs9I3kH6clz4t05+Ka/liQ3P0FNkHpsc9jQf2iJtK1ScdGtZV2aFgzNl+cEWSaFUftSs8AdSSJ6mSuDrXAAAAAElFTkSuQmCC);
  @media screen and (max-width: 1199px) {
    display: none;
  }
}
.main_wrapper:after {
  top: auto;
  right: 320px;
  z-index: 8;
  left: 256px;
  bottom: 0;
}
.main_wrapper:before {
  top: 0;
  right: 320px;
  z-index: 9;
  left: 256px;
  transform: rotate(90deg);
  bottom: auto;
}
/*header_end*/

/*sidebar*/

.main_wrapper .left_sidebar {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 17px;
  .leftsidebar_logo {
    text-align: center;
    padding: 25px 0;
    @include respond-to('tablet-md') {
      padding: 15px 0;
    }
  }
}

aside.left_sidebar {
  .navitems_wrapper {
    margin: 0px;
    padding: 0px;
    list-style: none;
    height: 100%;
    overflow-y: auto;

    .nav_link {
      display: flex;
      width: 100%;
      text-decoration: none;
      align-items: center;
      position: relative;
      padding: 8px;
      border-radius: 10px;
    }

    .nav_item {
      &.orange_highlight {
        .nav_link {
          position: relative;
        }
      }
    }
  }

  .nav_item {
    .nav_link {
      transition: 0.5s ease;
      &:hover {
        background: #252c3f;
      }
    }
    &.active {
      .nav_link {
        background: $white;

        .icon_wrapper {
          img {
            filter: grayscale(1) brightness(0);
          }
        }

        p {
          color: $navy_blue;
        }
      }
    }

    .icon_wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 10px;
      transition: 0.4s;

      img {
        filter: grayscale(1) brightness(0) invert(1);
        transition: 0.4s;
      }
    }

    .menu_title {
      letter-spacing: 0.04em;
      font-size: 16px;
      color: $white;
      margin-left: 12px;
      margin-bottom: 0px;
      transition: 0.4s;
    }

    &.active .menu_title {
      color: $navy_blue;
    }

    .count_number {
      font-family: 'Roboto';
      display: inline-block;
      font-weight: 500;
      font-size: 10px;
      background-color: $color_bittersweet_approx;
      color: $white;
      padding: 3px 8px;
      border-radius: 10px;
      margin-left: 16px;
    }
  }

  .navitems_wrapper .nav_item {
    margin-bottom: 14px;
    @include respond-to('tablet-md') {
      margin-bottom: 8px;
    }
  }

  .nav_item {
    .icon_wrapper,
    .menu_title,
    .count_number {
      position: relative;
      z-index: 2;
    }
  }
}

/*sidebar-end*/

/*csp_table_wrapper*/
.emsp_table_data .scrollbar-container {
  scrollbar-color: $color_link_water_approx transparent;
  scrollbar-width: thin;
  padding-top: 10px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    &:vertical {
      height: 30px;
      background-color: $color_link_water_approx;
      border-radius: 10px;
    }
  }
}

.csp_table_wrapper {
  padding: 5px 0px 15px 0px;

  .ps__rail-x,
  .ps__rail-y {
    display: none;
  }
  .pagination {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      tr:nth-child(even) {
        background-color: #f8f9fb;
      }
    }
  }

  tbody {
    td {
      font-weight: 700;
      color: $color_outer_space_approx;
      font-size: 14px;
      text-align: center;
    }

    tr:last-child td {
      border-bottom: none;
    }

    td:nth-child(1) {
      width: 80px;
      padding: 3px 0px;
    }

    td.profile_name {
      padding: 3px 0px;
      text-align: left;
      font-weight: 400;
      color: $blue_denim;
      font-size: 14px;
      width: 135px;
    }
  }

  th {
    font-weight: 400;
    color: $spaces_black;
    font-size: 12px;
    text-align: center;
    font-weight: normal;

    p {
      span {
        font-size: 10px;
      }
    }
  }

  table {
    th,
    td {
      padding: 3px 20px;
    }

    th {
      padding: 3px 32px;
    }
  }

  .profile_img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .profile_PN {
    display: flex;
    align-items: center;

    .profile_name {
      font-weight: 700;
      color: $blue_denim;
      font-size: 14px;
      margin: 0px;
      margin-left: 14px;
    }
  }
}

/*csp_table_wrapper-end*/

/*pipeline_details_card*/

.pipeline_details_card {
  padding: 24px 40px 18px 24px;
  margin-top: 30px;
  @include respond-to('phone-xl') {
    padding: 20px 15px;
  }
  .card_title {
    font-weight: 400;
    font-size: 22px;
    color: $color_outer_space_approx;
    margin: 0px;
    margin-bottom: 15px;
  }

  .cs_row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    justify-content: space-between;

    .cs_column {
      width: auto;
      max-width: 25%;
      padding: 0px 20px;
      text-align: center;
      margin-bottom: 20px;

      &:first-child {
        text-align: left;
      }
    }
  }

  .cs_column {
    .detail_title {
      font-weight: 400;
      font-size: 14px;
      color: $color_outer_space_approx;
      margin-bottom: 20px;
    }

    .value {
      font-weight: 300;
      color: $color_endeavour_approx;
      font-size: 26px;
    }
  }
}

/*pipeline_details_card_end*/

/*home_top_chart_sec*/

.saRe_card_wrapper {
  width: 260px;
  padding-right: 20px;
}

.card_design.saRe_card_design {
  margin-bottom: 20px;
  overflow: hidden;
}

.saRe_card_box {
  height: 53px;
  padding-bottom: 5px;
}

.sale_TPM_card_box {
  height: 170px;
  margin-top: 15px;
}

.people_card_box {
  .sale_TPM_card_box {
    height: 80px;
    margin-top: -26px;
    position: relative;
    left: -13px;
    width: 100%;

    .apexcharts-svg {
      overflow: visible;
    }
  }
}

#stp_chart,
.column_chart_double {
  .apexcharts-series path {
    clip-path: inset(0% 0% 0% 0% round 50px);
  }

  .stp_chart_X_label {
    margin-top: -10px;
  }

  .apexcharts-series .apexcharts-bar-area {
    filter: unset !important;
  }

  .apexcharts-xcrosshairs.apexcharts-active {
    opacity: 0 !important;
  }

  .apexcharts-tooltip {
    box-shadow: unset;
    background-color: transparent;
  }

  .stp_chart_tooltip {
    left: -21px;
    top: -25px;
  }
}

.sale_TPM_card_box {
  .apexcharts-series path {
    clip-path: unset !important;
  }
}

#total_sale_chart,
.annual_sales_goal {
  .apexcharts-tooltip {
    border: none !important;
    overflow: visible;
    box-shadow: unset;
    background-color: transparent !important;
  }

  .total_sale_tooltip {
    background: linear-gradient(183deg, $white_gradient 10%, $gray_gradient 100%);
    padding: 7px 18px;
    color: $white;
    position: relative;
    border-radius: 12px;
    top: -30px;
    backdrop-filter: blur(3px);
    box-shadow: 0 0 12px 4px rgba(239, 231, 231, 0.5);
    border: 2px solid #fbf4f9;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 14px;
      height: 14px;
      background: linear-gradient(183deg, rgba(248, 237, 243, 0.5) 10%, rgba(229, 231, 243, 0.6) 100%);
      bottom: -10px;
      border-radius: 3px 5px 5px 3px;
      border: 2px solid #fbf4f9;
      border-width: 0px 2px 2px 0px;
    }

    span {
      display: block;
      position: relative;
      z-index: 2;
    }

    .dt_value {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.5px;
      text-align: center;
      margin-bottom: 2px;
      background-image: linear-gradient(60deg, $green_gradient 10%, $blue_gradient 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .price_value {
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      background-image: linear-gradient(60deg, $green_gradient 10%, $blue_gradient 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .apexcharts-xaxistooltip-bottom {
    display: none !important;
  }
}

.employee_dashboard_page {
  .card_design.total_sale_card_design {
    margin-bottom: 20px;

    #total_sale_chart {
      margin-top: 0px;
    }
  }
}

#stp_chart,
.column_chart_double {
  .apexcharts-tooltip.apexcharts-theme-light {
    border: none;
    background-color: transparent;
  }

  .apexcharts-tooltip {
    overflow: visible;
    border: none;
  }

  .stp_chart_tooltip {
    background-color: $black;
    position: relative;
    border-radius: 6px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 17px;
      height: 17px;
      bottom: -7px;
      z-index: 1;
      background-color: $black;
      border-radius: 3px;
    }

    .value {
      font-weight: 700;
      font-size: 12px;
      color: $white;
      display: block;
      text-align: center;
      padding: 5px 9px;
      position: relative;
      z-index: 2;
    }
  }
}

.sale_TPM_wrapper {
  width: 260px;
  padding-right: 20px;
}

.home_chart_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.total_sale_wrapper {
  width: calc(100% - 520px);
}

.h_details_box {
  min-height: 73.33px;
  padding: 10px 15px 0px 15px;

  .title {
    font-weight: 400;
    font-size: 14px;
    color: $color_outer_space_approx;
    margin-bottom: 3px;
  }

  .value {
    .m_value {
      font-weight: 300;
      color: $green-blue;
      font-size: 22px;
    }

    .s_value {
      font-weight: 500;
      font-size: 10px;
      margin-left: 5px;

      &.green {
        color: $color_ocean_green_approx;
      }

      &.red {
        color: $color_cerise_red_approx;
      }

      &.yellow {
        color: $color_bright_sun_approx;
      }

      &.blue {
        color: $blue_denim;
      }
    }
  }

  .flag_value {
    font-weight: 500;
    font-size: 10px;
    color: $color_bright_sun_approx;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 3px;

    img {
      position: relative;
      top: 1px;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.sale_TPM_card_design .h_details_box {
  text-align: center;
  .flag_value {
    justify-content: center;
  }
}

.card_design {
  &.sale_TPM_card_design {
    margin-bottom: 20px;
    min-height: 280px;
  }

  &.total_sale_card_design {
    min-height: 280px;

    .h_details_box {
      position: relative;

      .value {
        .s_value {
          display: block;
        }

        .m_value {
          font-size: 26px;
        }
      }

      .title {
        color: $color_outer_space_approx;
        font-size: 22px;
        font-weight: 400;
      }

      .flag_value {
        top: 35px;
        right: 20px;
      }
    }

    #total_sale_chart {
      margin-top: -22px;
    }
  }
}

.card_design.total_sale_card_design {
  #total_sale_chart {
    margin-top: -22px;
  }
}

.card_design.total_sale_card_design {
  .total_sale_card_box {
    height: 205px;
  }
}

.help_box {
  background: linear-gradient(90deg, $celtic-blue 0%, $color_pacific_blue_approx 105.4%);
  color: $white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 16px;
  width: 216px;
  max-width: 100%;
  padding: 16px 20px;
  justify-content: center;
  display: none;
}

.main_wrapper .left_sidebar .help_box {
  position: absolute;
  bottom: 30px;
  left: 12px;
}

.help_box .click_btn {
  display: inline-block;
  color: $white;
  background-color: $color_buttercup_approx;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  text-decoration: none;
  padding: 6px 12px;
  margin-left: 12px;
  transition: 0.5s;
  border: none;

  &:hover {
    background-color: $color_gamboge_approx;
  }
}

/*home_top_chart_sec_end*/

/*people*/

.add_people {
  text-align: right;
  margin-bottom: 16px;
}

.people_card_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;

  .noData_found {
    width: 100%;
  }
}

.toolbar_pcs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .left_btns,
  .right_btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .btn_link {
    display: inline-flex;
    align-items: center;
    margin-right: 25px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.people_card_row .people_card_box {
  width: 33.3333%;
  padding: 0px 12px;
}

.people_card_box .inner_box {
  padding: 22px 22px 10px 22px;
  margin-bottom: 20px;
  position: relative;
}

.people_card_box .full_box_link {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: calc(100% - 70px);
  z-index: 3;
}

.profile_p_img {
  text-align: center;

  img {
    width: 82px;
    height: 82px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.profile_p_title {
  font-weight: 400;
  font-size: 20px;
  color: $color_outer_space_approx;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  @include respond-to('phone-md') {
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: $color_outer_space_approx;
  }
}

.people_db_row {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  max-width: 100%;
  margin: 0px auto;
}

.people_db_box_wrapper {
  width: 50%;
  margin-bottom: 25px;

  &:nth-child(odd) {
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &:nth-child(even) {
    padding-left: 20px;
  }

  .h_details_box {
    padding: 0px;
    display: inline-block;
  }
}

.profile_p_details {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 22px;
  cursor: pointer;

  .profile_p_img,
  .profile_p_title {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 44px);
    height: calc(100% - 30px);
    background-color: $alice_light_blue;
    cursor: pointer;
  }
}

/*people-end*/

/*people-add-page*/

.add_peopleF_box {
  padding: 30px;

  .add_peopleF_title {
    font-weight: 700;
    font-size: 22px;
    color: $color_outer_space_approx;
    margin-bottom: 25px;
  }

  .add_people_repeat_row {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid $concrete;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    background-color: $gray_cultured;

    .cs_form_row input,
    .cs_form_row select {
      padding: 9px 16px 9px 16px;
      background-color: $white;
    }

    .cs_form_row .custom_date_field input {
      padding-left: 38px;
    }

    .package_cs_select_box > div > div:first-of-type {
      background-color: $white;
    }
  }

  .cs_form_submit_row {
    margin-top: 30px;
    flex-direction: unset;
  }
}

.add_people_Rabtn {
  font-family: 'Roboto';
  color: $blue_denim;
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  margin-left: 34px;
  margin-bottom: 15px;
  letter-spacing: 0.04em;
  @include respond-to('phone-md') {
    margin-left: 20px;
  }
}

.commission_delete {
  margin-left: 0px !important;
  font-weight: normal;
}

.adding-people-modal.cs_moda_main_wrapper {
  .modal-header {
    margin-bottom: 37px;
  }

  .inner_box {
    min-height: 555px;
    position: relative;
  }

  .react-datepicker__triangle {
    transform: unset !important;
    margin-left: 0px !important;
    left: 25px !important;
    top: 0px !important;
    z-index: 3 !important;
  }

  .cs_form_row {
    .custom_date_field {
      img {
        top: 11px;
      }
    }

    .custom_date_label {
      .cs_form_label {
        color: $blue_denim;
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 30px;
        width: auto;
        position: absolute;
        top: 10px;
        left: 10px;
        transition: 0.5s;
      }
    }

    .custom_date_label.fcw_value {
      input {
        border: 2px solid $green-blue;
      }

      .cs_form_label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
        padding-left: 5px;
      }
    }

    .extra_icon_wrap {
      position: relative;

      input {
        position: relative;
        z-index: 2;
        background-color: transparent;
        transition: 0.5s;
      }

      input::placeholder {
        opacity: 0;
      }

      label {
        color: $blue_denim;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 5px;
        width: auto;
        position: absolute;
        top: 10px;
        left: 10px;
        transition: 0.5s;
      }

      input.fcw_value {
        border: 2px solid $green-blue;
      }

      input.fcw_value + label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
      }
    }

    .react-datepicker__input-container {
      position: relative;

      input {
        position: relative;
        z-index: 2;
        background-color: transparent;
        transition: 0.5s;
      }

      input::placeholder {
        opacity: 0;
      }

      label {
        color: $blue_shade_grey;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 30px;
        width: auto;
        position: absolute;
        top: 10px;
        left: 10px;
        transition: 0.5s;
      }

      input.fcw_value {
        border: 2px solid $green-blue;
      }

      input.fcw_value + label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
        padding-left: 5px;
      }
    }
  }
}

.add_people_repeat_row {
  .cs_form_row input,
  .cs_form_row select {
    border: 2px solid $blue_denim;
    border-radius: 5px;
    font-weight: 300;
    color: $black;
    background-color: $white;
  }

  .cs_form_row input::placeholder,
  .cs_form_row select::placeholder {
    color: $blue_shade_grey;
    opacity: 1;
  }
}

/*people-add-page-end*/

/*Bonuses*/

.individual_bonuses_t_header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  .title_sec {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
    color: $color_outer_space_approx;
    margin-bottom: 10px;

    .sub_title {
      font-weight: 500;
      color: $color_outer_space_approx;
      font-size: 12px;
      display: block;
      margin-top: 5px;
    }
  }
}

.individualB_row {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
}

.individualB_box {
  width: 50%;
  padding: 0px 10px;

  .inner_box {
    padding: 16px;

    margin-bottom: 20px;
    position: relative;

    .edit_btn {
      position: relative;
      display: inline-block;
      padding-right: 15px;
      pointer-events: none;
      opacity: 0;
      top: -5px;
      transition: 0.3s all;
      @include respond-to(phone-md) {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }

    &:hover .edit_btn {
      display: inline-block;
      opacity: 1;
      pointer-events: all;
    }
  }
}

.ib_title_img_wrapper {
  .ib_title {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;
    @include respond-to(phone-md) {
      font-size: 24px;
      margin-bottom: 15px;
    }

    .bonusAmount {
      color: $color_bright_sun_approx;
      display: inline-flex;
      margin-left: 15px;
      padding-left: 2px;

      img {
        border: none;
        border-radius: 0px;
        width: auto;
        height: auto;
        margin-right: 6px;
        object-fit: contain;
      }
    }
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    object-position: center;
    display: inline-block;
    margin-left: -8px;
    border: 2px solid $white;
    border-radius: 50%;

    &:first-child {
      margin-left: 0px;
    }
  }

  .ib_img_group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    display: none;

    .more_img {
      font-size: 11px;
      font-weight: 500;
      color: $white;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $green-blue;
      border-radius: 50%;
      margin-left: -8px;
      border: 2px solid $white;
    }
  }
}

.ib_time {
  font-weight: 500;
  font-size: 10px;
  color: $color_outer_space_approx;
  display: block;
  margin-top: 3px;
}

.ib_price_range {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.ib_range_wrapper {
  width: 100%;
  position: relative;
  right: 0px;
  top: -6px;

  .ib_range_slider {
    width: 100%;
    height: 6px;
    background-color: $color_link_water_approx;
    border-radius: 20px;
    position: relative;
  }
}

.ib_range_slider {
  .ib_range_active_area {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 6px;
    background-color: #40b885;
    border-radius: 20px;

    &.green {
      background-color: $color_ocean_green_approx;
    }

    &.yellow {
      background-color: $color_yellow_orange_approx;
    }

    &.red {
      background-color: $color_cerise_red_approx;
    }
  }

  &.blue .ib_range_active_area {
    background-color: $color_cornflower_blue_approx;
  }
}

.ib_range_value {
  font-weight: 700;
  font-size: 12px;
  color: $white;
  width: 35px;
  text-align: right;

  &.blue {
    color: $color_cornflower_blue_approx;
  }
}

.individual_bb_box {
  min-height: calc(100vh - 190px);
  padding: 20px;
  margin-bottom: 30px;

  .title_sec {
    margin-bottom: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .individualB_row {
    padding-top: 10px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 15px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      &:vertical {
        height: 30px;
        background-color: $color_gray_chateau_approx;
        border-radius: 10px;
      }
    }

    .individualB_box {
      .ib_time {
        display: inline-block;
        margin-top: 0px;
        margin-bottom: 5px;
      }

      .bounsFLS {
        font-size: 10px;
        font-weight: 500;
        color: $white;
      }

      .ib_price {
        img {
          display: inline-block;
          margin-right: 8px;
          top: 3px;
          position: relative;
        }
      }

      .inner_box {
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .ib_name_img_time {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .editTime {
            display: flex;
            @include respond-to(phone-md) {
              justify-content: center;
            }
          }
          @include respond-to(phone-md) {
            flex-wrap: wrap;
            .ib_title_img_wrapper,
            .editTime {
              width: 100%;
              text-align: center;
            }
          }
        }

        .ib_price_range {
          width: 100%;
        }
      }

      .ib_range_wrapper {
        .ib_range_slider {
          background-color: $bonuses_range_slider;
        }
      }
    }
  }
}
.ib_price_range img {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  top: 4px;
}

/*Bonuses_end*/

/*CONTEST START*/

.sales_contest_note_text {
  color: $color_outer_space_approx;
  font-size: 12px;
  font-weight: 500;
}

.sales_contest_wrapper .individual_bonuses_t_header {
  margin-bottom: 15px;
}

.sales_contest_box {
  padding: 15px;
  margin-bottom: 15px;

  .inner_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.sales_contestL_profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 325px;
}

.sales_contest_Pimg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 2px solid $white;
}

.sales_contest_user_name {
  font-weight: 700;
  font-size: 14px;
  color: $white;
  margin-bottom: 4px;
}

.sales_contest_rank {
  font-weight: 500;
  font-size: 10px;
  color: $white;
  display: flex;
  align-items: center;

  img {
    display: inline-block;
    margin-right: 2px;
  }
}

.sales_contest_user_details {
  margin-left: 10px;
}

.sales_contest_range_slider {
  width: calc(100% - 325px);
  background-color: $gray_cultured;
  border-radius: 10px;
  padding: 15px 30px;

  .ib_range_wrapper {
    top: 0px;
    width: 100%;

    .ib_range_slider {
      width: 100%;
      background-color: $color_mischka_approx;
    }
  }
}

.sales_contest_range_slider_value {
  color: $color_cornflower_blue_approx;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 6px;
}

.sales_contest_row {
  padding-top: 18px;
}

.sales_contest_box {
  &:nth-child(1) .sales_contest_Pimg {
    border: 2px solid $color_marigold_yellow_approx;
  }

  &:nth-child(-n + 3) .chatProfile {
    font-size: 24px;
    width: 86px;
    height: 86px;
  }

  &:nth-child(2) .sales_contest_Pimg {
    border: 2px solid $color_iron_approx;
  }

  &:nth-child(3) .sales_contest_Pimg {
    border: 2px solid $color_mango_tango_approx;
  }
}

.contest_page_wrapper {
  .sales_contest_wrapper {
    margin-bottom: 30px;
  }
}

.contest_page_wrapper {
  .individual_bonuses_t_header {
    .title_sec {
      margin-bottom: 0px;
    }
  }
}

/*CONTEST END*/

/*people_single_page*/

.people_single_DATA {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;

  .people_single_Dleft {
    padding-right: 24px;
    width: 40%;
  }

  .people_single_Dright {
    padding-left: 24px;
    width: 60%;
  }
}

.employee_dashboard_page {
  .people_single_DATA {
    padding-top: 10px;
  }
}

.people_single_page {
  .individualB_box {
    padding: 0px 0px;
    width: 100%;
  }

  .sales_contest_row {
    padding-top: 5px;
  }
  .sales_contestL_profile {
    width: 200px;
  }

  .sales_contest_range_slider {
    width: calc(100% - 200px);
  }
}

.people_WLdata_box {
  padding: 20px 0px;

  .noData-new.pInner {
    width: 350px;
  }
}

.cs_tab_menu ul {
  padding: 0px;
  list-style: none;
}

.people_WLdata_box .cs_tab_menu {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 22px;
  justify-content: space-between;

  .people_WLdata_tool_btn {
    .plus_cs_btn {
      min-width: 90px;
      text-align: right;
      margin-left: 10px;
    }
  }
}

.people_WLdata_data_header {
  display: flex;
  // flex-wrap: wrap;

  .th_cs {
    color: $spaces_black;
    font-weight: 400;
    font-size: 12px;
    padding: 5px 10px 10px 27px;
    width: 100%;
    @include respond-to('phone-xl') {
      padding: 5px 15px;
    }
    &:first-child {
      min-width: 180px;
      max-width: 180px;
      padding: 5px 10px 10px 27px;
      @media screen and (max-width: 1366px) {
        min-width: 140px;
        max-width: 140px;
      }
    }
  }
}

.people_WLdata_data_body {
  max-height: 495px;
  overflow-y: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: $color_link_water_approx;
    border-radius: 10px;
  }

  .people_WLdata_tr {
    border-bottom: 2px solid $gray_cultured;
    display: flex;

    &:nth-child(odd) {
      background-color: #f5f6fb;
    }

    .people_WLdata_td {
      font-weight: 400;
      color: $color_outer_space_approx;
      font-size: 14px;
      padding: 16px 10px 16px 27px;
      width: 100%;
      .circle-main {
        margin: 0;
      }
      @include respond-to('phone-xl') {
        padding: 10px 15px;
      }
      cursor: pointer;

      &:first-child {
        color: $green-blue;
        width: 100%;
        min-width: 180px;
        max-width: 180px;
        padding: 16px 10px 16px 27px;
        @media screen and (max-width: 1366px) {
          min-width: 140px;
          max-width: 140px;
        }
      }
    }
  }
}

.people_WLdata_td.textBlue {
  color: $green-blue !important;
}

.people_WLdata_data_body:-webkit-scrollbar {
  display: none;
}

.people_WLdata_data_body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.people_WLdata_data_footer {
  display: flex;
  // flex-wrap: wrap;
  width: calc(100% + 2px);

  .th_cs {
    font-weight: 700;
    color: $color_outer_space_approx;
    font-size: 14px;
    padding: 16px 27px 0px 27px;
    width: 100%;
    @include respond-to('phone-xl') {
      padding: 16px 15px 0px 15px;
    }

    &:first-child {
      width: 100%;
      min-width: 180px;
      max-width: 180px;
      @media screen and (max-width: 1366px) {
        min-width: 140px;
        max-width: 140px;
      }
    }
  }
}

.people_single_page {
  .sale_TPM_wrapper {
    width: calc(100% - 520px);
    padding-right: 0px;
  }

  .card_design.sale_TPM_card_design {
    min-height: 280px;

    .h_details_box {
      text-align: left;

      .title {
        color: $color_outer_space_approx;
        font-size: 22px;
        font-weight: 700;
      }

      .value {
        .m_value {
          font-size: 26px;
        }

        .s_value {
          font-size: 10px;
        }
      }

      padding: 25px 15px 0px 30px;
    }
  }
}

.top_ii_profile_header {
  display: flex;
  align-items: center;
  margin-bottom: 42px;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
  }

  .add_people_btn {
    display: inline-flex;
    align-items: center;

    img {
      display: inline-block;
      margin-right: 5px;
      height: 20px;
      width: auto;
    }
  }

  .messageBtn {
    background-color: #2a90ea;
    border: 1px solid #2a90ea;

    &:hover {
      background-color: #2382d7;
      border: 1px solid #2382d7;
    }
  }

  .highlightBtn {
    margin-left: 15px;
  }

  .people_ii_profile_img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 2px solid $white;
  }

  .people_ii_name {
    margin-bottom: 0px;
    color: $color_outer_space_approx;
    font-weight: 400;
    font-size: 14px;
    margin-left: 14px;
    @include respond-to('phone-md') {
      font-size: 13px;
      margin-left: 10px;
    }
  }

  .people_ii_profile_back_btn {
    color: #2a90ea;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    padding-left: 12px;
    margin-right: 15px;
    text-decoration: none;
    @include respond-to('phone-md') {
      font-size: 12px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0px;
      width: 7px;
      height: 7px;
      border-left: 1px solid #2a90ea;
      border-bottom: 1px solid #2a90ea;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.people_single_page {
  .saRe_card_wrapper {
    width: 520px;
    display: flex;
    flex-wrap: wrap;

    .saRe_card_design {
      width: calc(50% - 10px);
    }

    .saRe_card_design:nth-child(odd) {
      margin-right: 10px;
    }

    .saRe_card_design:nth-child(even) {
      margin-left: 10px;
    }
  }
}

/*people_single_page_end*/

/*setting_page*/

.setting_card {
  .cs_tab_menu {
    margin-bottom: 35px;
  }
}

.profile_pic_setting {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  .cs_form_label {
    width: 100%;
  }

  .pp_setting_img {
    width: 96px;
    height: 96px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      border: 1px solid $bright_gray;
    }
  }

  .pp_setting_btn_wrapper {
    width: calc(100% - 96px);
    padding-left: 28px;
  }
}

.general_setting_submit_button {
  padding: 10px 34px 12px 33px !important;
}

.pp_setting_btn {
  display: inline-block;
  border: 1px solid $color_theme_blue_color;
  border-radius: 30px;
  color: $color_theme_blue_color;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
  transition: 0.5s;
  text-decoration: none;

  &:hover {
    background-color: $color_theme_blue_color;
    border: 1px solid $color_theme_blue_color;
    color: $white;
    text-decoration: none;
  }

  &.pp_setting_removeP_btn {
    border: 1px solid transparent;
    color: #808080;

    &:hover {
      color: $color_bittersweet_approx;
      background-color: transparent;
      border: 1px solid transparent;
    }
  }
}

.cs_form_double_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -18px;
  margin-right: -18px;
}

.cs_form_row {
  margin-bottom: 35px;
}

.cs_form_double_row .cs_form_row {
  width: 50%;
  padding: 0px 18px;
}

.cs_form_six_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .title {
    width: 100%;
    padding: 0px 5px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  .cs_form_row {
    width: 16.66%;
    padding: 0px 5px;
    margin-bottom: 10px;
    @include respond-to(desktop-xs) {
      width: 33.33%;
    }
  }

  .commission_structure {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    .title {
      width: 100%;
      padding: 0px 5px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.cs_form_label {
  display: block;
  color: $color_outer_space_approx;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 12px;
}

.cs_form_row {
  input,
  select {
    border: 2px solid $bright_gray-1;
    border-radius: 5px;
    font-weight: 300;
    color: $black;
    font-size: 14px;
    padding: 9px 16px 9px 16px;
    width: 100%;
    font-family: 'Roboto';
  }

  textarea {
    font-family: 'Roboto';
    border: 2px solid $bright_gray-1;
    border-radius: 5px;
    font-weight: 300;
    color: $black;
    font-size: 14px;
    padding: 9px 16px 9px 16px;
    width: 100%;
  }

  input::placeholder {
    color: $black_coral;
    opacity: 1;
  }

  select {
    &::placeholder {
      color: $black_coral;
      opacity: 0.6;
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // background-image: url(../images/select_custom_arrow.svg);
    background-repeat: no-repeat;
    background-position: 97% center;
  }

  input {
    &[type='date'],
    &.cs_date_input {
      // background-image: url(../images/input_type_date_icon.svg);
      background-repeat: no-repeat;
      background-position: 15px center;
      padding-left: 35px;
    }
  }
}

.cs_form_submit_row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.cs_form_double_row .cs_form_submit_row {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.cs_form_submit_row input[type='submit'] {
  background-color: $color_theme_blue_color;
  border: 1px solid $color_theme_blue_color;
  color: $white;
  border: none;
  font-weight: 400;
  font-size: 14px;
  border-radius: 100px;
  padding: 11px 34px 12px 34px;
  cursor: pointer;
  margin-right: 9px;
}

.cs_form_submit_row .process + input[type='submit'] {
  padding: 11px 34px 12px 52px;
}

.general_setting_form {
  width: 876px;
  max-width: 100%;
}

.security_privacy_form .cs_form_row.new_password_row {
  margin-bottom: 15px;
}

.password_note_fill {
  .pnf_list_item {
    color: $black_coral;
    font-size: 12px;
    margin-bottom: 0px;
    line-height: normal;
    position: relative;
    padding-left: 24px;
    margin-bottom: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 14px;
      height: 14px;
      border: 1.5px solid $color_nevada_approx;
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 7px;
      height: 3px;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-45deg);
      display: none;
    }

    &.active {
      &:before {
        background-color: $color_japanese_laurel_approx;
        border: 1.5px solid $color_japanese_laurel_approx;
      }

      &:after {
        display: block;
      }
    }
  }

  margin-bottom: 30px;
}

.security_privacy_form .cs_form_submit_row input[type='submit'] {
  margin-top: 50px;
}

.ff_faq_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.ff_faq_box {
  width: 50%;
  padding: 0px 12px;
  margin-bottom: 20px;

  .ff_faq_contant {
    display: none;
  }

  .inner_box {
    border: 1px solid $bright_gray;
    border-radius: 16px;
    @include respond-to('phone-xl') {
      border-radius: 8px;
    }
  }

  .ff_faq_header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 21px 20px 21px 50px;
    position: relative;
    cursor: pointer;
  }

  &.active .ff_faq_header {
    border-bottom: 1px solid $bright_gray;
  }

  .ff_faq_header::before {
    content: '';
    position: absolute;
    top: 47%;
    transform: translateY(-50%) rotate(-45deg);
    width: 9px;
    height: 9px;
    border-left: 2px solid $color_outer_space_approx;
    border-bottom: 2px solid $color_outer_space_approx;
    left: 20px;
    border-radius: 1px;
    transition: 0.5s;
  }

  &.active .ff_faq_header::before {
    transform: translateY(-50%) rotate(-225deg);
    top: 54%;
  }

  .ff_faq_header {
    .ff_faq_title {
      margin-bottom: 0px;
      color: $color_outer_space_approx;
      font-weight: 700;
      font-size: 14px;
    }

    .ff_faq_target {
      color: $black_coral;
      font-weight: 400;
      font-size: 12px;
    }
  }

  .ff_faq_contant {
    .ff_notification_title {
      margin-bottom: 0px;
      font-size: 14px;
      color: $color_outer_space_approx;
      font-weight: 700;

      .ff_notification_status {
        display: block;
        color: $black_coral;
        font-weight: 400;
        font-size: 12px;
        margin-top: 5px;
      }
    }

    .ff_faq_contant_inner {
      padding: 20px 20px 20px 40px;
    }
  }
}

.ff_notification_btn_wrapper {
  position: relative;

  input {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0.000000001;
    cursor: pointer;
  }
}

.ff_notification_btn_wrapper input:checked + .ff_notification_btn {
  background-color: transparent;
  background: linear-gradient(90deg, #42ca89 2.5%, #0bc8bc 100%);
}

.ff_notification_btn_wrapper input:checked + .ff_notification_btn .ff_notification_dots {
  left: 20px;
}

.ff_notification_btn {
  display: inline-block;
  width: 44px;
  height: 28px;
  background-color: $color_heather_approx;
  border-radius: 50px;
  position: relative;
  transition: 0.5s;

  .ff_notification_dots {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 3px 8px $black_15, 0px 3px 1px $black_06;
    transition: 0.5s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
  }

  &.active_on {
    background-color: transparent;
    background: linear-gradient(90deg, #42ca89 2.5%, #0bc8bc 100%);

    .ff_notification_dots {
      left: 20px;
    }
  }
}

.ff_faq_box .ff_notification_ss_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }

  .ff_notification_left {
    width: calc(100% - 45px);
    padding-right: 20px;
  }
}

.notifications_form {
  .cs_form_submit_row {
    margin-top: 40px;
  }
}

.employee_dashboard_form {
  .cs_form_submit_row {
    margin-top: 40px;
  }
}

/*setting_page_end*/

/*Add New Bonus Modal*/
.cs_moda_main_wrapper.hidden {
  display: none;
}

.cs_moda_main_wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;

  &.show {
    display: flex;
    justify-content: center;
    padding: 30px 15px;
    overflow-y: auto;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      padding: 40px 15px 30px;
    }
  }

  .modal_overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(1, 24, 61, 0.8);
    cursor: pointer;
  }

  .inner_box {
    width: 1300px;
    max-width: calc(100% - 140px);
    height: auto;
    background-color: $white;
    border-radius: 16px;
    z-index: 1;
    padding: 26px 48px 125px 48px;
    position: relative;
    @media screen and (max-width: 1199px) {
      padding: 20px 26px 125px 26px;
      max-width: calc(100% - 60px);
    }
    @media screen and (max-width: 767px) {
      padding: 20px 26px 125px 26px;
      max-width: calc(100%);
    }
    @media screen and (max-width: 575px) {
      padding: 20px 15px 80px 15px;
      border-radius: 8px;
    }

    .cs_form_submit_row {
      flex-direction: row;
      justify-content: center;
      position: absolute;
      bottom: 40px;
      left: 0px;
      right: 0px;
      @media screen and (max-width: 575px) {
        bottom: 20px;
      }

      input[type='submit'],
      .upload-btn {
        background-color: $submit_btn_green;
        color: $white;
        font-size: 14px;
        font-weight: 400;
        border-radius: 10px;
        padding: 13px 44px;
        border: 1px solid $submit_btn_green;
        margin: 0px;
        font-family: 'Red Hat Display';
        letter-spacing: 0.04em;
        line-height: 20px;
        transition: 0.5s;
        @media screen and (max-width: 575px) {
          padding: 8px 20px;
          border-radius: 5px;
          font-size: 13px;
        }
        &:hover {
          background-color: $submit_btn_green_hover;
          border: 1px solid $submit_btn_green_hover;
        }
      }

      input[disabled=''],
      input[disabled='']:hover {
        background-color: #dbdbdb;
        border: 1px solid #dbdbdb;
        color: #737373;
      }

      .cancel-btn {
        background-color: transparent;
        color: $color_gray_chateau_approx;
        font-size: 14px;
        font-weight: 400;
        border-radius: 10px;
        padding: 13px 44px;
        transition: 0.5s;
        border: 1px solid $cancel_btn_gray;
        margin-left: 12px;
        text-decoration: none;
        font-family: 'Red Hat Display';
        letter-spacing: 0.04em;
        line-height: 20px;
        @media screen and (max-width: 575px) {
          padding: 8px 20px;
          border-radius: 5px;
          font-size: 13px;
        }
        &:hover {
          background-color: $cancel_btn_gray;
          border: 1px solid $cancel_btn_gray;
          color: $black;
          text-decoration: none;
        }
      }
    }

    .modal-close-btn {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: fixed;
      top: 30px;
      right: calc(((100vw - 1300px) / 2) - 40px);

      @media screen and (max-width: 767px) {
        top: -22px;
        right: 0;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        background-color: $white;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal-header {
      .select-all-btn {
        color: $color_theme_blue_color;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid $color_theme_blue_color;
        border-radius: 5px;
        padding: 8px 17px;
        transition: 0.5s;
        font-family: 'Red Hat Display';
        letter-spacing: 0.04em;

        &:hover {
          background-color: $color_theme_blue_color;
          color: $white;
        }
      }

      .modal-title {
        color: $color_outer_space_approx;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

.add_new_boCO_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-left: -15px;
  margin-right: -15px;

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.chat_room_model {
    justify-content: flex-start;

    .add_new_boCOf_box {
      .add_new_boCOU_name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .fm_error {
    padding: 0px 35px;
    display: block;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

.add_new_boCOf_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 25%;
  max-width: 100%;
  padding: 0px 15px;
  margin-bottom: 30px;
  @include respond-to('desktop-xs') {
    width: 33.33%;
  }
  @include respond-to('tablet-md') {
    width: 50%;
  }

  .add_new_boCO_details {
    width: calc(100% - 20px);
    padding-left: 25px;
  }

  .add_new_boCOU_checkbox {
    width: 18px;
    height: 18px;
    position: relative;

    input {
      position: absolute;
      top: -2px;
      left: -2px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0.00000001;
      cursor: pointer;
    }

    label {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        z-index: 1;
        border: 2px solid $black_coral;
        border-radius: 2px;
        transition: 0.3s;
      }

      &::after {
        content: '';
        width: 10px;
        height: 4px;
        position: absolute;
        top: 4px;
        left: 3px;
        transform: rotate(-45deg);
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        z-index: 1;
        transition: 0.3s;
        opacity: 0;
      }
    }

    input:checked + label {
      &:before {
        border: 2px solid $celtic-blue;
        background-color: $celtic-blue;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .add_new_boCO_details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .add_new_boCO_img {
      width: 48px;
      height: 48px;
      @include respond-to('phone-md') {
        width: 34px;
        height: 34px;
      }
    }

    .add_new_boCOEX_data {
      width: calc(100% - 48px);
      padding-left: 16px;
    }

    .add_new_boCO_img img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    .add_new_boCOU_name {
      color: $color_outer_space_approx;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .add_new_boCO_designation {
      color: $black_coral;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 0px;
    }
  }
}

.add_new_boCO_contant_wrapper {
  .continue_btn_wrapper {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .continue_btn {
    color: $white;
    background-color: $celtic-blue;
    border: 1px solid $celtic-blue;
    border-radius: 50px;
    font-size: 12px;
    padding: 6px 18px;
  }
}

.adding-bonus.cs_moda_main_wrapper {
  .react-datepicker__triangle {
    transform: unset !important;
    margin-left: 0px !important;
    left: 25px !important;
    top: 0px !important;
    z-index: 3 !important;
  }

  .inner_box {
    position: relative;
  }

  .modal-header {
    margin-bottom: 40px;
  }

  .inner_form_wrapper {
    width: 780px;
    margin-left: auto;
    max-width: 100%;
  }

  .wm-wrapper {
    width: 565px;
    max-width: 100%;
  }

  .cs_form_row input,
  .cs_form_row select,
  .cs_form_row textarea {
    border: 2px solid $bright_gray-1;
    border-radius: 5px;
    font-weight: 500;
    color: $black;
    background-color: $white;
    transition: 0.5s;
  }

  .cs_form_row input:focus,
  .cs_form_row select:focus,
  .cs_form_row textarea:focus {
    border: 2px solid $green-blue;
  }

  .cs_form_row input::placeholder,
  .cs_form_row select::placeholder,
  .cs_form_row textarea::placeholder {
    color: $blue_shade_grey;
    opacity: 1;
  }

  .cs_form_row textarea {
    height: 82px;
  }

  .custom_checkbox_row {
    .form_sec_label {
      width: 100%;
      margin-bottom: 17px;
    }

    .custom_checkbox_box {
      margin-left: 25px;
    }

    .custom_checkbox_box + .cs_form_row {
      margin-left: 55px;
      margin-bottom: 25px;
    }
  }

  .dollarsign_icon {
    .extra_icon_wrap {
      position: relative;

      input {
        padding-left: 27px;
      }

      &:before {
        content: '$';
        position: absolute;
        top: 0px;
        left: 17px;
        top: 10px;
        color: $blue_shade_grey;
        font-weight: 500;
      }
    }
  }

  .cs_radio_checkbox_wrapper {
    .custom_radio_box {
      margin-left: 25px;
    }
  }
}

/*Add New Bonus Modal End*/

/*Add New Bonus form*/

.add_boCO_form_wrapper {
  padding: 30px;
  margin-top: 32px;

  .inner_form_wrapper {
    width: 817px;
    max-width: 100%;
  }

  .cs_form_random_3row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;

    .cs_form_row {
      width: 232px;
      max-width: 33.3333%;
      padding: 0px 6px;
    }
  }
}

.custom_checkbox_box {
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }

  input {
    position: absolute;
    top: 1px;
    top: -1px;
    left: -4px;
    bottom: 0px;
    width: 18px;
    height: 18px;
    z-index: 2;
    opacity: 0.00000001;
    cursor: pointer;
  }

  label {
    color: $black_coral;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 0px;
    position: relative;
    padding-left: 30px;

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 14px;
      height: 14px;
      z-index: 1;
      border: 2px solid $black_coral;
      border-radius: 2px;
      transition: 0.3s;
    }

    &::after {
      content: '';
      width: 10px;
      height: 4px;
      position: absolute;
      top: 2.4px;
      left: 3px;
      transform: rotate(-45deg);
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      z-index: 1;
      transition: 0.3s;
      opacity: 0;
    }
  }

  input:checked + label {
    &::before {
      border: 2px solid $celtic-blue;
      background-color: $celtic-blue;
    }

    &::after {
      opacity: 1;
    }
  }
}

.custom_checkbox_row {
  .form_sec_label {
    color: $color_outer_space_approx;
    font-weight: 700;
    font-size: 12px;
  }
  margin-bottom: 30px;
}

.cs_form_double_row.input_default_currency {
  position: relative;

  input {
    padding-left: 33px;
  }

  &::before {
    content: '$';
    position: absolute;
    top: 34px;
    left: 35px;
    color: $color_outer_space_approx;
    font-weight: 400;
    font-size: 14px;
  }
}

.cs_form_double_row.input_default_currency.tw_line {
  &::before {
    top: 50px;
  }
}

.custom_checkbox_double_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  .custom_checkbox_row {
    width: 50%;
    padding: 0px 20px;
  }
}

/*Add New Bonus form End*/

/*notification-page*/

.notification_page .notification_title {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: $color_outer_space_approx;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.notification_page_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .notification_filter {
    width: 300px;
    padding: 28px;
  }

  .notification_listing_wrapper {
    width: 1200px;
    max-width: 100%;
  }
}

.notification_filter {
  .custom_checkbox_box {
    padding-left: 0px;
  }

  .notification_filter_form .notification_filter_title:nth-child(1) {
    margin-top: 0px;
  }

  .notification_filter_title {
    font-weight: 700;
    color: $color_outer_space_approx;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 38px;
  }
}

.custom_radio_box {
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }

  input {
    position: absolute;
    top: 5px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 18px;
    height: 18px;
    z-index: 2;
    opacity: 0.00000001;
    cursor: pointer;
  }

  label {
    color: $black_coral;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 0px;
    position: relative;
    padding-left: 30px;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 18px;
      height: 18px;
      z-index: 1;
      border: 1px solid $bright_gray-1;
      background-color: $alice_light_blue_2;
      border-radius: 2px;
      transition: 0.3s;
      border-radius: 50%;
    }

    &::after {
      content: '';
      width: 8px;
      height: 4px;
      position: absolute;
      top: 5px;
      left: 5px;
      transform: rotate(-45deg);
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      z-index: 1;
      transition: 0.3s;
      opacity: 0;
    }
  }

  input:checked + label {
    &::before {
      border: 1px solid $celtic-blue;
      background-color: $celtic-blue;
    }

    &::after {
      opacity: 1;
    }
  }
}

.cs_radio_checkbox_wrapper {
  .custom_radio_box {
    label {
      padding-left: 30px;
    }

    input {
      position: absolute;
      top: 1px;
      top: -1px;
      left: -4px;
      bottom: 0px;
      width: 18px;
      height: 18px;
      z-index: 2;
      opacity: 0.00000001;
      cursor: pointer;
    }

    label:before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 14px;
      height: 14px;
      z-index: 1;
      border: 2px solid $black_coral;
      border-radius: 2px;
      transition: 0.3s;
    }

    label:after {
      content: '';
      width: 10px;
      height: 4px;
      position: absolute;
      top: 2.4px;
      left: 3px;
      transform: rotate(-45deg);
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      z-index: 1;
      transition: 0.3s;
      opacity: 0;
    }
  }

  .custom_radio_box input:checked + label::before {
    border: 2px solid $celtic-blue;
    background-color: $celtic-blue;
  }

  .custom_radio_box input:checked + label::after {
    opacity: 1;
  }
}

.notification_items {
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding-top: 24px;
}

.notification_item {
  padding: 25px 30px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 15px 20px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: calc(100% - 60px);
    bottom: 0px;
    background-color: $bright_gray-1;
  }

  &.read:before,
  &:last-child:before {
    display: none;
  }

  &.read {
    border-bottom: 2px solid $white;
    background-color: rgba(108, 170, 208, 0.04);

    &:last-child {
      border-bottom: none;
    }
  }

  .notification_item_hh {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;

    .notification_status {
      display: flex;
      align-items: center;
      color: $color_theme_blue_color;
      font-weight: 500;
      font-size: 12px;

      img {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: -1px;
        width: 20px;
      }
    }

    .notification_time {
      color: $black_coral;
      font-size: 12px;
      position: relative;
      padding-left: 8px;
      margin-left: 7px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $black_coral;
      }
    }
  }

  .notification_dec {
    font-weight: 400;
    color: $black_coral;
    margin-bottom: 0px;
    font-size: 14px;

    strong {
      font-weight: 500;
      font-weight: normal;
      color: $color_outer_space_approx;
      font-size: 14px;
    }
  }
}

/* HELP MODAL START*/

.help_on_modal_contant {
  width: 740px;
  max-width: 100%;
  background-color: $white;
  border-radius: 16px;
  padding: 80px 30px 60px 30px;
  @include respond-to('phone-xl') {
    padding: 30px 15px 40px 15px;
  }
  position: relative;

  .notification_close_btn {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 29px;
    right: 28px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 2px;
      background-color: $color_cutty_sark_approx;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .help_modal_header {
    text-align: center;
    margin-bottom: 60px;
  }

  .help_main_title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 8px;
    @include respond-to('phone-xl') {
      font-size: 26px;
    }
  }

  .help_h_text {
    font-weight: 400;
    color: $black_coral;
    font-size: 16px;
    margin-bottom: 0px;
    @include respond-to('phone-xl') {
      font-size: 14px;
    }
  }

  .help_inner_col img {
    width: 160px;
    max-width: 100%;
    margin: 0px auto;
  }

  .help_cs_btn {
    color: $color_theme_blue_color;
    border: 1px solid $color_theme_blue_color;
    border-radius: 50px;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    padding: 7px 30px;
    transition: 0.5s;
    text-decoration: none;
    @include respond-to('phone-xl') {
      padding: 7px 20px;
    }

    &:hover {
      border: 1px solid $color_theme_blue_color;
      background-color: $color_theme_blue_color;
      color: $white;
    }
  }

  .help_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;

    .help_col {
      width: 50%;
      padding: 0px 20px;
      display: flex;
      justify-content: center;
      position: relative;

      &:nth-child(1):before {
        content: '';
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        background-color: $bright_gray-1;
        height: 50%;
      }
    }

    .help_inner_col {
      display: inline-block;
    }
  }

  .help_cs_btn_wrapper {
    text-align: center;
    margin-top: 30px;
  }
}

.help_center_form {
  .help_center_formM_textarea {
    width: 100%;
    height: 180px;
    background-color: $alice_light_blue_2;
    border-radius: 16px;
    border: none;
    color: $color_outer_space_approx;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 17px;
    resize: unset;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .submit_btn {
    width: 114px;
    padding: 11px 10px;
    border: none;
    background-color: $color_theme_blue_color;
    border: 2px solid $color_theme_blue_color;
    color: $white;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 700;
    margin-top: 35px;
  }
}

.help_chat_modal {
  .help_on_modal_contant {
    padding: 0px 0px;
    width: 654px;
    max-width: 100%;
  }

  .notification_close_btn {
    top: 42px;
    right: 28px;
  }
}

.help_csm_chat_wrapper {
  .help_csm_chat_header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 25px 20px 15px 20px;

    .help_csm_chatH_img {
      width: 52px;
      height: 52px;
      @include respond-to(desktop-xs) {
        width: 40px;
        height: 40px;
      }
    }

    .help_csm_chatH_contant {
      width: calc(100% - 52px);
      padding-left: 10px;
    }

    .help_csm_chatH_img img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid $white;
      object-fit: cover;
      object-position: center;
      @include respond-to(desktop-xs) {
        width: 40px;
        height: 40px;
      }
    }

    .help_csm_chatH_contant {
      display: flex;
      flex-wrap: wrap;
    }

    .help_csm_chatH_name {
      color: $color_outer_space_approx;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
      width: 100%;
    }

    .help_csm_chatH_alabel {
      color: $color_outer_space_approx;
      font-size: 12px;
      font-weight: 400;
      display: inline-block;
      width: 100%;
    }
  }

  .help_csm_chatMSG_box {
    height: 480px;
    width: 100%;
    background-color: $white;
    padding: 20px 20px;
    overflow-y: auto;
    scrollbar-color: $color_gray_chateau_approx transparent;
    scrollbar-width: thin;
    @include respond-to('phone-xl') {
      height: 360px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 15px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      &:vertical {
        height: 30px;
        background-color: $color_gray_chateau_approx;
        border-radius: 10px;
      }
    }
  }

  .help_csm_chat_front_msg {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 20px;

    &.group_chat_msg {
      padding-left: 38px;
      position: relative;

      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
  }

  .help_csm_chat_own_msg {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 20px;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  .help_csm_chat_front_msg {
    .help_csm_cf_msg_text {
      background-color: #f4f4f4;
      color: $color_outer_space_approx;
      font-weight: 400;
      border-radius: 0px 8px 8px 8px;
      padding: 7px 12px;
      width: auto;
      max-width: calc(100% - 50px);
      font-size: 14px;
    }

    .help_csm_cf_msg_time {
      color: $color_outer_space_approx;
      font-size: 10px;
      font-weight: 400;
      margin-left: 8px;
    }
  }

  .help_csm_chat_own_msg {
    .help_csm_co_msg_text {
      background-color: rgba(32, 108, 180, 0.2);
      color: $black;
      border-radius: 8px 0px 8px 8px;
      padding: 7px 12px;
      width: auto;
      max-width: calc(100% - 50px);
      font-size: 14px;
    }

    .help_csm_co_msg_time {
      color: $color_outer_space_approx;
      font-size: 10px;
      margin-right: 8px;
    }
  }

  .help_csm_chat_footer {
    padding: 20px 20px;
    position: relative;

    .help_csm_chatMSG_box {
      position: absolute;
      bottom: 100%;
      left: 0px;
      right: 0px;
      height: 250px;
    }

    .help_csm_chat_emoji_btn {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      border: none;
      background-color: transparent;

      img {
        width: 100%;
        height: auto;
      }
    }

    .help_csm_chat_uploadpin_btn {
      display: inline-block;
    }

    .help_csm_chat_send_btn {
      font-weight: 400;
      font-size: 14px;
      color: $white;
      background-color: transparent;
      padding: 9px 10px;
      display: inline-block;
      text-align: center;
      border-radius: 5px;
      margin-left: 20px;
      text-decoration: none;
      border: none;

      &:hover {
        color: $white;
      }
    }

    .help_csm_chat_textarea {
      border: none;
      color: $color_outer_space_approx;
      font-weight: 400;
      font-size: 14px;
      resize: unset;
      height: 30px;
      padding: 0px 10px;
      line-height: 30px;
      width: calc(100% - 150px);
    }
  }

  .help_csm_chat_footer_inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

/* HELP MODAL END*/

/*EMPLOYEE DASHBOARD*/
.employee_dashboard_page {
  .user_data_table {
    margin-bottom: 0px;
    margin-top: 50px;
    @include respond-to('phone-xl') {
      margin-top: 20px;
    }
  }
}
/*EMPLOYEE DASHBOARD END*/

/* DISCUSSION */

.main_wrapper .rightC_wrapper.cs_chatboard {
  padding: 0px;
  @include respond-to('phone-md') {
    padding: 10px 0px 0px;
  }
}
.free-trial-bar-active {
  .main_wrapper .rightC_wrapper.cs_chatboard {
    padding-top: 56px;
    @include respond-to('phone-md') {
      padding-top: 86px;
    }
  }
}

.cs_chatboard_wrapper {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 70px);
  position: relative;
  margin-top: -5px;
  overflow: hidden;
  width: 100%;

  .cs_chatboard_left {
    width: 360px;
    height: 100%;
    background-color: $white;
    border-right: 1px solid $bright_gray;

    .cs_tab {
      height: calc(100% - 106px);
      overflow-y: auto;
      scrollbar-color: $color_link_water_approx transparent;
      scrollbar-width: thin;
    }
  }

  .chat_searchBar {
    position: absolute;
    top: 5px;
    height: calc(100% - 5px);
    width: 300px;
    right: -310px;
    background-color: $white;
    z-index: 4;
    overflow: hidden;
    transition: 0.4s;
    border-left: 1px solid $bright_gray;

    .contant_scroll {
      max-height: calc(100% - 107px);
      overflow-y: auto;
      scrollbar-color: $color_link_water_approx transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 15px;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        &:vertical {
          height: 30px;
          background-color: $color_link_water_approx;
          border-radius: 10px;
        }
      }
    }

    .ts_fixed {
      .top_headr {
        background-color: $alice_light_blue_2;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2px 10px;

        .close_btn {
          font-weight: 600;
          font-size: 34px;
          transform: rotate(45deg);
          display: inline-block;
          cursor: pointer;
        }

        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0px;
          width: calc(100% - 25px);
          padding-left: 10px;
        }
      }

      .searchBar {
        position: relative;

        .form_wrapper {
          padding: 10px 10px;
          position: relative;
          z-index: 1;
          background-color: $white;

          .close_btn {
            font-weight: 400;
            font-size: 28px;
            display: inline-block;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            right: 15px;
            color: $abbey_light;
          }
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          width: 100%;
          height: 50%;
          background-color: $black;
          filter: blur(5px);
          opacity: 0.1;
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
        }

        input {
          background-color: $alice_light_blue_2;
          padding: 10px 25px 10px 35px;
          border: none;
          font-size: 13px;
          border-radius: 5px;
          width: 100%;
        }
      }
    }

    .msg_box {
      padding: 12px 10px;
      transition: 0.4s;
      cursor: pointer;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid $bright_gray;

      &:hover {
        background-color: $light_blue_04;
      }

      .msg_text {
        margin-bottom: 0px;
        font-size: 14px;
        color: $abbey_light;

        strong {
          color: $color_theme_blue_color;
        }
      }

      .time {
        font-size: 14px;
        color: $color_gray_chateau_approx;
      }
    }
  }
}

.free-trial-bar-active .cs_chatboard_wrapper {
  height: calc(100vh - 125px);
  @include respond-to('phone-md') {
    height: calc(100vh - 133px);
  }
}

// chat_searchbar_open
.cs_chatboard_wrapper.searchBar_open .cs_chatboard_right {
  width: calc(100% - 660px);
  @include respond-to('tablet-md') {
    width: calc(100% - 600px);
  }
}

.cs_chatboard_wrapper.searchBar_open .chat_searchBar {
  right: 0px;
}

// chat_searchbar_open_end

.cs_chatboard_searchHeader {
  padding: 24px 28px 10px 28px;

  .cs_chatboard_searchHeader_flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .creat_group_btn {
      display: inline-block;
      font-family: 'Roboto';
      font-weight: 400;
      color: $white;
      border: 1px solid $color_buttercup_approx;
      border-radius: 5px;
      text-decoration: none;
      padding: 8px 18px;
      font-size: 12px;
      transition: 0.5s;
      cursor: pointer;
      background-color: $color_buttercup_approx;
      box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

      &:hover {
        background-color: $color_gamboge_approx;
        border: 1px solid $color_buttercup_approx;
        color: $white;
        text-decoration: none;
      }
    }
  }

  .cs_chatboard_search_input_wrapper {
    width: calc(100% - 83px);
    position: relative;

    img {
      position: absolute;
      top: 13px;
      left: 14px;
    }

    .search_icon {
      position: absolute;
      top: 9px;
      left: 15px;
    }

    &.chat_room_list {
      input {
        border-radius: 20px;
      }
    }

    input {
      font-family: 'Roboto';
      font-size: 14px;
      padding: 9px 16px 9px 34px;
      border: 2px solid $bright_gray-1;
      border-radius: 5px;
      font-weight: 400;
      width: 100%;
      color: $black;
      position: relative;
      z-index: 2;
      background-color: transparent;
    }

    label {
      .chat {
        left: 11px !important;
      }

      color: $blue_shade_grey;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0px;
      padding: 0px 5px 0px 5px;
      width: auto;
      position: absolute;
      top: 11px;
      left: 30px;
      transition: 0.5s;
    }

    input.fcw_value {
      border: 2px solid $green-blue;
    }

    input.fcw_value + label {
      top: -7px;
      background-color: $white;
      z-index: 2;
      font-size: 12px;
      left: 12px;
      color: $green-blue;
    }
  }

  .add_new_btn {
    display: inline-block;
    font-family: 'Roboto';
    font-weight: 400;
    color: $white;
    border: 1px solid $color_theme_blue_color;
    border-radius: 5px;
    text-decoration: none;
    padding: 10px 16px;
    font-size: 12px;
    transition: 0.5s;
    cursor: pointer;
    background-color: $color_theme_blue_color;
    box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

    &:hover {
      background-color: $color_theme_blue_color_hover;
      border: 1px solid $color_theme_blue_color_hover;
      color: $white;
      text-decoration: none;
    }
  }
}

.cs_chatboard_wrapper .cs_chatboard_left .cs_tab_menu {
  ul {
    border-bottom: 1px solid $bright_gray;
    padding: 0px 28px;

    li {
      width: 50%;
      margin-right: 0px;
    }
  }

  .tab-a {
    border: none;
    color: $abbey_light;
    font-size: 12px;
    font-weight: 500;
    width: 50%;
    text-align: center;
    line-height: normal;
    text-transform: capitalize;
    padding: 12px 15px;
    border-radius: 0px;
    margin-bottom: 0px;
    box-shadow: unset;

    &:hover {
      background-color: $concrete;
    }

    &.active-a {
      border: none;
      background-color: transparent;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 100%;
        height: 3px;
        background-color: $celtic-blue;
      }
    }
  }
}

.cs_chatboard_Pitems_wrapper {
  padding-top: 16px;
}

.cs_chatboard_Pitem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 22px 16px 28px;
  border-bottom: 2px solid $white;
  cursor: pointer;

  &.active {
    background: $light_blue_10;
  }

  &:hover {
    text-decoration: none;
    background: $light_blue_04;
  }

  .cs_chatboard_Pitem_img {
    width: 48px;
    height: 48px;
    @include respond-to(phone-md) {
      width: 34px;
      height: 34px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }

  .cs_chatboard_Pitem_name {
    color: $color_outer_space_approx;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .cs_chatboard_Pitem_cmsg {
    color: $abbey_light;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 11px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cs_chatboard_Pitem_details {
    width: calc(100% - 100px);
    padding-left: 15px;
    padding-right: 10px;
  }

  .cs_chatboard_Pitem_lastseen {
    width: 50px;
    text-align: right;
    line-height: normal;

    .cs_chatboard_Pitemlastseen_time {
      color: $abbey_light;
      font-weight: 400;
      font-size: 10px;
      margin-bottom: 5px;
    }

    .cs_chatboard_Pitem_count {
      color: $white;
      background: $magic_potion;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      font-size: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
    }
  }
}

.cs_chatboard_wrapper {
  .cs_chatboard_right {
    width: calc(100% - 360px);
    height: 100%;
    position: relative;
    padding-top: 86px;
    padding-bottom: 73px;
    transition: 0.4s;
    @include respond-to(desktop-xs) {
      padding-top: 78px;
    }
  }

  .cs_chatboard_right_no_chat {
    width: calc(100% - 360px);
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    img {
      display: block;
      width: 420px;
      height: auto;
    }

    .no_chat_text {
      margin-bottom: 0px;
      margin-top: 20px;
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #95a0b1;
      width: 100%;
    }
  }

  .help_csm_chat_wrapper {
    .help_csm_chat_footer {
      background-color: $white;
      padding: 17px 20px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      box-shadow: 0px -2px 10px #f2f2f2;
    }

    .help_csm_chat_header {
      background-color: $white;
      padding: 18px 20px 18px 20px;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      z-index: 2;
      box-shadow: 0px 2px 10px #f2f2f2;

      .back_btn {
        display: inline-block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        left: 0px;
        border-left: 1px solid $color_outer_space_approx;
        border-bottom: 1px solid $color_outer_space_approx;
        display: none;
      }

      .cs_chatboard_chat_dots_menu {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
      }

      .help_csm_chatH_OP_btn {
        border: none;
        padding: 0px;
        background-color: transparent;
        cursor: pointer;
      }
    }

    height: 100%;

    .help_csm_chatMSG_box {
      padding: 24px 28px;
      height: 100%;
    }
  }
}

.cs_chatboard_chat_dots_menu .user_dropdown_menu {
  position: absolute;
  top: 35px;
  width: 206px;
  right: -3px;
  background-color: $white;
  box-shadow: 4px 8px 36px $black_8;
  border-radius: 8px;
  padding: 15px;
  display: none;

  &.show {
    display: block;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        .menu_label {
          color: $color_outer_space_approx;
        }

        .img_wrapper img {
          filter: grayscale(1) brightness(0.2);
        }
      }
    }
  }

  .menu_label {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    color: $dark_gray70_left_20;
    transition: 0.5s;
    cursor: pointer;
  }

  .menu_label:hover {
    color: $celtic-blue;
  }

  ul .img_wrapper {
    width: 26px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 5px;
  }

  .menus_group {
    border-bottom: 1px solid $bright_gray-1;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: none;
    }
  }
}

.cs_chatboard_uploadDropdown_wrapper {
  position: relative;

  .cs_chatboard_uploadDropdown {
    background: $white;
    box-shadow: 4px 8px 36px $black_8;
    border-radius: 8px;
    padding: 15px;
    display: none;
    position: absolute;
    bottom: 64px;
    width: 184px;
    right: -86px;

    &.show {
      display: block;
    }

    .cs_chatboard_uploadD_items {
      padding: 0px;
      margin: 0px;
      list-style: none;
    }

    .cs_chatboard_uploadD_item {
      img {
        display: inline-block;
        margin-right: 6px;
      }

      a,
      .cs_chatboard_uploadD_itemI {
        text-decoration: none;
        display: flex;
        padding: 8px 15px 10px 15px;
        cursor: pointer;

        &:hover {
          background: $light_blue2_08;
          border-radius: 8px;
        }
      }
    }

    .cs_chatboard_UD_item_label {
      color: $black_coral;
      font-weight: 400;
      font-size: 12px;
    }
  }

  .help_csm_chat_uploadpin_btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;

    img {
      filter: grayscale(1) brightness(1);
    }

    &.active {
      background-color: $light_blue2_08;

      img {
        filter: unset;
      }
    }
  }
}

.cs_chatboard_wrapper .help_csm_chat_wrapper .help_csm_chat_footer .help_csm_chat_textarea {
  width: calc(100% - 148px);
}

.chat_delete_modal {
  .inner_box {
    width: 400px;
    max-width: 100%;
  }

  .chat_deleteMI_wrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-width: 740px;
    background-color: $white;
    border-radius: 16px;
    text-align: center;

    img {
      width: 70px;
    }

    .text {
      font-size: 24px;
      margin-top: 15px;
      font-weight: 600;
    }

    .btn_wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .success_btn {
        display: inline-block;
        font-weight: 700;
        color: $white;
        border: 1px solid $color_buttercup_approx;
        border-radius: 5px;
        text-decoration: none;
        padding: 8px 18px;
        font-size: 15px;
        transition: 0.5s;
        cursor: pointer;
        margin-right: 20px;
        background-color: $color_buttercup_approx;
        box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

        &:hover {
          background-color: $color_gamboge_approx;
          border: 1px solid $color_buttercup_approx;
        }
      }

      .cancel_btn {
        color: $black;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/*new_chat_modal*/

.new_chat_modal .cs_chatboard_searchHeader {
  padding: 0px;
  margin-bottom: 25px;

  .cs_chatboard_search_input_wrapper {
    width: 50%;

    &.chat_room_model {
      width: 40% !important;
      @include respond-to(phone-xl) {
        width: 100% !important;
      }

      label.chat {
        left: 15px;
        top: 19px;
        color: $blue_denim;
        font-weight: 400;
      }

      input {
        padding-left: 15px;
      }
    }

    input {
      background-color: $alice_light_blue_2;
      border: 1px solid $alice_light_blue_2;
    }
  }
}

.add_new_boCOf_box {
  .new_chat_add_cbtn {
    border: 1px solid $bright_gray-1;
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.5s;
    box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

    &:hover {
      background-color: $green-blue;
      border: 1px solid $green-blue;

      img {
        filter: grayscale(1) brightness(10);
      }
    }
  }

  .new_chat_add_cbtn_checkBox {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 18px;

    input {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.00000001;
      z-index: 2;
      cursor: pointer;
    }

    .ncBTN_checkBox_icon {
      border: 2px solid $black_coral;
      background-color: $alice_light_blue_2;
      width: 18px;
      height: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      transition: 0.5s;
      position: relative;

      &:before {
        content: '';
        width: 10px;
        height: 5px;
        position: absolute;
        top: 3px;
        left: 2px;
        transform: rotate(-45deg);
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        z-index: 1;
        transition: 0.3s;
        opacity: 0;
      }

      img {
        display: none;
      }
    }

    input {
      &:checked {
        & + .ncBTN_checkBox_icon {
          background-color: $green-blue;
          border: 1px solid $green-blue;

          img {
            filter: grayscale(1) brightness(10);
          }
        }
      }
    }

    input {
      &:checked {
        + {
          .ncBTN_checkBox_icon {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.add_new_chat_box .add_new_boCO_details {
    width: calc(100% - 18px);
    padding-right: 15px;
  }
}

.new_chat_modal {
  .add_new_boCO_contant_wrapper {
    padding-bottom: 0px;
    width: 100%;
    max-width: 100%;
  }

  .add_new_boCO_form {
    max-height: calc(100vh - 295px);
    overflow-y: auto;
    margin-right: -25px;
    width: calc(100% - 44px);
    padding-left: 15px;
  }

  .cGroup_ff {
    max-width: 100%;
    padding-top: 30px;
    text-align: center;

    .cGroup_ff_form {
      button {
        margin-bottom: 10px;
      }
    }

    .custom_label_ef {
      width: calc(100% - 80px);
      margin-right: 15px;
      position: relative;
    }

    input {
      font-size: 14px;
      padding: 9px 16px 9px 16px;
      width: 100%;
      position: relative;
      z-index: 2;
      border: 2px solid $bright_gray-1;
      border-radius: 5px;
      font-weight: 500;
      color: $black;
      background-color: transparent;
    }

    input.fcw_value {
      border: 2px solid $green-blue;
    }

    input.fcw_value + label {
      top: -7px;
      background-color: $white;
      z-index: 2;
      font-size: 12px;
      left: 12px;
      color: $green-blue;
    }

    label {
      color: $blue_shade_grey;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      padding: 0px 5px 0px 5px;
      width: auto;
      position: absolute;
      top: 11px;
      left: 16px;
      transition: 0.5s;
    }

    .submit_btn {
      display: inline-block;
      background-color: $submit_btn_green;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      border-radius: 10px;
      padding: 13px 21px;
      border: 1px solid $submit_btn_green;
      margin: 0px;
      font-family: 'Red Hat Display';
      letter-spacing: 0.04em;
      line-height: 20px;
      transition: 0.5s;
      cursor: pointer;
      @include respond-to(phone-md) {
        padding: 11px 15px;
      }
      &:hover {
        background-color: $submit_btn_green_hover;
        border: 1px solid $submit_btn_green_hover;
      }
    }
  }
}

/*new_chat_modal_end*/

// emoji_custom_design
.help_csm_chat_wrapper {
  .help_csm_chat_footer_inner {
    .emoji-mart {
      position: absolute;
      bottom: 71px;
      left: 0px;
      right: 0px;
      width: 100% !important;
      border: none !important;
    }

    .emoji-mart-bar {
      border-bottom: 0px;

      &:last-child {
        display: none;
      }
    }

    .emoji-mart-search {
      margin: 0px auto;
      padding: 12px 10px;

      input {
        border: none;
        background-color: $ghost-white;
        padding: 12px 45px 12px 15px;
      }

      .emoji-mart-search-icon {
        top: 25px;
        right: 20px;
      }
    }

    .emoji-mart-anchors {
      svg {
        fill: $celtic-blue;
      }

      img {
        fill: $celtic-blue;
      }
    }

    .emoji-mart-anchor-selected {
      .emoji-mart-anchor-bar {
        background-color: $celtic-blue !important;
      }
    }

    .emoji-mart-scroll {
      scrollbar-color: $color_link_water_approx transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 15px;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        &:vertical {
          height: 30px;
          background-color: $color_link_water_approx;
          border-radius: 10px;
        }
      }
    }
  }
}

// emoji_custom_design_end

// chat_modual_img_file_upload
.help_csm_chat_wrapper {
  .help_csm_chat_footer {
    .chatM_upload_IV_box {
      position: absolute;
      bottom: 71px;
      left: 0px;
      right: 0px;
      width: 100%;

      .chatM_upload_IV_box_inner {
        width: 100%;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 200px;
        object-fit: contain;
        display: inline-block;
        padding: 20px;
        border-radius: 30px;
      }

      .img_cover {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .upload_file_name {
          .inner_wrapper {
            text-align: center;
            background-color: $green-blue;
            padding: 10px;
            color: $white;
            border-radius: 10px;
          }

          .file_text {
            margin-bottom: 0px;
            margin-top: 2px;
          }

          padding: 20px 15px;

          img {
            padding: 0px;
            border-radius: 10px;
            width: 80px;
            filter: grayscale(1) brightness(10);
          }
        }

        .chat_UPvideo_wrapper {
          padding: 20px 15px;
        }
      }

      .chatM_upload_IV_btn {
        width: 26px;
        height: 26px;
        background-color: $white;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 20px;
        font-weight: 700;
        transform: rotate(45deg);
        font-size: 18px;
        box-shadow: 1px 1px 6px $chat_close_btn_shadow;

        span {
          position: relative;
          left: 0px;
          top: -1px;
        }
      }
    }
  }

  .help_csm_chat_own_msg {
    .help_csm_co_msg_text {
      .chat_upload_file_name {
        background-color: $celtic-blue;
        color: $white;
        font-weight: 400;
        border-radius: 8px 0px 8px 8px;
        padding: 8px 8px;
        font-size: 14px;
        text-align: center;
        width: auto;
        max-width: 100%;

        .inner_wrapper {
          text-align: center;
          background-color: $white_20;
          border-radius: 4px;
          padding: 10px 10px 5px 10px;
        }

        img {
          display: inline-block;
          width: 40px !important;
          filter: grayscale(1) brightness(10);
          border-radius: 0px !important;
        }

        .file_name_text {
          margin-bottom: 0px;
          margin-top: 2px;
          font-size: 14px;
          width: 160px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:empty {
        display: none;
      }
    }

    .img_with_text_wrapper {
      width: calc(100% - 50px);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    > .help_csm_co_msg_text {
      margin-top: 20px;

      &:first-child {
        margin-top: 0px;
      }
    }

    .help_csm_co_msg_text.img_msg {
      width: auto;
      max-width: 250px;
      padding: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        border-top-right-radius: 0px;
      }
    }
  }

  .help_csm_chat_front_msg {
    .chat_upload_file_name {
      background-color: $celtic-blue;
      color: $white;
      font-weight: 400;
      border-radius: 8px 0px 8px 8px;
      padding: 8px 8px;
      font-size: 14px;
      text-align: center;
      width: auto;
      max-width: 100%;

      .inner_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        padding: 7px 10px;
      }

      img {
        display: inline-block;
        width: 20px !important;
        filter: grayscale(1) brightness(10);
        border-radius: 0px !important;
      }

      .file_name_text {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-left: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }
    }

    .help_csm_co_msg_text.img_msg {
      width: auto;
      max-width: 250px;
      padding: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        border-top-left-radius: 0px;
      }
    }
  }
}

// image-upload-with-without-text-design
.help_csm_chat_own_msg {
  .textTime_wrapper {
    position: relative;
    padding-left: 86px;

    .help_csm_co_msg_text {
      width: 100%;
      max-width: 100%;
      text-align: left;
    }

    .help_csm_co_msg_time {
      position: absolute;
      bottom: 2px;
      left: 0px;
      width: 79.5px;
      text-align: right;
    }
  }

  &.text_msg_with_img {
    .help_csm_co_msg_text {
      &.img_msg {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-end;

        img {
          width: auto;
          max-width: 250px;
        }
      }
    }

    .textTime_wrapper {
      display: inline-flex;
      position: relative;
      padding-left: 86px;

      .help_csm_co_msg_text {
        width: 100%;
        max-width: 100%;
        text-align: left;
        margin-top: 15px;
      }

      .help_csm_co_msg_time {
        position: absolute;
        bottom: 2px;
        left: 0px;

        &:only-child {
          position: static;
        }
      }
    }

    .inner_wrapper {
      text-align: right;
    }

    &.only_DOC_upload {
      .inner_wrapper {
        position: relative;
        padding-left: 86px;
      }

      .textTime_wrapper {
        padding-left: 0px;
        position: absolute;
        left: 0px;
        bottom: 2px;
      }
    }
  }
}

.help_csm_chat_front_msg {
  .textTime_wrapper {
    position: relative;
    padding-right: 86px;

    .help_csm_cf_msg_text {
      width: 100%;
      max-width: 100%;
      text-align: left;
    }

    .help_csm_cf_msg_time {
      position: absolute;
      bottom: 2px;
      right: 0px;
      width: 79.5px;
      text-align: left;
    }
  }

  &.text_msg_with_img {
    .help_csm_cf_msg_text {
      &.img_msg {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-end;

        img {
          width: auto;
          max-width: 250px;
        }
      }
    }

    .textTime_wrapper {
      display: inline-flex;
      position: relative;
      padding-right: 86px;

      .help_csm_cf_msg_text {
        width: 100%;
        max-width: 100%;
        text-align: left;
        margin-top: 15px;
      }

      .help_csm_cf_msg_time {
        position: absolute;
        bottom: 2px;
        right: 0px;

        &:only-child {
          position: static;
        }
      }
    }

    .inner_wrapper {
      text-align: left;
    }

    &.only_DOC_upload {
      .inner_wrapper {
        position: relative;
        padding-right: 86px;
      }

      .textTime_wrapper {
        padding-right: 0px;
        position: absolute;
        left: unset;
        right: 0px;
        bottom: 2px;
      }
    }
  }
}

/*discussion_end*/

/*HIGHLIGHT PAGE START*/
.highlights_page_wrapper {
  width: 812px;
  max-width: 100%;
  margin: 0px auto;

  .individual_bonuses_t_header.highlight_P_head {
    justify-content: flex-end;
  }
}

.highlights_post_box {
  background-color: $white;
  border: 1px solid $bright_gray-1;
  border-radius: 16px;
  padding: 20px 35px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .highlights_post_title {
    color: $color_outer_space_approx;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 2px;

    strong {
      font-weight: 700;
    }
  }

  .highlights_post_time {
    font-family: 'Roboto';
    font-weight: 400;
    color: $black_coral;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .highlights_post_header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  .highlights_post_img {
    display: inline-block;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  .highlights_post_user_data {
    width: calc(100% - 42px);
    margin-top: 3px;
    padding-left: 15px;
  }

  .highlights_post_contant {
    .highlights_post_text,
    p {
      color: $color_outer_space_approx;
      font-family: 'Roboto';
      font-weight: 300;
      line-height: 22px;
      margin-bottom: 18px;
    }

    .file_wrapper {
      text-align: center;
      background-color: $alice_light_blue_2;
      border-radius: 16px;
      margin-bottom: 15px;
      position: relative;
      overflow: hidden;
    }

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 200px;
      display: inline-block;
      border-radius: 16px;
    }

    video {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 250px;
      display: inline-block;
      border-radius: 16px;
    }

    .bg_img,
    .bg_video {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: blur(10px);
    }

    .show_img,
    .show_video {
      position: relative;
      z-index: 2;
    }
  }

  .highlights_post_footer {
    display: flex;
    align-content: center;
    padding-top: 20px;

    a {
      text-decoration: none;
    }

    .highlights_post_footer_item {
      margin-right: 50px;
      display: inline-flex;
      cursor: pointer;
      position: relative;
      padding-bottom: 3px;

      &.like_item {
        & > div {
          display: inline-flex;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 0px;
        height: 2px;
        background-color: $celtic-blue;
        transition: 0.5s;
      }

      &.active:before {
        width: calc(100%);
      }
    }

    .hpf_count {
      color: $black_coral;
      font-size: 12px;
      font-weight: 400;
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.employee_highlights_modal {
  .highlights_post_footer {
    .highlights_post_footer_item {
      background-color: transparent;
      padding: 9px 20px 6px 20px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-right: 0px;

      &:before {
        display: none;
      }
    }
  }
}

// add-highlight-modal
.add_highlight_modal {
  .highlightModalTextarea {
    height: 140px;
  }

  .highlight_modal_title {
    color: $color_theme_blue_color;
    text-align: center;
    margin-bottom: 35px;
    font-weight: 400;
    letter-spacing: 0.01em;
    font-size: 36px;
    @media screen and (max-width: 1199px) {
      font-size: 26px;
      margin-bottom: 26px;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .inner_box {
    width: 1300px;
    max-width: 100%;
    padding: 0px 0px 0px 0px;
    @media screen and (max-width: 1400px) {
      max-width: 90%;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    .modal-close-btn {
      right: calc(((100vw - 1300px) / 2) - 40px);
    }

    .cs_form_submit_row {
      position: static;
      justify-content: flex-start;
    }

    .add_highlightM_Right {
      .cs_form_submit_row input[type='submit'] {
        padding: 13px 24px;
      }
    }
  }

  .add_highlightM_wrapper {
    width: 100%;
    height: auto;
    max-width: 100%;
    background-color: $white;
    border-radius: 16px;
    padding: 60px 80px;
    position: relative;
    @media screen and (max-width: 1199px) {
      padding: 40px 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 15px;
    }
    textarea {
      height: 120px;
      resize: none;
    }

    .cs_form_row {
      margin-bottom: 15px;

      &.file_type {
        margin-bottom: 25px;
      }
    }

    .cs_form_row.file_type {
      input {
        border-radius: 7px;
        border: 1px dashed $color_theme_blue_color;
      }
    }
  }

  .add_highlight_modal-main {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .add_highlightM_Left {
      width: 50%;
      background-color: #f7f8fd;
      border-radius: 16px 0px 0px 16px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 15px;
        border-radius: 16px 16px 0px 0px;
      }
      img {
        max-width: 100%;
        @media screen and (max-width: 767px) {
          width: 100%;
          width: 160px;
          height: auto;
        }
      }
    }

    .add_highlightM_Right {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .package_cs_select_box > div > div:first-of-type {
        border: 2px solid #2376c4;
        font-weight: 400;

        div {
          color: $blue_denim;

          svg {
            fill: #272c32;
          }
        }
      }

      .cs_form_row textarea {
        border: 2px solid #2376c4;
        font-weight: 400;

        div {
          color: $blue_denim;
        }
      }

      .highlightModalTextarea {
        color: $blue_denim;

        &::-webkit-input-placeholder {
          /* Edge */
          color: $blue_denim;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $blue_denim;
        }

        &::placeholder {
          color: $blue_denim;
        }
      }
    }
  }

  .custom_file_upload {
    &.custom_file_upload_success {
      .img_upload_wrapper {
        img {
          border: 2px solid $color_theme_blue_color;
        }
      }
    }

    .title {
      border: 1px solid $color_theme_blue_color;
      border-radius: 100px;
      color: $color_theme_blue_color;
      display: inline-block;
      font-size: 14px;
      padding: 13px 27px;
      transition: 0.5s;
      cursor: pointer;
      font-weight: 400;
      line-height: 20px;

      &:hover {
        background-color: $color_theme_blue_color;
        color: $white;
      }
    }
  }
}

// ADD HIGHLIGHTS MODAL END

/*employee_highlights_modal*/
.cs_moda_main_wrapper.show.employee_highlights_modal {
  align-items: flex-start;
}

.employee_highlights_modal .highlights_post_box {
  padding: 28px 28px 0px 28px;
  border-radius: 0px;
  background-color: transparent;
  border: none;
  margin-bottom: 0px;
  border-bottom: 1px solid $bright_gray-1;
  @include respond-to('phone-md') {
    padding: 15px 15px 0px 15px;
  }
}

.employee_highlights_m_contant {
  width: 800px;
  max-width: 100%;
  margin: 0px auto;
  background-color: $white;
  border-radius: 16px;
  position: relative;
}

.employee_highlights_modal .employee_highlights_modal_close_btn {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 29px;
  right: 28px;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 2px;
    background-color: $color_cutty_sark_approx;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.em_highlights_comment_wrapper {
  background-color: $alice_light_blue_2;
  padding: 25px;
  max-height: 380px;
  overflow-y: auto;
  -ms-overflow-style: none;
  @include respond-to('phone-md') {
    padding: 10px;
  }

  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .highlight_no_comments {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: inline-block;
      width: 28px;
      margin-right: 10px;
    }
  }

  .like_sec,
  .comments_sec {
    display: none;
  }

  .like_sec.show {
    display: block;
  }

  .comments_sec.show {
    display: block;
  }
}

.employee_highlights_modal {
  .help_csm_chat_footer {
    padding: 17px 24px;
    border-top: 1px solid $bright_gray-1;
    @include respond-to('phone-md') {
      padding: 17px 15px;
    }
  }

  .help_csm_chat_footer_inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .help_csm_chat_footer {
    .help_csm_chat_emoji_btn {
      width: 36px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .help_csm_chat_textarea {
      border: none;
      color: $color_outer_space_approx;
      font-weight: 400;
      font-size: 14px;
      resize: unset;
      height: 30px;
      padding: 0px 10px;
      line-height: 30px;
      width: calc(100% - 130px);
    }

    .help_csm_chat_send_btn {
      font-weight: 400;
      font-size: 14px;
      color: $white;
      background-color: $green-blue;
      width: 70px;
      padding: 9px 10px;
      display: inline-block;
      text-align: center;
      border-radius: 100px;
      margin-left: 20px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .highlights_post_box {
    .highlights_post_contant {
      .highlights_post_text,
      p {
        margin-bottom: 0px;
      }
    }

    .highlights_post_footer {
      padding-top: 15px;
    }

    .highlights_post_user_data {
      padding-right: 30px;
    }
  }
}

.em_highlights_comment_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $white;
  border: 1px solid $bright_gray-1;
  border-radius: 16px;
  padding: 17px 20px;

  @include respond-to('phone-xl') {
    padding: 10px 15px;
    border-radius: 8px;
  }
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }

  .em_highlights_comment_des {
    padding-left: 12px;
    width: calc(100% - 106px);
  }

  .em_highlights_comment_img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    border: 1px solid $bright_gray;
  }

  .em_highlights_commentU_name {
    color: $color_outer_space_approx;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .em_highlights_comment_text {
    color: $color_outer_space_approx;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .em_hl_comment_time_like {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 70px;
  }

  .em_hl_comment_time {
    font-weight: 700;
    font-size: 12px;
    color: $black_coral;
    display: block;
    width: 100%;
    text-align: center;
    line-height: normal;
    margin-bottom: 2px;
  }

  .em_hl_comment_time_like {
    .active_likeFill_icon {
    }
  }
}

.em_highlights_comment_wrapper {
  .like_sec {
    background-color: $white;
    padding: 17px 20px;
    border: 1px solid $bright_gray-1;
    border-radius: 16px;
  }
}

.like_sec {
  .inner_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .em_highlights_comment_box {
    background-color: transparent;
    border: none;
    padding: 0px;
    width: calc(50%);
    border-radius: 0px;
    position: relative;
    border-bottom: 1px solid $bright_gray-1;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 0px;

    &:nth-child(odd) {
      padding-right: 15px;
    }

    &:nth-child(even) {
      padding-left: 15px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      width: 1px;
      height: 100%;
      background-color: $bright_gray-1;
    }

    &:nth-child(even):before {
      display: none;
    }

    .em_highlights_comment_des {
      width: calc(100% - 35px);
    }
  }

  .highlight_no_comments {
    width: 100%;
  }
}

.like_sec .em_highlights_comment_box:nth-last-child(1),
.like_sec .em_highlights_comment_box:nth-last-child(2) {
  border-bottom: none;
}

/*employee_highlights_modal_end*/

/*HIGHLIGHTS-PAGE-END */

.add_people_repeat_row {
  .cs_form_row.input_default_currency {
    position: relative;

    input {
      padding-left: 28px;
    }

    &::before {
      content: '%';
      position: absolute;
      top: 18px;
      left: 20px;
      color: $color_outer_space_approx;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.add_new_boCO_modal {
  .inner_box {
    .add_new_boCO_contant_wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

/*Contest-page-new-design-end*/
.sales_contest_wrapper.new_design {
  .card_design {
    width: 100%;
  }

  .card_design.main_contest_card_box {
    background-color: $white;
    padding: 30px 20px 20px 20px;
    width: 100%;
    max-width: 100%;
  }

  .contest_page_head {
    .title_sec {
      font-size: 14px;
      color: $color_outer_space_approx;
      font-weight: 700;
    }
  }

  .main_contest_card_box {
    .individual_bonuses_t_header {
      justify-content: flex-start;
      padding-right: 54px;

      .title_sec {
        margin-right: 30px;
        font-size: 14px;
        margin-bottom: 0px;
        width: calc(100% - 230px);
      }

      .add_bonus_btn {
        background: unset;
        box-shadow: unset;
        border: none;
        color: $blue_denim;
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 5px 0px;
        border-radius: 50px;

        .rday_count {
          font-size: 18px;
          font-weight: 700;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }

  .sales_contest_user_details {
    width: calc(100% - 60px);
  }

  .sales_contest_user_name {
    white-space: pre;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sales_contest_rank {
    font-size: 12px;
  }

  .sales_contest_box {
    .cc_current_bookings {
      .amount {
        color: $white;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 5px;
      }

      .label {
        color: $white;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 0px;
      }

      width: 27.5%;
      padding: 0px 10px;
      text-align: center;
      @include respond-to('phone-md') {
        width: 50%;
      }

      .inner_wrapper {
        display: inline-block;
      }
    }

    .cc_closed_Deals {
      .amount {
        color: $white;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 5px;
      }

      .label {
        color: $white;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 0px;
      }

      width: 27.5%;
      padding: 0px 10px;
      text-align: center;
      @include respond-to('phone-md') {
        width: 50%;
      }

      .inner_wrapper {
        display: inline-block;
      }
    }

    .inner_box {
      margin-left: -10px;
      margin-right: -10px;
      justify-content: space-between;
    }

    .sales_contestL_profile {
      width: 45%;
      padding: 0px 10px;
      @include respond-to('phone-md') {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    &:nth-child(1) {
      .sales_contest_Pimg {
        border: 2px solid $white;
      }
    }

    &:nth-child(2) {
      .sales_contest_Pimg {
        border: 2px solid $white;
      }
    }

    &:nth-child(3) {
      .sales_contest_Pimg {
        border: 2px solid $white;
      }
    }
  }

  .sales_contestL_profile {
    .inner_wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .other_em_title {
    color: $color_outer_space_approx;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 30px;
  }

  .sales_contest_row {
    display: flex;
    flex-wrap: wrap;
  }

  .contest_sliderM {
    margin-bottom: 0px;

    .slick-arrow {
      &:before {
        display: none;
      }

      width: 14px;
      height: 14px;
      border-left: 3px solid $color_outer_space_approx;
      border-bottom: 3px solid $color_outer_space_approx;
      transform: rotate(45deg);
      position: absolute;
      top: 12px;
      right: 0px;
      left: unset;
      z-index: 3;
      position: absolute;
      display: inline-block;
    }

    .slick-arrow.slick-prev {
      right: 24px;
    }

    .slick-arrow.slick-next {
      transform: rotate(-135deg);
    }

    .contest_card_slide {
      margin-bottom: 30px;
      padding: 20px;
      position: relative;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
      }

      .edit_btn {
        position: absolute;
        top: 5px;
        right: 5px;
        display: none;
        transform: scale(0.9);
      }
      .contest_card-info {
        &:hover .edit_btn {
          display: block;
        }
      }
    }

    .loadMore_wrapper {
      text-align: center;

      .load_more_btn {
        background-color: $color_theme_blue_color;
        border: 1px solid $color_theme_blue_color;
        color: $white;
        border: none;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 14px;
        border-radius: 100px;
        padding: 11px 34px 12px 34px;
        cursor: pointer;
        margin-right: 9px;
        margin-bottom: 20px;
        display: inline-block;
      }
    }
  }
}

.contest_slick_btn_wrapper {
  position: relative;

  .slick-arrow {
    &:before {
      display: none;
    }

    width: 14px;
    height: 14px;
    border-left: 3px solid $color_outer_space_approx;
    border-bottom: 3px solid $color_outer_space_approx;
    transform: rotate(45deg);
    position: absolute;
    top: 42px;
    right: 25px;
    left: unset;
    z-index: 3;
    position: absolute;
    display: inline-block;
  }

  .slick-arrow.slick-prev {
    right: 50px;
  }

  .slick-arrow.slick-next {
    transform: rotate(-135deg);
  }
}

.people_single_DATA {
  .people_single_Dright {
    .sales_contest_wrapper.new_design {
      .sales_contest_row {
        .card_design {
          border: 2px solid $gray_cultured;
          background-color: $white;
        }
      }

      .card_design.main_contest_card_box {
        width: 100%;
      }
    }
  }
}

.sales_contest_wrapper.new_design.contestPage_cc {
  .sales_contest_box {
    &:nth-child(1) {
      width: calc(33.3333% - 17px);
      margin-right: 24px;
      @include respond-to('tablet-md') {
        width: 100%;
        margin-right: 0px;
      }

      .sales_contestL_profile {
        width: 100%;
        padding: 0px;
        padding-top: 25px;
        position: relative;

        .inner_wrapper {
          width: 100%;
          justify-content: center;
        }
      }

      .sales_contest_user_details {
        width: 100%;
        margin-left: 0px;
      }

      .sales_contest_user_name {
        color: $white;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 20px;
      }

      .sales_contest_Pimg {
        width: 86px;
        height: 86px;
      }

      .sales_contest_rank {
        img {
          position: relative;
          top: -1px;
        }

        position: absolute;
        top: 0px;
        left: 0px;
      }

      .cc_current_bookings {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }

      .cc_closed_Deals {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &:nth-child(2) {
      width: calc(33.3333% - 17px);
      margin-right: 24px;
      @include respond-to('tablet-md') {
        width: 100%;
        margin-right: 0px;
      }

      .sales_contestL_profile {
        width: 100%;
        padding: 0px;
        padding-top: 25px;
        position: relative;

        .inner_wrapper {
          width: 100%;
          justify-content: center;
        }
      }

      .sales_contest_user_details {
        width: 100%;
        margin-left: 0px;
      }

      .sales_contest_user_name {
        color: $white;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 20px;
      }

      .sales_contest_Pimg {
        width: 86px;
        height: 86px;
      }

      .sales_contest_rank {
        img {
          position: relative;
          top: -1px;
        }

        position: absolute;
        top: 0px;
        left: 0px;
      }

      .cc_current_bookings {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }

      .cc_closed_Deals {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &:nth-child(3) {
      width: calc(33.3333% - 17px);
      @include respond-to('tablet-md') {
        width: 100%;
        margin-right: 0px;
      }
      .sales_contestL_profile {
        width: 100%;
        padding: 0px;
        padding-top: 25px;
        position: relative;

        .inner_wrapper {
          width: 100%;
          justify-content: center;
        }
      }

      .sales_contest_user_details {
        width: 100%;
        margin-left: 0px;
      }

      .sales_contest_user_name {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 20px;
      }

      .sales_contest_Pimg {
        width: 86px;
        height: 86px;
      }

      .sales_contest_rank {
        img {
          position: relative;
          top: -1px;
        }

        position: absolute;
        top: 0px;
        left: 0px;
      }

      .cc_current_bookings {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }

      .cc_closed_Deals {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .inner_box {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .contest_sliderM {
    position: relative;
    .slick-arrow {
      right: 5px;
    }

    .slick-arrow.slick-prev {
      right: 30px;
    }
  }
}

/*Contest-page-new-design-end*/

/*404-ERROR-PAGE START*/
.error_404_wrapper {
  text-align: center;
  padding: 30px 15px 40px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner_wrapper {
    width: 600px;
    max-width: 100%;
    margin: 0px auto;
  }

  img {
    display: inline-block;
    width: 600px;
    max-width: 100%;
  }

  .error_heading {
    font-weight: 700;
    font-size: 30px;
    margin-top: 20px;
  }

  .text {
    font-size: 16px;
  }

  .error_404_btn {
    display: inline-block;
    background-color: $color_theme_blue_color;
    border: 1px solid $color_theme_blue_color;
    color: $white;
    border: none;
    font-weight: 700;
    font-size: 14px;
    border-radius: 100px;
    padding: 11px 34px 12px 34px;
    margin-top: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}
/*404-ERROR-PAGE END*/

.skeletonLoader_active {
  position: relative;
}

.skeletonLoader_active .home_chart_wrapper .saRe_card_design {
  position: relative;
  height: 130px;
  overflow: hidden;
}

.skeleton_loader_line_wrapper {
  display: none;
}

.skeletonLoader_active .skeleton_loader_line_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 3;
  padding: 20px;
  display: block;
  overflow: hidden;
  border-radius: 16px;
}

.skeletonLoader_active .skeleton_loader_line_wrapper .line {
  display: inline-block;
  width: 100%;
  height: 15px;
  background-color: $light_grey_pagination;
  margin-bottom: 18px;
  border-radius: 4px;
}

.skeletonLoader_active .skeleton_loader_line_wrapper .line:nth-child(odd) {
  width: 50%;
}

.skeletonLoader_active .skeleton_loader_line_wrapper .line:nth-child(3n) {
  width: 70%;
}

.skeletonLoader_active .skeleton_loader_line_wrapper:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  z-index: 3;
  background-repeat: repeat-y;
  background-size: 100px 600px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 150% 0;
  }
}

.skeletonLoader_active .home_chart_wrapper .sale_TPM_card_design,
.skeletonLoader_active .total_sale_card_design {
  height: 280px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .csp_table_wrapper {
  border-radius: 20px;
}

.skeletonLoader_active .csp_table_wrapper,
.skeletonLoader_active .pipeline_details_card {
  height: 210px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .people_card_box .inner_box {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  height: 320px;
}

.skeletonLoader_active .people_single_page .people_WLdata_box {
  height: 320px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .people_single_page .individualB_box .inner_box {
  height: 130px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .people_single_page .main_contest_card_box {
  height: 320px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .individual_bonuses_sswrapper .individual_bb_box {
  height: 320px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .contestPage_cc .contest_sliderM {
  height: 500px;
  overflow: hidden;
  position: relative;
}

.skeletonLoader_active .highlights_post_box {
  height: 205px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.skeletonLoader_active .cs_chatboard_wrapper .cs_chatboard_left {
  position: relative;
}

.skeletonLoader_active .noData_found {
  display: none;
}

// fancy_loading_end

// add_bonus_modal
.add_bonus {
  .modal-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 35px;

    .modal-title {
      margin-bottom: 12px;
      padding-right: 20px;
      min-width: 230px;
    }

    .select-all-btn {
      margin-bottom: 12px;
    }
  }

  .add_new_boCO_form_Mwidth {
    max-width: 740px;
    width: 100%;
    margin: 0px auto;
  }

  .no_people {
    text-align: center;

    img {
      display: inline-block;
      width: 80px;
    }

    .title {
      font-weight: 700;
      font-size: 22px;
      margin-top: 10px;
    }

    .add_link {
      background-color: $submit_btn_green;
      color: $white;
      font-size: 14px;
      font-weight: 700;
      border-radius: 10px;
      padding: 10px 30px;
      transition: 0.5s;
      border: 1px solid $submit_btn_green;
      margin: 0px;
      margin-top: 0px;
      text-decoration: none;
      margin-top: 12px;

      &:hover {
        background-color: $submit_btn_green_hover;
        border: 1px solid $submit_btn_green_hover;
      }
    }
  }
}

// add_bonus_modal_end

.people_card_box.goals_profile {
  .profile_p_details {
    margin-bottom: 0px;
  }

  .h_details_box {
    padding: 0px 0px;
    margin: 35px 0px;
    text-align: center;
    @include respond-to('tablet-md') {
      margin: 20px 0px;
    }

    .value {
      .m_value {
        color: $blue_commision_profile;
      }
    }

    .title {
      margin-bottom: 0px;
      margin-top: 7px;
    }
  }
}

// goals page dashboard
.goals_dashboard_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 34px;
  @include respond-to('phone-xl') {
    margin-bottom: 20px;
  }

  .total_sale_wrapper {
    .h_details_box {
      padding: 20px 15px 0px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond-to('phone-md') {
        flex-wrap: wrap;
        padding: 15px 15px 0px 15px;
        .left {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      .right-pl {
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-to('phone-md') {
          width: 100%;
          .profit,
          .lose {
            width: 50%;
          }
        }
      }
      .profit,
      .lose {
        padding: 4px 15px;
        font-size: 12px;
        border-radius: 10px;
        display: block;
        font-family: 'Red Hat Display';
        font-weight: 800;
        letter-spacing: 0.04em;
        line-height: 19.2px;
        span {
          font-size: 10px;
          line-height: 17px;
          display: block;
          font-weight: 500;
          font-family: 'Roboto';
        }
      }
      .profit {
        background-color: rgba(41, 215, 223, 0.1);
        color: $color_bright_sun_approx;
        margin-bottom: 5px;
        margin-right: 12px;

        &.green-bg {
          background-color: rgba(29, 193, 138, 0.1);
          color: #1dc18a;
        }

        &.red-bg {
          background-color: rgba(238, 64, 115, 0.1);
          color: #ee4073;
        }
      }
      .lose {
        color: $blue_denim;
        margin-bottom: 5px;
        background-color: rgba(39, 117, 189, 0.1);

        &.green-bg {
          background-color: rgba(29, 193, 138, 0.1);
          color: #1dc18a;
        }

        &.red-bg {
          background-color: rgba(238, 64, 115, 0.1);
          color: #ee4073;
        }
      }

      .m_value {
        color: $color_bright_sun_approx;
      }
    }
  }

  .total_sale_wrapper {
    width: calc(100% - 262px);
    padding-right: 26px;
    @include respond-to('phone-xl') {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }
    .card_design.total_sale_card_design {
      min-height: 272px;
      margin-bottom: 0px;
    }
  }

  .sale_TPM_wrapper {
    padding-right: 0px;
    width: 260px;
    @include respond-to('phone-xl') {
      width: 100%;
    }

    .card_design.sale_TPM_card_design {
      min-height: 272px;
      margin-bottom: 0px;
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 15px;
      position: relative;

      .h_details_box,
      .column_chart_double {
        width: 100%;
      }
    }
  }
}

.goals_profile {
  .edit_profile {
    padding: 0px;
    position: absolute;
    top: 16px;
    right: 17px;
    z-index: 3;
    display: none;
  }

  .inner_box {
    padding: 32px 22px 10px 22px;

    &:hover {
      .edit_profile {
        display: block;
      }
    }
  }
}

.column_chart_double {
  .apexcharts-series path {
    clip-path: unset;
  }
}

.chart_wrapper {
  .chart_title {
    color: $color_outer_space_approx;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 22px;
    @include respond-to('phone-md') {
      margin-bottom: 10px;
    }
  }
}

.annual_sales_goal {
  height: 175px;

  .apexcharts-legend-series {
    display: inline-flex !important;
    flex-wrap: wrap;
    align-items: center;
    margin: 0px !important;
    margin-right: 22px !important;

    &:last-child {
      margin-right: 0px !important;
    }
  }

  .apexcharts-legend-marker {
    border-radius: 0px !important;
    width: 17px !important;
    height: 17px !important;
    margin-right: 7px !important;
  }

  .apexcharts-legend-text {
    color: $spaces_black !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-family: 'Roboto' !important;
  }
}

// goals page dashboard end

// commissions_modal
.commissions_modal {
  .mx_width {
    width: 1136px;
    max-width: 100%;
    margin: 0px auto;
  }

  .add_new_btn {
    display: inline-block;
    color: $color_theme_blue_color;
    border: 1px solid $color_theme_blue_color;
    font-size: 14px;
    padding: 9px 24px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 35px;
  }

  .table_commission {
    margin-top: 76px;
  }

  .commissions_modal_header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 44px;
    @include respond-to('phone-md') {
      margin-bottom: 20px;
    }

    .left_profile_details,
    .total_details_box {
      width: 50%;
      @include respond-to('phone-md') {
        width: 100%;
      }
    }

    .total_details_box {
      padding-left: 120px;
      @include respond-to('desktop-xs') {
        padding-left: 100px;
      }
      @include respond-to('tablet-md') {
        padding-left: 80px;
      }
      @include respond-to('phone-xl') {
        padding-left: 10px;
        text-align: right;
      }
      @include respond-to('phone-md') {
        padding-left: 0;
        text-align: right;
      }
    }

    .left_profile_details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 20px;

      .profile_IMG {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .pf_detail {
        width: calc(100% - 52px);
        padding-left: 15px;

        .user_name {
          color: $color_outer_space_approx;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 4px;
        }

        .p_text {
          color: $color_outer_space_approx;
          font-size: 12px;
          font-weight: 300;
          margin-bottom: 0px;
        }
      }
    }

    .total_details_box {
      .td_box_inner {
        background: $alice_light_blue;
        border-radius: 16px;
        display: inline-block;
        padding: 20px 20px;
        min-width: 326px;
        max-width: 100%;
        @include respond-to('desktop-xs') {
          min-width: 300px;
        }
        @include respond-to('tablet-md') {
          min-width: 250px;
        }
        @include respond-to('phone-xl') {
          min-width: auto;
          text-align: left;
          padding: 15px;
        }
        @include respond-to('phone-xl') {
          width: 100%;
        }

        .label {
          color: $color_outer_space_approx;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 4px;
        }

        .amount {
          color: $green-blue;
          font-size: 22px;
          font-weight: 300;
          margin-bottom: 0px;

          .pl_text {
            font-size: 10px;
            font-weight: 400;

            &.green {
              color: $color_ocean_green_approx;
            }

            &.red {
              color: $color_cerise_red_approx;
            }
          }
        }
      }
    }
  }

  .commissionM_wrapper {
    .commissionM_row {
      margin-left: -40px;
      margin-right: -40px;
      display: flex;
      flex-wrap: wrap;
      @include respond-to('desktop-xs') {
        margin-left: -20px;
        margin-right: -20px;
      }
      @include respond-to('phone-xl') {
        margin-left: -15px;
        margin-right: -15px;
        height: 360px;
        overflow: auto;
      }
      .left_months,
      .right_months {
        width: 50%;
        padding: 0px 40px;
        @include respond-to('desktop-xs') {
          padding: 0px 20px;
        }
        @include respond-to('tablet-xl') {
          width: 100%;
          padding: 0px 15px;
        }
      }
      .right_months {
        @include respond-to('tablet-xl') {
          .commissionM_box {
            &:first-child {
              display: none;
            }
          }
        }
      }
      .left_months {
        @include respond-to('phone-md') {
          .commissionM_box {
            &:first-child {
              display: none;
            }
          }
        }
      }

      .commissionM_box {
        margin-bottom: 14px;
      }

      .mDetails_row {
        margin-left: -7px;
        margin-right: -7px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        @include respond-to('phone-md') {
          box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
          padding: 14px 7px;
          margin-left: 0px;
          margin-right: 0px;
        }
        .mColumn {
          padding: 0px 7px;

          .h_label {
            text-align: center;
            color: #222b2e;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0px;
          }

          .cs_form_label {
            margin: 0px;
          }

          .input_values,
          input {
            border: 2px solid $bright_gray-1;
            border-radius: 5px;
            color: $color_outer_space_approx;
            font-size: 14px;
            font-weight: 300;
            padding: 8px 12px;
            width: 100%;
            text-align: center;
          }

          .notAllow {
            cursor: not-allowed;
          }

          .input_values.disable {
            background-color: $bright_gray-1;
            color: transparent;
          }

          &.month_CL {
            color: $blue_denim;
            font-size: 14px;
            font-weight: 400;
            width: 82px;
            @include respond-to('phone-md') {
              width: 100%;
              font-size: 16px;
              padding-bottom: 5px;
            }
          }

          &.goal_CL,
          &.actual_CL {
            width: calc(50% - 100px);
            @include respond-to('phone-md') {
              width: calc(50%);
              &:before {
                color: $blue_shade_grey;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0px;
                width: 100%;
                text-align: center;
              }
            }
          }
          &.goal_CL {
            &:before {
              @include respond-to('phone-md') {
                content: 'Goal';
              }
            }
          }
          &.actual_CL {
            &:before {
              @include respond-to('phone-md') {
                content: 'Actual';
              }
            }
          }

          &.pl_CL {
            width: 80px;
            text-align: center;
            @include respond-to('phone-md') {
              width: calc(50%);
              padding-top: 7px;
            }

            span {
              font-size: 14px;
              font-weight: 400;

              &.green {
                color: $color_ocean_green_approx;
              }

              &.red {
                color: $color_cerise_red_approx;
              }
            }
          }

          &.edit_CL {
            width: 34px;
            @include respond-to('phone-md') {
              width: calc(50%);
              padding-top: 7px;
            }

            .edit_profile {
              padding: 0px;
              width: 20px;
              height: 20px;
              display: none;

              img {
                display: inline-block;
              }
            }
          }
        }

        &:hover {
          .edit_CL {
            .edit_profile {
              display: block;
            }
          }
        }
      }
    }
  }
}

// commissions_modal_end

// commission_factored_modal
.commission_factored_modal {
  .mx_width {
    width: 540px;
    max-width: 100%;
    margin: 0px auto;
  }

  .rm_row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    .rm_box {
      padding: 0px 10px;
      width: 50%;

      .rm_inner_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .label {
          margin-bottom: 0px;
          max-width: calc(100% - 45px);
          padding-right: 10px;
          font-size: 12px;
          color: $color_outer_space_approx;
          font-weight: 400;
        }
      }
    }
  }

  .inner_box {
    .cs_form_submit_row {
      .cancel-btn {
        margin-right: 12px;
      }
    }
  }

  .commission_factoredM_header {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    .title {
      color: $color_outer_space_approx;
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 0px;
      line-height: 32px;
      letter-spacing: 0.04em;
    }

    .modal-subtitle {
      font-size: 12px;
      color: #222b2e;
      opacity: 0.8;
      font-weight: 400;
      letter-spacing: 0.04em;
      line-height: 19.2px;
      margin-bottom: 0;
    }

    .comission_s_icon {
      margin-right: 21px;
    }
  }

  .commission_factoredF_wrapper {
    margin-top: 40px;
    margin-bottom: 10px;
    min-height: 110px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: calc(100% - 160px);

    @include respond-to('phone-md') {
      width: calc(100% - 80px);
    }
    .cfm_row {
      margin-left: -6px;
      margin-right: -6px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 18px;

      .form_box {
        width: 50%;
        padding: 0px 6px;
        @include respond-to('phone-md') {
          width: 100%;
        }
      }
    }

    input {
      border: 2px solid $blue_denim;
      border-radius: 5px;
      font-weight: 300;
      color: $blue_denim;
      background-color: $white;
      padding: 9px 16px 9px 16px;
      font-size: 14px;
      width: 100%;
      transition: 0.5s;
    }

    input:focus {
      border-color: $blue_denim;
    }

    input::placeholder {
      opacity: 1;
      color: $blue_denim;
    }

    .remove_btn_wrapper {
      width: 100%;
      padding: 0px 6px;
      margin-top: -10px;

      .remove_btn {
        padding: 0px;
        color: $blue_denim;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
        margin-bottom: 12px;
      }
    }

    .add_more_btn {
      padding: 0px;
      color: $blue_denim;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
    }

    .extra_icon_wrap {
      position: relative;

      label {
        color: $blue_shade_grey;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 5px;
        width: auto;
        position: absolute;
        top: 10px;
        left: 14px;
        transition: 0.5s;
      }

      input.fcw_value {
        border: 2px solid $green-blue;
      }

      input.fcw_value + label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
      }
    }
  }
}

// commission_factored_modal_end

// deal_modal
.deal_modal {
  .package_cs_select_box {
    position: relative;
    z-index: 999;
  }

  .inner_box {
    padding: 50px 48px 60px 48px;
    @include respond-to('desktop-xs') {
      padding: 20px 26px 20px 26px;
    }
    @include respond-to('phone-md') {
      padding: 20px 15px 20px 15px;
    }
  }

  .dealModal_header {
    .left_profile_details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .profile_IMG {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .pf_detail {
        width: calc(100% - 52px);
        padding-left: 15px;

        .user_name {
          color: $color_outer_space_approx;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 4px;
        }

        .p_text {
          color: $color_outer_space_approx;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
  }

  .tabsF_wrapper {
    min-height: 318px;
  }

  .dcn_wrapper {
    .tabsF_wrapper {
      padding-left: 230px;
      margin-top: -12px;
      width: 1105px;
      max-width: 100%;
      @include respond-to('tablet-md') {
        padding-left: 0px;
        margin-top: 20px;
      }
    }
  }

  .cs_tab_menu {
    margin-bottom: 15px;

    .tab-a {
      .count {
        color: $color_outer_space_approx;
      }

      &.active-a {
        .count {
          color: $white;
        }
      }
    }
  }

  .data_table_wrapper {
    .table_header {
      margin-bottom: 10px;

      .table_row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 14px 0px;
        position: relative;
        width: calc(100% - 160px);
        @include respond-to('phone-md') {
          width: 100%;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0px;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: $alice_light_blue_2;
          border-radius: 5px;
        }
      }

      .table_td {
        color: $spaces_black;
        font-size: 12px;
        font-weight: 400;
        position: relative;
      }
    }

    .table_body {
      @include respond-to('phone-md') {
        max-height: 250px;
        overflow: auto;
      }
      .table_row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 5px 0px;
        position: relative;
        width: calc(100% - 160px);
        @include respond-to('phone-md') {
          width: 100%;
          margin-bottom: 5px;
        }

        input {
          background-color: $white;
          border: 2px solid #e6edf1;
          border-radius: 5px;
          padding: 5px 10px;
        }
      }

      .table_td {
        color: $green-blue;
        font-size: 14px;
        font-weight: 300;
      }

      .confirm_btn {
        display: inline-block;
        background-color: $color_ocean_green_approx;
        color: $white;
        font-size: 12px;
        font-weight: 300;
        border-radius: 5px;
        padding: 5px 18px;
        transition: 0.5s;
        margin-left: 6px;

        &:hover {
          background-color: $submit_btn_green_hover;
        }
      }

      .won_btn {
        display: inline-block;
        background-color: $color_ocean_green_approx;
        color: $white;
        font-size: 12px;
        font-weight: 400;
        border-radius: 5px;
        padding: 5px 8px;
        transition: 0.5s;
        margin-right: 6px;

        &:hover {
          background-color: $submit_btn_green_hover;
        }
      }

      .lost_btn {
        display: inline-block;
        background: $lost_btn_bg_color;
        color: $black_coral;
        font-size: 12px;
        font-weight: 400;
        border-radius: 5px;
        padding: 5px 8px;
        transition: 0.5s;

        &:hover {
          background-color: $black_coral;
          color: $white;
        }

        &.cancelBtn {
          margin-left: 8px;
        }
      }

      .edit_btn {
        display: inline-block;
        padding: 0px;
        width: 18px;
        height: 18px;
        position: relative;
        top: 1px;
        margin-right: 12px;

        img {
          display: inline-block;
          width: 16px;
        }
      }

      .delete_btn {
        margin-left: 3px;

        img {
          width: 16px;
        }
      }

      .in_review_text {
        color: $color_iron_approx;
        font-size: 14px;
        font-weight: 500;
        white-space: pre;
      }

      .table_rowLS {
        padding-right: 10px;
        max-width: 180px;
        width: auto;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 100%;
        background-color: $alice_light_blue_2;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        align-items: center;
        display: none;
        padding-left: 5px;
        @include respond-to(phone-xl) {
          display: flex;
          background-color: transparent;
        }
      }

      .table_row:hover {
        background-color: $alice_light_blue_2;
      }

      .table_row:hover .table_rowLS {
        display: flex;
      }

      .table_rowLS.in_review_active {
        display: flex;
        background-color: transparent;

        .edit_btn,
        .won_btn,
        .lost_btn,
        .delete_btn,
        .confirm_btn {
          display: none;
        }
      }

      .table_row:hover .table_rowLS.in_review_active {
        background-color: $alice_light_blue_2;

        .edit_btn,
        .won_btn,
        .lost_btn,
        .confirm_btn {
          display: block;
        }
        .delete_btn {
          display: block;
        }
      }
    }

    .table_header,
    .table_body {
      .table_td {
        padding: 0px 10px;
      }

      .company {
        width: 30%;
      }

      .deal_size {
        width: 19%;
      }

      .action {
        width: 16%;
      }
      .commission {
        width: 16%;
      }
      .rate {
        width: 12%;
      }
      .date {
        width: 18%;
      }

      .dayOfAging {
        width: 5%;
      }
      .rate {
        input {
          border: 2px solid $bright_gray-1;
          background-color: $white;
          color: $blue_denim;
          font-size: 14px;
          font-weight: 400;
          padding: 4px 5px;
          border-radius: 5px;
          width: 58px;
          text-align: center;
        }

        input[disabled] {
          border: 2px solid transparent;
          background-color: transparent;
        }
      }
    }

    .default_rate_table {
      .table_header,
      .table_body {
        .company {
          width: 50%;
        }

        .rate {
          width: 12%;
        }
      }
    }
  }

  .btn_wrapperDC {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    .done_btn {
      border: 1px solid $color_theme_blue_color;
      border-radius: 5px;
      color: $color_theme_blue_color;
      font-size: 14px;
      padding: 9px 30px;
      transition: 0.5s;
      background-color: $white;

      &:hover {
        background-color: $color_theme_blue_color;
        border: 1px solid $color_theme_blue_color;
        color: $white;
      }

      &.loader_active {
        position: relative;
        padding-left: 50px;

        .process {
          border-right-color: #c9c9c9;
          top: 8px;
          left: 3px;
        }

        &[disabled] {
          border-color: #c9c9c9;
          color: #c9c9c9;
          cursor: not-allowed;

          &:hover {
            background-color: $white;
          }
        }
      }
    }

    .cancel_btn {
      border: 1px solid transparent;
      border-radius: 5px;
      color: $blue_shade_grey;
      font-size: 14px;
      padding: 9px 30px;
      transition: 0.5s;

      &:hover {
        color: $color_outer_space_approx;
      }
    }
  }

  .add_new_btn {
    display: inline-block;
    color: $white;
    border: 1px solid $submit_btn_green;
    background-color: $submit_btn_green;
    font-size: 14px;
    padding: 9px 24px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 35px;

    &:hover {
      background-color: $submit_btn_green_hover;
      border: 1px solid $submit_btn_green_hover;
    }
  }

  .add_new_form {
    margin-top: 20px;

    .doubleF_wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .form_row.company {
      width: 265px;
      margin-right: 65px;
    }

    .form_row.deal_size {
      width: 150px;
      @include respond-to('phone-md') {
        width: calc(100% - 5px);
      }
    }

    input {
      border: 2px solid $bright_gray-1;
      border-radius: 5px;
      color: $color_outer_space_approx;
      font-size: 14px;
      font-weight: 300;
      padding: 9px 12px;
      width: 100%;
      font-family: 'Roboto';
    }

    .radio_btn_wrapper {
      padding-left: 50px;
      margin-top: 15px;
      @include respond-to('phone-md') {
        padding-left: 15px;
      }
    }

    .crb_wrapper {
      margin-bottom: 7px;
    }

    .custom_radio_box {
      display: inline-block;
      position: relative;
      width: 100%;
      margin-bottom: 10px;

      input {
        margin: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
      }

      label {
        color: $black_coral;
        font-size: 12px;
        font-weight: 400;

        &:before {
          width: 14px;
          height: 14px;
          border: 2px solid $color_theme_blue_color;
          background-color: transparent;
          top: -2px;
        }

        &:after {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $color_theme_blue_color;
          border: none;
          display: block;
          top: 4px;
          left: 6px;
        }
      }

      input:checked + label:after {
        opacity: 1;
      }
    }

    .btn_wrapperDC {
      margin-top: 30px;
      justify-content: flex-start;
    }
  }
}

// deal_modal_end

// wl_confirmation_modal
.wl_confirmation_modal.cs_moda_main_wrapper {
  padding-top: 80px;

  .inner_box {
    width: 522px;
    max-width: 100%;
    padding: 50px 65px 125px 65px;
    @include respond-to(phone-xl) {
      padding: 50px 25px 125px 25px;
    }
    .modal-close-btn {
      display: block;
      right: -30px;
      @include respond-to(phone-xl) {
        right: 0;
        top: -22px;
      }
    }

    .cs_form_submit_row {
      bottom: 55px;
    }
  }

  .text {
    color: $color_outer_space_approx;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }
}

// wl_confirmation_modal_end

// highlight_modal
.employee_highlights_modal {
  .inner_box {
    padding: 0px 0px;
    width: 812px;

    .modal-close-btn {
      right: calc(((100vw - 812px) / 2) - 40px);
    }

    .employee_highlights_m_contant {
      width: 100%;
      overflow: hidden;
    }

    .help_csm_chat_footer {
      background-color: #f6f8fa;
      border-top: none;
      padding-top: 0px;

      .help_csm_chat_footer_inner {
        padding: 20px 20px;
        background-color: $white;
        border-radius: 20px;
        border: 1px solid $bright_gray-1;
        @include respond-to('phone-xl') {
          padding: 10px 15px;
          border-radius: 10px;
        }
      }
    }
  }
}

// highlight_modal_end

.new_chat_modal {
  .inner_box {
    padding: 48px 48px 48px 48px;

    @include respond-to('phone-xl') {
      padding: 20px 15px;
    }
  }

  .cs_chatboard_searchHeader {
    .cs_chatboard_search_input_wrapper {
      img {
        top: 13px;
      }

      input {
        border: 10px solid #e7f0f7;
        border-radius: 15px;
        font-weight: 500;
        color: #000000;
        background-color: transparent;
        max-width: 318px;
      }

      input.fcw_value {
        border-color: $green-blue;
      }

      input.fcw_value + label {
        top: -20px !important;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        left: 12px;
        color: $green-blue;
      }
    }
  }

  .new_chathead {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .modal_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 0;
    }

    .new_chathead_icon {
      margin-right: 23px;
    }
  }
}

.chat_delete_modal {
  .inner_box {
    padding: 26px 48px 98px 48px;

    .modal-close-btn {
      right: calc(((100vw - 400px) / 2) - 40px);
    }

    .save_btn {
      background-color: #36ce8d;
      color: $white;
      font-size: 14px;
      font-weight: 700;
      border-radius: 10px;
      padding: 13px 44px;
      border: 1px solid #36ce8d;
      margin: 0px;
      font-family: 'Roboto';
      cursor: pointer;
    }

    .cancel-btn {
      cursor: pointer;
    }
  }
}

// new-people-inner
.people_single_page {
  .people-chartM-wrapper {
    display: flex;
    flex-wrap: wrap;

    .sale_TPM_wrapper {
      width: 240px;

      .sale_TPM_card_box {
        height: 190px;
      }

      .apexcharts-series path {
        clip-path: unset !important;
      }

      .h_details_box {
        padding: 15px 15px 0px 15px;
        position: relative;

        .flag_value {
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }

      .title {
        font-size: 14px !important;
        font-weight: 500 !important;
        padding-right: 35px;
      }

      .value {
        padding-right: 35px;
      }

      .m_value {
        font-size: 22px !important;
      }
    }

    .saRe_card_wrapper {
      width: 280px;
      padding-left: 20px;
      padding-right: 20px;

      .saRe_card_design {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
      }
    }

    .total_sale_wrapper {
      width: calc(100% - 520px);

      .total_sale_card_design {
        margin-bottom: 0px;
      }

      .s_value {
        display: inline-block !important;
      }
    }

    .sale_TPM_wrapper {
      .sale_TPM_card_design {
        position: relative;
        overflow: hidden;
      }
    }

    .saRe_card_wrapper {
      .saRe_card_design {
        position: relative;
        overflow: hidden;
      }
    }

    .total_sale_wrapper {
      .total_sale_card_design {
        position: relative;
        overflow: hidden;
      }
    }
  }
}

// new-people-inner-end

.edit-bonus {
  .modal-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .people-details {
    background-color: #fafbfd;
    padding: 20px 20px;
    border-radius: 12px;
    min-width: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include respond-to('phone-xl') {
      padding: 15px;
    }

    .profile-img {
      display: inline-block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    .contant {
      width: calc(100% - 70px);
      padding-left: 14px;
    }

    .people-name {
      color: #222b2e;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 8px;
      @include respond-to('phone-xl') {
        font-size: 16px;
      }
    }

    .contact-wrapper {
      display: flex;
      align-items: center;
    }

    .phone,
    .email {
      color: #222b2e;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      transition: 0.5s;
      text-decoration: none;

      img {
        display: inline-block;
        width: 20px;
        margin-right: 5px;
        @include respond-to('phone-xl') {
          width: 16px;
        }
      }

      &:hover {
        color: $celtic-blue;
      }
    }

    .phone {
      margin-right: 10px;
    }
  }
}

.help_on_modal {
  .inner_box {
    width: 740px;
    padding: 0px 0px 0px 0px;

    .modal-close-btn {
      right: calc(((100vw - 740px) / 2) - 40px);
    }

    .help_on_modal_contant {
      width: 100%;
    }
  }
}

.how_help {
  .inner_box {
    .cs_form_submit_row {
      position: static;
      width: 100%;
      margin-top: 40px;
    }

    .extra_icon_wrap {
      width: 100%;
    }
  }

  .help_on_modal_contant {
    padding: 30px 30px 40px 30px;

    @include respond-to('phone-xl') {
      padding: 30px 15px 40px 15px;
    }
    .help_modal_header {
      margin-bottom: 30px;
    }
  }
}

.setting_page {
  .cs_form_double_row {
    .extra_icon_wrap {
      position: relative;

      .cs_form_label {
        color: $green-blue;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 5px;
        width: auto;
        position: absolute;
        top: 10px;
        left: 10px;
        transition: 0.5s;
      }

      input.fcw_value + label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
      }

      input.fcw_value {
        border: 2px solid $green-blue;
        @include respond-to('phone-xl') {
          border: 1px solid $green-blue;
        }
      }
    }

    .custom_date_label .cs_form_label {
      color: $green-blue;
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 0px;
      padding: 0px 5px 0px 30px;
      width: auto;
      position: absolute;
      top: 10px;
      left: 10px;
      transition: 0.5s;
    }

    .custom_date_label.fcw_value .cs_form_label {
      top: -7px;
      background-color: $white;
      z-index: 2;
      font-size: 12px;
      color: $green-blue;
      padding-left: 5px;
    }

    .custom_date_label.fcw_value input {
      border: 2px solid $green-blue;
      @include respond-to('phone-xl') {
        border: 1px solid $green-blue;
      }
    }

    .custom_date_field.with_label img {
      top: 12px;
    }

    .react-datepicker__input-container input::placeholder {
      opacity: 0;
    }
  }
}

.area_chart,
.column_chart_double,
#total_sale_chart,
#stp_chart {
  position: relative;
  left: -5px;
}

.contest_slick_btn_wrapper.noData .card_design.main_contest_card_box {
  padding: 0px 0px;
}

.special-accosion-hl {
  background: radial-gradient(34.21% 192.32% at 50% 50%, #f4f7fc 0%, #ebedf0 100%);
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  .left-img {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    height: 100%;
    width: auto;
  }

  .right-img {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: auto;
  }

  .bottom-img {
    position: absolute;
    bottom: 0px;
    transform: translateX(-50%);
    left: 50%;
  }

  .contant-wrapper {
    width: 440px;
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 10px;
  }

  .fs_label {
    font-weight: 500;
    font-size: 10px;
    color: #333333;
    letter-spacing: 0.68em;
    text-align: center;
    text-transform: uppercase;
  }

  .name {
    color: #333333;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 0px;
  }
}

.no_notification_data {
  .notification_items {
    padding: 0px 0px;
  }
}

.no-notification-wrapper {
  padding: 30px 15px;
  text-align: center;

  img {
    display: inline-block;
    width: 50px;
    margin-bottom: 10px;
  }
}

// new-header
.site_header {
  .back_to_pricing {
    color: #222b2e;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0.1px;
    cursor: pointer;

    &:hover {
      color: $color_theme_blue_color;
    }
  }
}

// new-header-end

// signup-page
.signup-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;

  .cs_form_row {
    .custom_date_field img {
      position: absolute;
      top: 11px;
      left: 18px;
      z-index: 1;
    }

    .cs_form_label {
      transition: 0.5s;
    }

    .custom_date_label,
    .extra_icon_wrap {
      background-color: $white;
    }

    .extra_icon_wrap {
      border-radius: 5px;
    }

    input {
      position: relative;
      z-index: 2;
      background-color: transparent;
      transition: 0.5s;
      padding: 12px 16px 12px 16px;
    }

    .custom_date_label.fcw_value input {
      border: 2px solid $green-blue;
    }

    .custom_date_label input::placeholder {
      opacity: 0;
      color: transparent;
    }

    .custom_date_label {
      .cs_form_label {
        color: $blue_shade_grey;
        font-weight: 500;
        margin-bottom: 0px;
        padding: 0px 5px 0px 30px;
        width: auto;
        position: absolute;
        top: 12px;
        left: 10px;
        transition: 0.5s;
      }
    }

    .custom_date_label.fcw_value {
      .cs_form_label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
        padding-left: 5px;
      }
    }

    .signup-page .cs_form_row input.fcw_value + .cs_form_label {
      background-image: linear-gradient(#f5f6fa 50%, #f5f6fa 68%);
    }

    input {
      border: 1px solid #e83865 !important;
    }

    label {
      color: #e83865 !important;
    }

    input.fcw_value {
      border: 1px solid $green-blue !important;
    }

    input.fcw_value + label {
      color: $green-blue !important;
    }
  }

  .lgbg-img {
    position: absolute;
    top: -50px;
    right: 0px;
    width: 21.87vw;
  }

  .container {
    width: 100%;
    max-width: 630px;
    margin: 0px auto;
  }

  .form-unc-links {
    text-align: center;
    margin-top: 25px;

    p {
      color: $color_cutty_sark_approx;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 20px;

      span,
      a {
        color: $blue_denim;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .creatAC-wrapper {
    text-align: center;
    margin-top: 42px;
  }

  .create-account-btn {
    color: $white;
    background-color: #36ce8d;
    border-radius: 10px;
    padding: 15px 30px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto';
    margin: 0px auto;
    position: relative;

    &.loader_active {
      padding: 15px 30px 15px 50px;

      .process {
        top: 14px;
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }

    &:hover {
      background-color: #27ae75;
    }
  }

  .note-text {
    color: $color_cutty_sark_approx;
    font-size: 10px;
    font-weight: 500;
  }

  .form-title {
    color: #222b2e;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
  }

  .double-wrapper {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;

    .cs_form_row {
      padding: 0px 10px;
      width: 50%;
    }
  }

  .cs_form_row {
    margin-bottom: 20px;

    .extra_icon_wrap {
      position: relative;

      .cs_form_label {
        font-weight: 500;
        color: $blue_shade_grey;
        margin-bottom: 0px;
        position: absolute;
        top: 15px;
        left: 14px;
      }
    }

    input.fcw_value {
      border: 2px solid $green-blue;
    }

    input.fcw_value + .cs_form_label {
      top: -7px;
      background-image: linear-gradient(#f5f6fa 50%, #fff 68%);
      z-index: 2;
      font-size: 12px;
      color: $green-blue;
      padding: 0px 5px 0px 5px;
    }
  }
}
@media screen and (max-width: 767px) {
  .signup-page {
    padding-top: 50px;
    .form-title {
      font-size: 20px;
      margin-bottom: 30px;
    }
    .double-wrapper {
      margin-left: 0;
      margin-right: 0;
      flex-wrap: wrap;
      .cs_form_row {
        width: 100%;
      }
    }
    .cs_form_row {
      padding-left: 10px;
      padding-right: 10px;
    }
    .creatAC-wrapper {
      margin-top: 15px;
    }
  }
}

.signup-page {
  &.forgotPassword {
    height: calc(100vh - 90px);
    padding-top: 0px;

    .container {
      width: 470px;
    }
  }
}

// signup-page-end

// add-sales-team-modal
.add-sales-team-modal {
  .inner_box {
    padding: 0px 0px;
    background: #f7f8fd;
  }

  .add_sales-cn-wrapper {
    display: flex;
    flex-wrap: wrap;

    .bulkImportWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #1b3664;
      border-radius: 10px;
      padding: 10px 15px;
      margin-bottom: 24px;
    }

    .left-cn {
      .profileDet {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        background-color: rgba(39, 117, 189, 0.1);
        padding: 14px 14px;
        border-radius: 10px;

        .name {
          font-weight: 500;
          margin-bottom: 0px;
          color: $color_theme_blue_color;
        }

        .userEmail {
          font-weight: 400;
          color: #ef3e6d;
        }
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
      }

      .processBar {
        display: inline-flex;
        align-items: center;
        list-style: none;
        padding-left: 0px;
        margin: 0px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% - 30px);
          transform: translateX(-50%);
          height: 1px;
          background-color: $blue_denim;
        }

        li {
        }

        li:last-child {
          margin-right: 0px;
        }
      }

      .bulk-import-btn-W {
        text-align: center;
      }

      .nameNewLR {
        margin-bottom: 0px;
        color: $blue_denim;
        text-align: center;
        margin-top: -53px;
        margin-bottom: 24px;
      }

      .preNextName {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;

        .name {
          margin-bottom: 0px;
          color: $blue_denim;
          text-align: center;
        }

        .nextBtn {
          position: relative;
          font-weight: 600;
          padding-right: 10px;
          font-size: 14px;
        }

        .prevBtn {
          position: relative;
          font-weight: 600;
          padding-left: 10px;
          font-size: 14px;
        }

        .prevBtn::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border-bottom: 2px solid #000;
          border-right: 2px solid #000;
          transform: rotate(135deg);
          top: 5px;
          left: 0px;
        }

        .nextBtn::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border-bottom: 2px solid #000;
          border-right: 2px solid #000;
          transform: rotate(-45deg);
          top: 5px;
          right: 0px;
        }
      }
      .contant-wrapper {
        position: relative;
        padding: 25px 25px 50px 25px;

        .add-saleTeam-form {
          max-width: 100%;
          margin: 0px auto;

          .add-more-btn {
            font-weight: normal;
          }
        }

        .upload-file-img {
          border-radius: 10px;
          width: 510px;
          margin: 0px auto;
          margin-top: 50px;
          text-align: center;

          .inner-wrapper {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 30px 10px 20px;
            border: 1px dashed #4294ff;
            background-color: rgba(66, 148, 255, 0.1);
            border-radius: 10px;
            position: relative;

            .close-btn {
              width: 14px;
              height: 14px;
              position: absolute;
              top: 5px;
              right: 5px;
              border-radius: 50%;
              padding: 0px;
              background-color: $white;
              box-shadow: 0px 0px 10px #e3e3e3;

              &:before,
              &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 7px;
                height: 1px;
                background-color: #000;
              }

              &:before {
                transform: translate(-50%, -50%) rotate(45deg);
              }

              &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
              }
            }

            img {
              display: inline-block;
              width: 30px;
            }

            .text {
              font-size: 16px;
              margin-bottom: 0px;
              width: calc(100% - 30px);
              padding-left: 10px;
            }
          }
        }
      }

      .double-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        .cs_form_row {
          width: 50%;
          padding-left: 10px;
          padding-right: 10px;
          @include respond-to('phone-md') {
            width: 100%;
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }

      .three-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        .cs_form_row {
          width: 33.3333%;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .cs_form_row {
        margin-bottom: 20px;
        width: 17%;
        margin: 0px auto 20px;

        .extra_icon_wrap {
          position: relative;
        }

        .custom_date_field.with_label img {
          top: 11px;
          left: 12px;
        }

        .react-datepicker__input-container input::placeholder {
          opacity: 0;
        }

        .custom_date_label.fcw_value input,
        input.fcw_value {
          border: 2px solid $green-blue;
        }

        .custom_date_label.fcw_value .cs_form_label,
        input.fcw_value + .cs_form_label {
          top: -7px;
          background-color: $white;
          // background-image:linear-gradient(#f5f6fa,#fff);
          z-index: 2;
          font-size: 12px;
          color: $green-blue;
          padding: 0px 5px 0px 5px;
        }
      }

      .add-more-btn {
        color: $blue_denim;
        font-size: 14px;
        font-weight: 400;
        padding: 0px;
        margin-bottom: 20px;
        font-weight: normal;
      }
    }

    .right-cn {
      width: 50%;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      padding-top: 100px;
      padding-bottom: 30px;

      .inner-wrapper-right {
        position: relative;
        z-index: 2;
      }

      .right-cn-bg {
        position: absolute;
        top: 20px;
        left: 0px;
        right: 0px;
        width: 100%;
      }

      .right-cn-blue-tree {
        position: absolute;
        left: 37px;
        top: 66%;
        transform: translateY(-50%);
      }

      .right-cn-orange-tree {
        position: absolute;
        right: 37px;
        bottom: 14%;
        transform: translateY(-50%);
      }

      .wtsee-box {
        width: 445px;
        max-width: 100%;
        background: $white;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        padding: 30px;
        margin: 0px auto;

        .title {
          color: #ef3e6d;
          font-size: 36px;
          font-weight: 700;
          line-height: 46px;
          text-align: center;
        }

        p {
          color: $color_cutty_sark_approx;
          font-size: 12px;
          line-height: 24px;
        }

        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
          margin-bottom: 16px;
          margin-top: 10px;

          li {
            color: $color_cutty_sark_approx;
            font-size: 12px;
            line-height: 24px;
            position: relative;
            padding-left: 15px;

            &:before {
              content: '';
              position: absolute;
              top: 10px;
              left: 0px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $color_cutty_sark_approx;
            }
          }
        }

        .pink-btn-wrapper {
          text-align: center;

          .pink-btn {
            background-color: #ef3e6d;
            color: $white;
            text-align: center;
            padding: 11px 22px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 500;
            margin-top: 25px;
          }
        }
      }
    }
  }
}

// add-sales-team-modal-end

// download-predsheet-modal
.download-predsheet-modal {
  .inner_box {
    width: 522px;
    max-width: 100%;
    padding: 50px 65px 125px 65px;
    @include respond-to('phone-md') {
      padding: 30px 30px 105px 30px;
    }
  }

  .text {
    color: #222b2e;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    @include respond-to('phone-md') {
      font-size: 18px;
    }
  }

  .cs_form_submit_row {
    .cancel-btn,
    input {
      width: 125px;
      text-align: center;
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-left: 0px;
      margin-right: 15px;
    }

    .upgrade-loader {
      .process {
        position: relative;
        top: 0px;
        left: 0px;
        margin-left: 6px;
      }
    }

    .upgrade-btn {
      background-color: #ef3e6d;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 12px;
      display: inline-block;
      border-radius: 5px;
      transition: 0.5s;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
}

// download-predsheet-modal-end

// Sales Goals and Past Performance modal
.add-sales-pp-modal {
  .past-per-form {
    width: 420px;
    max-width: 100%;
    margin: 0px auto;

    &.pastPerFormheader {
      .extra_icon_wrap {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .past-per-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
    margin-bottom: 6px;

    .label {
      text-align: right;
      color: $blue_denim;
      font-size: 14px;
      font-weight: 700;
      margin-top: 17px;
    }

    .label {
      width: 100px;
      padding: 0px 7px;
    }

    .goals-box,
    .ac-box {
      width: calc(50% - 50px);
      padding: 0px 7px;
    }

    .goals-box input,
    .ac-box input {
      width: 100%;
      border: 2px solid $blue_denim;
      border-radius: 5px;
      color: $blue_denim; //#222b2e;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 10px;
      text-align: center;
    }

    .goals-box input[disabled],
    .ac-box input[disabled] {
      background: $bright_gray-1;
      color: transparent;
    }
  }
}

// Sales Goals and Past Performance modal end

// email-verification-wrapper
.email-verification-wrapper {
  text-align: center;
  padding-top: 90px;
  position: relative;

  .ev-bg {
    position: absolute;
    top: 100px;
    right: 0px;
  }

  .container {
    width: 900px;
    padding: 0px 15px;
    margin: 0px auto;
    position: relative;
    z-index: 2;
  }

  .contant-wrapper {
    width: 600px;
    max-width: 100%;
    margin: 0px auto;
  }

  .contant-wrapper p {
    color: $black_coral;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;

    .link-text {
      color: $color_theme_blue_color;
      cursor: pointer;
    }
  }

  .title {
    color: #222b2e;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .email-img-wrapper {
    display: inline-block;
    position: relative;
    margin-bottom: 30px;

    .badge {
      border-radius: 50%;
      border: 3px solid $white;
      background-color: #f34875;
      padding: 4px;
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 16px;
      font-weight: 700;
      position: absolute;
      top: 0px;
      right: -2px;
    }
  }
}

// email-verification-wrapper-end

// subscriptionP-wrapper
.subscriptionP-wrapper {
  padding-top: 40px;

  .subscription-bg-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .subscriptionForm-box {
    width: 496px;
    max-width: 100%;
    margin: 0px auto;
    background-color: #f6f8fa;
    border-radius: 10px;
    box-shadow: 4px 8px 36px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 4;

    .sf-head {
      background-color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: #222b2e;
      text-align: center;
      padding: 29px 15px;

      p {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .sf-contant {
      padding: 30px 17px;

      .ss-btn {
        color: $white;
        background-color: #36ce8d;
        border-radius: 10px;
        padding: 15px 10px;
        font-size: 14px;
        font-weight: 400;
        width: 205px;
        max-width: 100%;
        text-align: center;
        display: block;
        margin: 0px auto;
        margin-top: 38px;
        margin-bottom: 20px;
      }

      .secure-wrapper {
        text-align: center;

        .inner {
          display: flex;
          align-items: center;
          color: $black_coral;
          font-size: 14px;
          font-weight: 400;
          justify-content: center;

          .lock-icon {
            display: inline-block;
            margin-right: 7px;
            position: relative;
            top: -2px;
          }
        }
      }
    }

    .subscription-active {
      background: $white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 15px;
      margin-bottom: 20px;

      .title,
      .price {
        color: #222b2e;
        font-size: 14px;
        font-weight: 700;

        span {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }

    .card-details-form {
      border-radius: 10px;
      background-color: $white;

      .double-wrapper {
        display: flex;
        flex-wrap: wrap;

        .cardI-box {
          width: 50%;
        }

        .cardI-box:first-child {
          border-right: 2px solid $bright_gray-1;
        }
      }

      .cardI-box {
        input,
        select {
          width: 100%;
          border: none;
          background-color: transparent;
          color: #222b2e;
          font-size: 14px;
          font-weight: 500;
          padding: 16px 24px;
        }
      }
    }

    .card-details-form > .cardI-box,
    .card-details-form > .double-wrapper {
      border-bottom: 2px solid $bright_gray-1;
    }

    .card-details-form > .cardI-box.no-border,
    .card-details-form > .double-wrapper.no-border {
      border-bottom: none;
    }
  }
}

.free-trial-bar-active {
  .site_header {
    top: 56px;
    @media screen and (max-width: 575px) {
      top: 77px;
    }
  }

  .main_wrapper {
    .rightC_wrapper {
      padding-top: 100px;
      @media screen and (max-width: 575px) {
        padding-top: 115px;
      }
    }
  }
}

.free-trial-bar {
  background-color: #f5f6fa;
  position: fixed;
  top: 0px;
  left: 256px;
  right: 0px;
  z-index: 9;
  @media screen and (max-width: 1199px) {
    left: 0px;
  }

  .inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 24px;
    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
      padding: 12px 14px;
    }
  }

  .upgrade-loader {
    .process {
      position: relative;
      top: 0px;
      left: 0px;
      margin-left: 6px;
    }
  }

  .text {
    color: #222b2e;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-right: 20px;
    @media screen and (max-width: 575px) {
      font-size: 11px;
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .upgrade-btn {
    background-color: #ef3e6d;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 12px;
    border-radius: 5px;
    transition: 0.5s;
    margin-right: 10px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
    &:hover {
      background-color: #ef3e6d;
    }
  }

  .schedule-btn {
    background-color: transparent;
    color: #ef3e6d;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 5px;
    border: 2px solid #ef3e6d;
    transition: 0.5s;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      border: 1px solid #ef3e6d;
    }
    &:hover {
      background-color: #ef3e6d;
      color: $white;
      @media screen and (max-width: 767px) {
        border: 1px solid #ef3e6d;
      }
    }
  }
}

// free-trial-bar-end

// add-sales-teamError-modal
.add-sales-teamError-modal {
  .note_text {
    background-color: rgba(54, 206, 141, 0.1);
    color: #36ce8d;
    font-size: 14px;
    text-align: center;
    padding: 13px 15px;
    border-radius: 10px;

    p {
      line-height: 22px;
      margin-bottom: 0px;
    }
  }

  .inner_box {
    width: 400px;
    padding: 26px 48px 110px 48px;

    .dataMS_astm {
      .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      ul {
        margin: 0px;
        padding: 0px;
        padding-left: 20px;
        margin-bottom: 20px;

        li {
          list-style: none;
          position: relative;
          font-size: 14px;
          color: #444;
          padding-left: 13px;
          margin-bottom: 6px;

          &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0px;
            width: 6px;
            height: 6px;
            background-color: $color_theme_blue_color;
            border-radius: 50%;
          }
        }
      }
    }

    .modal-close-btn {
      right: calc(((100vw - 400px) / 2) - 40px);
    }

    .cs_form_submit_row {
      .cancel-btn {
        margin-left: 0px;
        margin-right: 20px;
      }
    }
  }
}

// add-sales-teamError-modal-end

// customer-page
.customer-page {
  .customerD-card {
    padding: 35px 35px;
    @media screen and (max-width: 1199px) {
      padding: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .customerD-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left,
    .right {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }
      @media screen and (max-width: 575px) {
        width: 100%;
      }

      .filterBtn.reverse {
        img {
          transform: scaleY(-1);
        }
      }
    }

    .right {
      align-items: center;
      @media screen and (max-width: 575px) {
        justify-content: flex-end;
        margin-top: 15px;
      }
    }

    .search-box {
      width: 300px;
      max-width: 100%;
      @media screen and (max-width: 575px) {
        width: 100%;
        margin-bottom: 10px !important;
      }
    }

    .customer-selectBox {
      margin-left: 20px;
      @media screen and (max-width: 575px) {
        margin-left: 0px;
        width: 100%;
      }
    }

    .customer-selectBox * {
      white-space: pre;
    }

    .customer-selectBox > div:last-child {
      width: auto;
      min-width: 100%;
    }

    .customer-selectBox > div {
      border: 2px solid $bright_gray-1;
      border-radius: 5px;
      font-weight: 400;
      color: #000000;
      font-family: 'Roboto';
      font-size: 14px;
    }

    .customer-selectBox > div > div:nth-child(2) > span {
      display: none;
    }

    .extra_icon_wrap.search {
      position: relative;
    }

    .cs_form_row {
      margin-bottom: 0px;

      input {
        padding: 9px 16px 9px 32px;
      }
    }

    .extra_icon_wrap {
      img {
        position: absolute;
        top: 12px;
        left: 12px;
      }

      .cs_form_label {
        color: $blue_shade_grey;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 5px;
        width: auto;
        position: absolute;
        top: 11px;
        left: 30px;
        transition: 0.5s;
        pointer-events: none;
      }

      input.fcw_value + label {
        top: -7px;
        background-color: $white;
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
      }

      input.fcw_value {
        border: 2px solid $green-blue;
      }
    }

    .new-customer-addBtn {
      display: inline-block;
      font-family: 'Roboto';
      font-weight: 400;
      color: $white;
      border: 1px solid $green-blue; // #f4b616;
      border-radius: 5px;
      text-decoration: none;
      padding: 11px 18px;
      position: relative;
      top: -1px;
      font-size: 12px;
      transition: 0.5s;
      cursor: pointer;
      background-color: $green-blue;
      box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
      margin-left: 20px;
      @include respond-to(phone-md) {
        padding: 8px 12px;
        margin-left: 10px;
      }

      &:hover {
        background-color: $color_theme_blue_color_hover;
        border: 1px solid $color_theme_blue_color_hover;
        color: $white;
        text-decoration: none;
      }
    }
  }

  .customerTable-wrapper {
    margin-top: 22px;
    overflow: auto;

    .customerTable {
      width: 100%;
      border-collapse: collapse;

      thead tr {
        td {
          color: #222b2e;
          font-size: 12px;
          font-weight: 400;
          padding: 15px 20px;
          background: #f6f8fa;
          @media screen and (max-width: 767px) {
            padding: 12px 15px;
          }
          &:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      tbody {
        .hide {
          display: none;
        }

        tr {
          &:nth-child(even) {
            background: #fafbfd;
          }

          td {
            padding: 7px 20px;
            color: #222b2e;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            @media screen and (max-width: 767px) {
              padding: 7px 15px;
            }

            .viewBtn {
              color: $blue_denim;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          td.name {
            color: $blue_denim;
            font-size: 14px;
            font-weight: 700;

            button {
              color: $blue_denim;
              font-size: 14px;
              font-weight: 700;
            }
          }
        }
      }
    }

    &.customerTableEMP {
      @media screen and (max-width: 767px) {
        .customerTable {
          thead {
            display: none;
          }
          tbody {
            tr {
              td {
                &:before {
                  content: attr(data-heading) ': ';
                  font-weight: bold;
                  color: $navy_blue;
                  width: 100%;
                  padding-right: 5px;
                }
                display: block;
                width: 100%;
                white-space: normal;
                .viewBtn {
                  color: $white;
                  background: $green-blue;
                  padding: 4px 10px;
                  border-radius: 2px;
                  &:hover {
                    background-color: $color_theme_blue_color_hover;
                  }
                }
              }
              display: block;
              margin-bottom: 20px;
              width: 100%;
              box-sizing: border-box;
              border: 2px solid $bright_gray-1;
              padding: 10px 0px;
              border-radius: 10px;
              box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05);
            }
          }
        }
      }
    }
    // SUPPER ADMIN
    &.customerTableSuperAdmin {
      @media screen and (max-width: 767px) {
        .customerTable {
          thead {
            display: none;
          }
          tbody {
            tr {
              &.toggleTR {
                display: none;
                > td {
                  &:before {
                    display: none;
                  }
                  .toggleTable {
                    background: #fafbfd;
                    tr {
                      padding-top: 5px;
                      padding-bottom: 5px;
                      border-top: 0px solid $bright_gray-1 !important;
                    }
                  }
                }
                &.show {
                  display: table-row;
                  border-top: 0;
                }
                .toggleTable {
                  td {
                    &.name,
                    &.teammate {
                      display: none !important;
                    }
                  }
                }
              }
              td {
                &:before {
                  content: attr(data-heading) ': ';
                  font-weight: bold;
                  color: $navy_blue;
                  width: 100%;
                  padding-right: 5px;
                  @include respond-to(phone-md) {
                    display: block;
                    padding-bottom: 5px;
                    padding-right: 0px;
                  }
                }
                display: block;
                text-align: left;
                width: 100%;
                white-space: normal;
                .companyBtn {
                  @include respond-to(phone-md) {
                    padding-left: 0;
                  }
                }
                &.deleteBtn {
                  img {
                    width: 14px;
                    margin-bottom: -4px;
                  }
                }
              }
              display: block;
              width: 100%;
              box-sizing: border-box;
              border-top: 1px solid $bright_gray-1;
              padding: 7px 0px;
              box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05);
            }
          }
        }
      }
    }
  }
}

// customer-page-end

// super-admin-home-page
.customer-page {
  &.superAdminHome {
    .customerD-header {
      .new-customer-addBtn {
        background-color: #f6f8fa;
        border: none;
        box-shadow: unset;
        cursor: auto;

        .label {
          color: #222b2e;
          font-size: 16px;
          font-weight: 700;
          display: inline-block;
          margin-right: 10px;
          @include respond-to(phone-md) {
            font-size: 14px;
          }
        }

        .value {
          color: $blue_denim;
          font-size: 16px;
          font-weight: 700;
          display: inline-block;
          @include respond-to(phone-md) {
            font-size: 14px;
          }
        }
      }
    }

    td {
      width: 16%;
    }

    .phone {
      color: $blue_denim !important;
      cursor: pointer;
    }

    .toggleTR {
      background-color: transparent !important;
      display: none;

      &.show {
        display: table-row;
      }

      tr:nth-child(2n) {
        background-color: transparent !important;
      }

      .toggleTable {
        td:nth-child(3) {
          color: #43b886 !important;
        }

        tr:nth-child(odd) {
          td:nth-child(3),
          td:nth-child(4),
          td:nth-child(5) {
            background-color: #fafbfd;
          }
        }

        .viewBtn {
          color: #222b2e;
          text-decoration: none !important;
        }
      }
    }

    .toggleTR > td {
      width: 100%;
      padding: 0px 0px !important;
    }

    .toggleTable {
      width: 100%;
    }

    .customerTable-wrapper {
      .customerTable {
        tbody {
          tr {
            td {
              padding: 12px 12px;
              @media screen and (max-width: 1199px) {
                white-space: nowrap;
              }
              @media screen and (max-width: 767px) {
                padding: 8px 15px;
              }
            }
          }
        }
      }
    }

    .companyBtn {
      position: relative;
      padding-right: 16px;
    }

    .companyBtn:before {
      content: '';
      position: absolute;
      top: 5px;
      right: 0px;
      width: 6px;
      height: 6px;
      border-left: 2px solid $blue_denim;
      border-bottom: 2px solid $blue_denim;
      transform: rotate(-45deg);
      transition: 0.5s;
    }

    .companyBtn.active:before {
      transform: rotate(-225deg);
      top: 8px;
    }

    .deleteBtn {
      img {
        width: 20px;
        filter: grayscale(1) brightness(2);
      }

      button:hover {
        img {
          filter: unset;
        }
      }
    }

    td:first-child {
      width: 20%;
    }

    td:last-child {
      width: 8%;
      min-width: 60px;
      text-align: center;
    }

    td {
      .toggleTable {
        td {
          text-align: left;

          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
}

// super-admin-home-page

// Employee Goals page
.empGoals-page {
  .apexcharts-series path,
  .apexcharts-series path {
    clip-path: unset !important;
  }

  .totleBox {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 48px;
    text-align: left;
    @include respond-to('phone-md') {
      margin-bottom: 20px;
    }

    .totleBox-inner {
      display: inline-block;
    }

    .label {
      color: #59626b;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .number {
      color: #43b886;
      font-size: 40px;
      font-weight: 300;
      @include respond-to('phone-md') {
        font-size: 26px;
      }
    }
  }

  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    width: 50%;
  }

  .left {
    padding-right: 20px;
    padding-top: 40px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    @include respond-to('phone-xl') {
      padding-right: 15px;
      width: 33.33%;
    }
    @include respond-to('phone-md') {
      padding-right: 0px;
      width: 100%;
      padding-top: 0px;
      margin-bottom: 30px;
    }

    .innerWrapper {
      display: flex;
      justify-content: start;
      width: 100%;

      .ininWrapper {
        width: auto;
        .totleBox__update {
          min-width: 310px;
        }
        display: inline-block;
        @include respond-to('phone-md') {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
        }
      }
    }

    .sale_TPM_card_box {
      width: 540px;
      max-width: 100%;
      background: #fff;
      border-radius: 16px;
      @include respond-to('desktop-md') {
        width: 480px;
      }
    }
  }

  .right {
    padding-left: 20px;
    @include respond-to('phone-xl') {
      padding-left: 15px;
      width: 66.66%;
    }
    @include respond-to('phone-md') {
      padding-left: 0px;
      width: 100%;
    }
  }

  .empGoals-card {
    padding: 20px 0px 20px 0px;

    .empGoals-table {
      width: 100%;
      border-collapse: collapse;

      tr:nth-child(even) {
        background: #fafbfd;
      }

      tr {
        td:first-child {
          padding-left: 50px;
          @include respond-to('tablet-md') {
            padding-left: 15px;
          }
        }
      }

      tr {
        td:last-child {
          padding-right: 50px;
          @include respond-to('tablet-md') {
            padding-right: 15px;
          }
        }
      }

      thead {
        td {
          color: #59626b;
          font-weight: 400;
          font-size: 14px;
          padding: 12px 15px;
        }
      }

      tbody {
        td {
          color: #222b2e;
          font-size: 14px;
          font-weight: 300;
          padding: 12px 15px;
          @include respond-to('phone-sm') {
            padding: 12px 10px;
            font-size: 12px;
          }

          &:first-child {
            color: $blue_denim;
            font-weight: 400;
          }

          .red {
            color: #ef3e6d;
          }

          .green {
            color: #43b886;
          }
        }
      }
    }
  }
}

// Employee Goals page end

// commission-page
.commission-page {
  .cs_tab_menu {
    margin-bottom: 20px;
  }

  .commissionGraph-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0px;
    @include respond-to('phone-xl') {
      padding: 15px 0px;
    }

    .left {
      padding-right: 20px;
      width: 40%;
      @include respond-to('tablet-xl') {
        width: 100%;
        padding-right: 0px;
      }
    }

    .right {
      padding-left: 20px;
      width: 60%;
      @include respond-to('tablet-xl') {
        width: 100%;
        padding-left: 0px;
        margin-top: 30px;
      }
    }
  }

  .totleBox {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    @include respond-to('phone-md') {
      margin-bottom: 20px;
    }

    .totleBox-inner {
      display: inline-block;
    }

    .label {
      color: #59626b;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
    }

    .number {
      color: #43b886;
      font-size: 40px;
      font-weight: 300;
      @include respond-to('phone-md') {
        font-size: 26px;
      }
    }
  }

  .commissionCusto-card {
    .card__header__two {
      padding: 10px 28px 0px;
    }
    .cs_tab {
      padding: 10px 28px 28px;
    }
    @include respond-to('phone-xl') {
    }
  }
  .cutomer-comission-table-wrapper {
    overflow: auto;
    max-height: 495px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 15px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:vertical {
      height: 30px;
      background-color: $color_link_water_approx;
      border-radius: 10px;
    }
  }
  .cutomer-comissionTable {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    thead tr {
      td {
        color: #222b2e;
        font-size: 12px;
        font-weight: 400;
        padding: 15px 20px;
        background: #f6f8fa;
        @include respond-to('phone-xl') {
          padding: 10px 15px;
        }

        &:nth-child(1) {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background: #fafbfd;
        }

        td {
          padding: 7px 20px;
          color: #222b2e;
          font-size: 14px;
          font-weight: 400;

          .viewBtn {
            color: $blue_denim;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        td.name {
          color: $blue_denim;
          font-size: 14px;
          font-weight: 500;
        }
      }

      tr:first-child {
        td {
          padding-top: 20px;
        }
      }
    }
  }
}

// commission-page-end

// employee-bonuses-page
.emp-bonuses-page {
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    width: 50%;
  }

  .left {
    padding-right: 20px;
    @include respond-to('tablet-md') {
      width: 33.33%;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    @include respond-to('phone-xl') {
      width: 100%;
      padding-right: 0px;
    }
  }

  .right {
    padding-left: 20px;
    @include respond-to('tablet-md') {
      width: 66.66%;
    }
    @include respond-to('phone-xl') {
      width: 100%;
      padding-left: 0px;
      .individual_bb_box {
        min-height: initial;
      }
    }
  }

  .totleBox {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    .totleBox-inner {
      display: inline-block;
    }

    .label {
      color: #59626b;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .number {
      color: $color_bright_sun_approx;
      font-size: 40px;
      font-weight: 700;
    }
  }

  .individualB-title {
    color: #222b2e;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .individualB_row {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;
    margin-bottom: 30px;
  }

  .individualB_box {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
  }
}

// employee-bonuses-page-end

.individualB_row_manager {
  .individualB_box {
    margin-bottom: 20px;
  }
}

// noData-new
.noData-new {
  .noData-inner {
    text-align: center;
  }

  img {
    max-width: 100%;
    @include respond-to('phone-md') {
      width: 50%;
    }
  }

  .noData-title {
    color: #95a0b1;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }

  .noData-btn {
    display: inline-block;
    font-family: 'Roboto';
    font-weight: 400;
    color: $white;
    border: 1px solid $color_theme_blue_color; //#f4b616;
    border-radius: 5px;
    text-decoration: none;
    padding: 8px 18px;
    font-size: 12px;
    transition: 0.5s;
    cursor: pointer;
    background-color: $color_theme_blue_color;
    box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

    &:hover {
      background-color: $color_theme_blue_color_hover;
      border: 1px solid $color_theme_blue_color_hover;
      color: $white;
      text-decoration: none;
    }
  }
}

.noData-new.pInner {
  width: 370px;
  max-width: 100%;
  margin: 0px auto;
  padding: 30px 15px;

  .noData-title {
    color: #2a90ea;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 18px;
  }
}

// noData-new-end

// add-cutomer-modal
.adding-customer-modal {
  // .inner_box {
  //   min-height: 560px;
  // }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .title {
      color: #222b2e;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0px;
      letter-spacing: 0.04em;
      @include respond-to('phone-md') {
        font-size: 18px;
      }
    }

    .bulkImport-btn {
      background-color: #206cb4;
      border-radius: 5px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      padding: 8px 15px;
      margin-bottom: 10px;
      letter-spacing: 0.04em;
      @include respond-to('phone-md') {
        padding: 8px 10px;
      }
      img {
        margin-right: 10px;
        @include respond-to('phone-md') {
          margin-right: 8px;
          width: 14px;
        }
      }
    }
  }

  .addCustomer-col-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0px 0px 20px;

    @include respond-to('phone-md') {
      padding: 10px 0px;
    }

    .cs_form_row {
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
    }
  }

  .add-customer-btn {
    color: $blue_denim;
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    margin-bottom: 15px;
    letter-spacing: 0.04em;
  }

  .add_people_rdelete {
    margin-left: 0px !important;
  }

  .adding-customer-head {
    display: flex;
    align-items: center;

    .addCustomers_icon {
      margin-right: 19px;
      width: 40px;
      @include respond-to('phone-md') {
        margin-right: 10px;
        max-width: 36px;
      }
    }
  }
}

// add-cutomer-modal-end

// customer-view-modal
.customer-view-modal {
  .inner_box {
    padding: 0px 0px;
    min-height: 536px;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    background-color: $white;
    padding: 10px 30px 10px 30px;
    border-radius: 16px 16px 0px 0px;
    @include respond-to('phone-xl') {
      padding: 15px;
      flex-wrap: wrap;
      align-items: start;
    }
    @include respond-to('phone-md') {
      .btnWrapper {
        position: absolute;
        right: 10px;
      }
    }

    .title {
      color: #276cbd;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      @include respond-to('phone-sm') {
        .btnWrapper {
          position: absolute;
          right: 10px;
        }
      }
    }

    .btnWrapper {
      margin-bottom: 0px;

      .editBtn {
        margin-right: 10px;
        @include respond-to('phone-xl') {
          margin-right: 6px;
        }

        &:hover {
          img {
            filter: unset;
          }
        }
      }

      .delateBtn {
        &:hover {
          img {
            filter: unset;
          }
        }
      }

      img {
        filter: grayscale(1) brightness(2);
        height: 20px;
        transition: 0.5s;
        @include respond-to('phone-xl') {
          height: 20px;
        }
      }
    }
  }

  .customerView-cn {
    display: flex;
    flex-wrap: wrap;

    .left {
      width: 270px;
      background: #f5f6fb;
      padding: 25px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 490px;
      position: relative;
      border-radius: 0px 0px 0px 16px;
      @include respond-to('phone-xl') {
        width: 100%;
        height: auto;
        padding: 15px;
      }

      .editBtn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 99;
        display: none;
        cursor: pointer;
      }

      &:hover .editBtn {
        display: block;
      }

      .innerWrapper {
        width: 200px;
        max-width: 100%;
        margin: 0px auto;
        @include respond-to('phone-xl') {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }

      .customerDetailsBox {
        margin-bottom: 35px;
        @include respond-to('phone-xl') {
          margin-bottom: 20px;
          width: 50%;
          &.address {
            width: 100%;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }

        .title {
          color: #222b2e;
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        .value {
          color: $blue_denim;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }

    .right {
      width: calc(100% - 270px);
      height: 490px;
      @include respond-to('phone-xl') {
        width: 100%;
        height: 370px;
      }
      .chatBox {
        height: 100%;
        position: relative;

        .noData-new {
          margin-top: 40px;
          @include respond-to('phone-xl') {
            margin-top: 20px;
          }
          .noData-title {
            font-weight: 400;
          }
        }

        .chatContant {
          height: calc(100% - 73px);
          padding: 32px 32px;
          overflow-y: scroll;
          @include respond-to('phone-xl') {
            padding: 15px;
          }
        }

        .chatMsg {
          display: flex;
          align-items: flex-end;
          flex-direction: row-reverse;

          .time {
            color: #222b2e;
            font-size: 10px;
            font-weight: 400;
            margin-right: 10px;
            position: relative;
            top: -3px;
          }

          .text {
            background-color: #276cbd;
            color: $white;
            font-size: 14px;
            line-height: 22px;
            border-radius: 5px;
            padding: 6px 15px;
            width: auto;
            max-width: calc(100% - 100px);
            @include respond-to('phone-sm') {
              font-size: 13px;
              padding: 4px 10px;
            }
          }
        }

        .chatFooter {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          padding: 16px 24px;
          border-top: 1px solid #eeeeee;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include respond-to('phone-xl') {
            padding: 10px 15px;
          }

          input {
            height: 40px;
            width: calc(100% - 80px);
            border: none;
            box-shadow: unset;
            outline: none;
          }

          .submitBtn {
            background-color: $color_bright_sun_approx;
            color: #ffffff;
            border-radius: 5px;
            padding: 11px 20px;
            font-weight: 600;
            border: 1px solid $color_bright_sun_approx;
            transition: 0.5s;

            &:hover {
              background-color: #11b7bf;
              border: 1px solid #11b7bf;
              color: $white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

// customer-view-modal-end

.hsPassword {
  position: relative;

  .hsImg {
    width: 22px;
    position: absolute;
    top: 33px;
    right: 15px;
    z-index: 3;
    cursor: pointer;
  }
}

.signup-form-wrapper {
  .hsPassword {
    .hsImg {
      top: 13px;
      right: 18px;
    }
  }
}

.notification_page {
  .noData-new {
    width: 100%;

    .noData-title {
      font-weight: 400;
    }
  }
}

.wl_confirmation_modal.cs_moda_main_wrapper.forgotPasswordModal {
  .inner_box {
    padding: 50px 40px 110px 40px;
    @include respond-to(desktop-md) {
      padding: 40px 40px 110px 40px;
    }
    @include respond-to(desktop-xs) {
      padding: 30px 30px 110px 30px;
    }
    @include respond-to(phone-xl) {
      padding: 20px 20px 110px 20px;
    }
  }

  .title {
    font-weight: 700;
    text-align: center;
    font-size: 46px;
    @include respond-to(desktop-md) {
      font-size: 40px;
      margin-bottom: 30px;
    }
    @include respond-to(desktop-xs) {
      font-size: 35px;
      margin-bottom: 30px;
    }
    @include respond-to(phone-xl) {
      font-size: 26px;
    }
  }
}

.left_sidebar .navitems_wrapper::-webkit-scrollbar {
  display: none;
}

.left_sidebar .navitems_wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cursor {
  cursor: pointer;
}

.employee_dashboard_page {
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    width: 50%;
    @include respond-to('tablet-md') {
      width: 100%;
    }
  }

  .left {
    padding-right: 20px;
    @include respond-to('tablet-md') {
      padding-right: 0px;
    }
  }

  .right {
    padding-left: 20px;
    @include respond-to('tablet-md') {
      padding-left: 0px;
    }
  }
}

.people_single_page.employee_dashboard_page .saRe_card_wrapper {
  width: 100%;
  padding-right: 0px;
}

.people_single_page.employee_dashboard_page .total_sale_wrapper {
  width: 100%;
}

.help_on_modal {
  .help_csm_chat_wrapper {
    .help_csm_chat_footer_inner {
      .emoji-mart {
        border-bottom: 1px solid #e5e5e5 !important;
        border-top: 1px solid #e5e5e5 !important;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .emoji-mart-scroll {
        height: 164px;
      }
    }
  }
}

html.bodyOverflowHidden {
  overflow-y: hidden;
}

// user-profile

.profile_initial {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
}

.headerProfile {
  font-size: 12px;
  width: 28px;
  height: 28px;
}

.settingPageProfile {
  font-size: 40px;
  width: 96px;
  height: 96px;
}

.tableProfile {
  font-size: 17px;
  width: 50px;
  height: 50px;
  @include respond-to('desktop-xs') {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

.peopleProfile {
  font-size: 23px;
  width: 82px;
  height: 82px;
  @include respond-to('phone-md') {
    width: 60px;
    height: 60px;
    font-size: 21px;
  }
}

.chatProfile {
  font-size: 16px;
  width: 48px;
  height: 48px;
  @include respond-to('phone-md') {
    font-size: 14px;
    width: 34px;
    height: 34px;
  }
}

.bonusProfile {
  font-size: 10px;
  width: 24px;
  height: 24px;
}

.editBonusProfile {
  font-size: 20px;
  width: 70px;
  height: 70px;
  @include respond-to('phone-xl') {
    font-size: 18px;
    width: 45px;
    height: 45px;
  }
}

.highlightProfile {
  font-size: 14px;
  width: 40px;
  height: 40px;
}

.commentProfile {
  font-size: 14px;
  width: 36px;
  height: 36px;
}

.like_comment_Profile {
  font-size: 12px;
  width: 32px;
  height: 32px;
}

.BgColor1 {
  background-image: linear-gradient(rgb(244, 198, 205), rgb(255, 175, 187));
  color: #ac5160;
}

.BgColor2 {
  background-image: linear-gradient(rgb(130, 219, 242), rgb(63, 177, 206));
  color: rgb(32, 125, 149);
}

.BgColor3 {
  background-image: linear-gradient(rgb(251, 157, 191), rgb(217, 99, 141));
  color: rgb(174, 33, 83);
}

.BgColor4 {
  background-image: linear-gradient(rgb(215, 170, 251), rgb(165, 89, 225));
  color: rgb(113, 48, 164);
}

.BgColor5 {
  background-image: linear-gradient(rgb(195, 202, 208), rgb(154, 159, 164));
  color: rgb(95, 98, 100);
}

.BgColor6 {
  background-image: linear-gradient(rgb(240, 164, 162), rgb(225, 115, 113));
  color: rgb(172, 67, 65);
}

.BgColor7 {
  background-image: linear-gradient(#91f0ec, #31bbb5);
  color: #238682;
}

.BgColor8 {
  background-image: linear-gradient(#b0ee93, #62c433);
  color: #356f1a;
}

.BgColor9 {
  background-image: linear-gradient(#f4e68e, #dbc643);
  color: #8e7e1a;
}

.deal_modal {
  .add_new_formNewsm {
    .doubleF_wrapper {
      width: 715px;
      max-width: 100%;
    }

    .form_row.company {
      width: calc(100% - 170px);
      margin-right: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include respond-to('phone-md') {
        width: 100%;
        margin-right: 0px;
      }
    }

    .or {
      font-size: 14px;
      font-weight: 500;
      margin-left: 12px;
      margin-right: 12px;
      display: inline-block;
      color: grey;
      position: relative;
      top: 3px;
      @include respond-to('phone-md') {
        width: calc(100% - 5px);
        display: block;
        padding: 5px 0px;
        text-align: center;
      }
    }

    .company {
      .deal_size,
      .select {
        width: calc(50% - 24px);
        @include respond-to('phone-md') {
          width: 100%;
          margin-right: 0px;
        }
      }
    }
  }
}

.bonuseNewLRDesign {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .left {
    width: 50%;
    padding-right: 40px;
    display: flex;
    justify-content: center;

    .innerWrapper {
      display: inline-block;

      .title {
        color: #b0b8c5;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      .amount {
        color: $color_bright_sun_approx;
        font-size: 40px;
        font-weight: 700;
      }
    }
  }

  .individual_bb_box {
    width: 50%;
    min-height: unset;

    .titleIn {
      width: 100%;
      color: #222b2e;
      font-size: 16px;
      font-weight: 700;
    }

    .individualB_box {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

#help_chat_modal {
  .help_csm_chat_footer {
    border-top: 1px solid #eeeeee;
  }
}

.inviteUserModal {
  .inner_box {
    padding: 108px 102px 62px 102px;
    min-height: 398px;
    position: relative;
    @include respond-to('desktop-xs') {
      padding: 80px 82px 45px 82px;
    }
    @include respond-to('phone-xl') {
      padding: 20px 15px;
    }

    .bg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 30px;
    }
  }
  .add_new_boCO_form {
    justify-content: flex-start;
  }

  .blueTree {
    position: absolute;
    bottom: auto;
    left: 30px;
    top: 270px;
    @include respond-to('phone-xl') {
      display: none;
    }
  }

  .orangeTree {
    position: absolute;
    bottom: auto;
    right: 35px;
    top: 310px;
    @include respond-to('phone-xl') {
      display: none;
    }
  }

  .inviteUserHeader {
    margin-bottom: 50px;
    @include respond-to('phone-md') {
      margin-bottom: 20px;
    }

    .title {
      color: $blue_denim;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 5px;
      @include respond-to('tablet-md') {
        text-align: left;
        font-size: 28px;
      }
      @include respond-to('phone-xl') {
        font-size: 24px;
      }
    }

    .text {
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #4e5558;
      font-weight: 700;
      @include respond-to('tablet-md') {
        text-align: left;
        line-height: 22px;
      }
    }
  }

  .cs_form_submit_row {
    position: static !important;
    margin-top: 50px;
  }

  .innerCN {
    position: relative;
    z-index: 2;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 30px 56px 45px 56px;
    @include respond-to('desktop-md') {
      padding: 30px 30px 30px 30px;
    }
    @include respond-to('phone-md') {
      padding: 15px;
      border-radius: 8px;
    }
  }
}

.people_single_Dright .sales_contest_box:nth-child(-n + 3) .chatProfile {
  width: 48px;
  height: 48px;
  font-size: 18px;
  @include respond-to('desktop-xs') {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

.people_single_Dright {
  .individualB_box {
    .ib_name_img_time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond-to('phone-md') {
        flex-wrap: wrap;
        .editTime {
          text-align: center;
          width: 100%;
        }
      }
    }

    .ib_title_img_wrapper {
      display: inline-block;
      @include respond-to('phone-md') {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    .ib_time {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .ib_price {
      display: flex;
      align-items: center;
    }

    .bounsFLS {
      font-size: 10px;
      font-weight: 500;
      color: #222b2e;
      margin-top: 6px;
    }

    .ib_range_value {
      color: #40b885;
    }
  }
}

.people_single_page {
  .people-chartM-wrapper {
    margin-bottom: 20px;
  }

  .employee_dashboard_page {
    .total_sale_wrapper {
      width: 100%;
      margin-bottom: 30px;
    }

    .sale_TPM_wrapper {
      width: 50%;
      @include respond-to('phone-md') {
        width: 100%;
      }
    }

    .saRe_card_wrapper {
      width: 50%;
      padding-right: 0px;
      @include respond-to('phone-md') {
        width: 100%;
        padding-left: 0px;
      }
    }
  }
}

.highlight_P_head {
  width: 812px;
  max-width: 100%;
  margin: 0px auto;
  margin-bottom: 20px;
}

.add-sales-team-modal {
  form > .fm_error {
    display: block;
    text-align: center;
    margin-top: 25px;
  }
}

// people-page-deal-box-fixed
.people_single_page {
  .employee_dashboard_page {
    padding-top: 80px;
  }

  .left {
    .people_single_Dleft {
      position: fixed;
      width: calc(50vw - 210px);
      z-index: 9;
      @media screen and (max-width: 1400px) {
        width: calc(50vw - 180px);
      }
      @include respond-to('desktop-xs') {
        width: calc(50% - 20px);
      }
      @include respond-to('tablet-md') {
        width: 100%;
        position: relative;
        z-index: unset;
      }
    }
  }

  .top_ii_profile_header {
    position: relative;
    width: 100%;
    padding-right: 0;
    z-index: 4;
    margin-top: -32px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 0px;
  }
}

.people_single_page {
  .people_single_Dleft {
    .tab-container {
      height: calc(100vh - 260px);
      @include respond-to('tablet-md') {
        height: 420px;
      }
      @include respond-to('phone-md') {
        height: 350px;
      }
      .cs_tab {
        height: calc(100% - 45px);
      }

      .people_WLdata_data_body {
        max-height: unset;
        height: calc(100% - 100px);
      }
    }
  }
}

.free-trial-bar-active {
  .people_single_page {
    .people_single_Dleft {
      .tab-container {
        height: calc(100vh - 310px);
        @include respond-to('tablet-md') {
          height: unset;
        }

        .cs_tab {
          height: calc(100% - 45px);
        }

        .people_WLdata_data_body {
          max-height: unset;
          height: calc(100% - 80px);
        }
      }
    }
  }
}

// people-page-deal-box-fixed-end

// Notification Process
.notificationLoading {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_theme_blue_color;
  font-size: 20px;
  font-weight: 600;
}

.notificationProcess {
  padding: 10px;
  border: 4px solid rgba(35, 118, 196, 0.2);
  border-right-color: $color_theme_blue_color;
  position: static;
  margin-right: 10px;
}

// Notification Process end

.home_chart_wrapper.manager {
  .card_design.saRe_card_design {
    height: 130px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
}

.csApexTooltip {
  background-color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  color: $white;
  font-size: 12px;
}

.wl_confirmation_modal.dealDeleteModal {
  .text {
    font-size: 20px;
    width: 250px;
    max-width: 100%;
    margin: 0px auto;
  }

  .deleteIconWrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0.1);
    margin: 0px auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    img {
      width: 100%;
    }
  }
}

.datepickerCSHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  font-weight: 600;
  font-size: 16px;

  .btnPrev,
  .btnNext {
    color: $white;
    font-weight: 600;
    font-size: 16px;
    padding: 2px 10px;
  }
}

// responsive-css
@media screen and (max-width: 1400px) {
  .main_wrapper {
    .rightC_wrapper {
      padding: 30px 30px 40px 30px;
    }
  }

  .people_card_box {
    .inner_box {
      padding: 22px 15px 10px 15px;
    }

    .sale_TPM_card_box {
      height: 74px;
    }
  }

  .people_db_box_wrapper {
    &:nth-child(2n + 1) {
      padding-right: 8px;
    }

    &:nth-child(2n) {
      padding-left: 8px;
    }
  }

  .h_details_box {
    .value {
      .m_value {
        font-size: 18px;
      }
    }
  }

  .profile_p_details {
    &::before {
      width: calc(100% + 30px);
    }
  }

  .people_single_page {
    .saRe_card_box {
      height: 60px;
    }
  }

  .people_single_DATA {
    .people_single_Dright {
      padding-left: 15px;
    }

    .people_single_Dleft {
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .site_header {
    .right_wrapper {
      padding-right: 28px;
      gap: 10px 0px;

      .mobile_toggle_btn {
        display: inline-block;
      }
    }
  }

  .site_header {
    left: 0;
  }

  .main_wrapper {
    .left_sidebar {
      left: -270px;
      transition: 0.5s;
      z-index: 999;
    }

    .left_sidebar.open {
      left: 0px;
      z-index: 999;
      box-shadow: 3px -3px 12px 10px rgba(0, 0, 0, 0.1);
    }

    .rightC_wrapper {
      width: 100%;
      left: 0px;
      transition: 0.5s;
      padding: 30px 15px 30px 15px;
    }

    .rightC_wrapper.mobile_left_sidebar_active {
      &:before {
        content: '';
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: $black_6;
        z-index: 3;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .login_form_Wbox {
    padding-left: 0px;
    padding-right: 0px;
  }

  .login_form_Wbox {
    .img_left_wrapper,
    .right_form_wrapper {
      width: calc(50% - 20px);
    }
  }

  .sales_contestL_profile {
    width: 240px;
  }

  .sales_contest_range_slider {
    width: calc(100% - 240px);
    padding: 15px 12px;
  }

  .home_chart_wrapper {
    .saRe_card_wrapper {
      width: 50%;
    }

    .sale_TPM_wrapper {
      width: 50%;
      padding-right: 0px;
    }

    .total_sale_wrapper {
      width: 100%;
    }
  }

  .people_card_row {
    .people_card_box {
      width: 50%;
    }
  }

  .add_people_repeat_row {
    .cs_form_six_row {
      .cs_form_row {
        width: 50%;
      }
    }
  }

  .people_single_page {
    .home_chart_wrapper {
      .saRe_card_wrapper {
        width: 100%;
        padding-right: 0px;
      }

      .sale_TPM_wrapper {
        width: 100%;
      }
    }
  }

  .people_single_DATA {
    .people_single_Dleft {
      width: 100%;
      padding-right: 0px;
    }

    .people_single_Dright {
      width: 100%;
      padding-left: 0px;
    }

    padding-top: 10px;
  }

  .individualB_box {
    width: 100%;
  }

  .cs_chatboard_wrapper {
    .cs_chatboard_left {
      width: 300px;

      .cs_tab_menu {
        ul {
          padding: 0px 15px;
        }
      }
    }

    .cs_chatboard_right {
      width: calc(100% - 300px);
    }

    .cs_chatboard_right_no_chat {
      width: calc(100% - 300px);
    }
  }

  .cs_chatboard_searchHeader {
    padding: 24px 15px 10px 15px;
  }

  .cs_chatboard_Pitem {
    padding: 16px 15px 16px 15px;
    @include respond-to(phone-md) {
      padding: 10px 15px 10px 15px;
    }
    .cs_chatboard_Pitem_details {
      padding-left: 10px;
      padding-right: 2px;
    }
  }

  .cs_chatboard_Pitems_wrapper {
    padding-top: 5px;
  }

  .site_header {
    .userD_wrapper {
      margin-left: 18px;

      .user_name {
        max-width: 150px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .help_csm_chat_wrapper {
    .help_csm_chat_footer_inner {
      .emoji-mart-scroll {
        height: 160px;
      }

      .emoji-mart-search {
        input {
          padding: 10px 45px 10px 15px;
        }

        .emoji-mart-search-icon {
          top: 23px;
        }
      }
    }
  }

  .login_form_Wbox {
    flex-wrap: wrap;
    padding-top: 0px;
    padding-bottom: 0px;

    .img_left_wrapper {
      .login_formI_img {
        text-align: center;

        img {
          width: 300px;
          max-width: 100%;
        }
      }
    }

    .login_form_card {
      width: 450px;
      max-width: 100%;
      margin: 0px auto;
      margin-bottom: 40px;
      padding: 30px 15px;
    }
  }

  .login_page_wrapper {
    height: auto;
    padding-top: 0px;
  }

  .login_form_Wbox {
    .img_left_wrapper,
    .right_form_wrapper {
      width: 100%;
    }

    .right_form_wrapper {
      order: -1;
    }
  }

  .sales_contestL_profile {
    width: 200px;
  }

  .sales_contest_range_slider {
    width: calc(100% - 200px);
  }

  .site_header {
    .search_box {
      display: none;
    }
  }

  .pipeline_details_card {
    .cs_row {
      .cs_column {
        max-width: 50%;
        width: 50%;
        text-align: left;
        .inner_column {
          background-color: #f8f9fb;
          padding: 15px;
          border-radius: 8px;
        }
      }
    }

    .cs_column {
      .detail_title {
        margin-bottom: 12px;
      }
    }
  }

  .toolbar_pcs {
    .btn_link {
      margin-right: 15px;
    }
  }

  .highlights_post_box {
    padding: 18px 20px;
  }

  .add_boCO_form_wrapper {
    .cs_form_double_row {
      .cs_form_row {
        width: 100%;
      }
    }

    .cs_form_double_row.input_default_currency.tw_line {
      &::before {
        top: 34px;
      }
    }
  }

  .ff_faq_box {
    width: 100%;
  }

  .add_new_boCO_form {
    margin-left: -15px;
    margin-right: -15px;

    .add_new_boCOf_box {
      padding: 0px 15px;
    }
  }

  .employee_deal_modal {
    .employee_deal_resp_hr {
      overflow-x: auto;
    }

    .employee_deal_DATArow_head {
      min-width: calc(690px - 65px);
    }

    .employee_deal_DATA_overflow {
      min-width: 690px;

      .confirm_btn {
        margin-left: 10px;
      }
    }
  }

  .cs_chatboard_wrapper {
    &.chatOpenForMobile {
      .cs_chatboard_left {
        display: none;
      }
      .cs_chatboard_right {
        display: block;
      }
    }
    .chat_searchBar {
      transform: translateX(100%);
      visibility: hidden;
    }
    &.searchBar_open {
      .cs_chatboard_right {
        width: 100%;
      }
      .chat_searchBar {
        transform: translateX(0%);
        visibility: visible;
      }
    }
    .cs_chatboard_left {
      width: 100%;
    }

    .cs_chatboard_right_no_chat {
      width: 100%;
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
    }

    .cs_chatboard_right {
      display: none;
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0px;
      height: 100%;
      width: 100%;
      &.user-chat-show {
        right: 0;
        z-index: 2;
        width: 100%;
        transform: translateX(0%);
        visibility: visible;
      }

      .help_csm_chat_header {
        padding-left: 38px;

        .back_btn {
          display: block;
          left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .help_csm_chat_wrapper {
    .help_csm_chat_footer_inner {
      .emoji-mart {
        bottom: 57px;
      }
    }
  }

  .cs_form_row {
    margin-bottom: 22px;
  }

  .cs_form_double_row {
    .cs_form_row {
      width: 100%;
    }
  }

  .team_individual_sel_modal {
    .team_individual_wrapper {
      padding: 60px 25px 60px 25px;
    }

    .ti_btns_wrapper {
      width: 300px;
      max-width: 100%;
      margin: 0px auto;

      .ti_btns_box {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .sales_contestL_profile {
    width: 100%;
  }

  .sales_contest_range_slider {
    width: 100%;
    margin-top: 20px;
    padding: 15px 12px;
  }

  .card_design {
    margin-bottom: 25px;
  }

  .site_header {
    padding: 15px;

    .userD_wrapper {
      padding-right: 15px;
      margin-left: 17px;
      min-width: unset;

      .user_name {
        margin-left: 7px;
        white-space: pre;
        max-width: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .right_wrapper {
      .notofication_icon {
        top: 4px;

        img {
          width: 18px;
        }

        .notofication_count {
          top: -9px;
        }
      }
    }
  }

  .home_chart_wrapper {
    .saRe_card_wrapper {
      width: 100%;
      padding-right: 0px;
    }

    .sale_TPM_wrapper {
      width: 100%;
      padding-right: 0px;
    }
  }

  .card_design.total_sale_card_design {
    .h_details_box {
      .title {
        font-size: 18px;
      }

      .value {
        .m_value {
          font-size: 20px;
        }
      }
    }
  }

  .pipeline_details_card {
    .cs_row {
      .cs_column {
        max-width: 100%;
        width: 100%;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .cs_column {
      .detail_title {
        margin-bottom: 10px;
      }
    }
  }

  .people_card_row {
    .people_card_box {
      width: 100%;
    }
  }

  .add_people_repeat_row {
    .cs_form_six_row {
      .cs_form_row {
        width: 100%;
      }
    }

    .add_people_Rabtn {
      position: relative;
      top: 0px;
      right: 0px;
      margin-top: 6px;
    }
  }

  .add_peopleF_box {
    .add_people_repeat_row {
      padding-right: 0px;
    }

    padding: 20px;
  }

  .people_single_page {
    .saRe_card_wrapper {
      .saRe_card_design {
        width: 100%;

        &:nth-child(2n + 1) {
          margin-right: 0px;
        }

        &:nth-child(2n) {
          margin-left: 0px;
        }
      }
    }

    .card_design.sale_TPM_card_design {
      .h_details_box {
        text-align: left;
        padding: 18px 10px 0px 18px;

        .title {
          font-size: 18px;
        }

        .value {
          .m_value {
            font-size: 20px;
          }
        }
      }
    }

    .sales_contestL_profile {
      width: 100%;
    }

    .sales_contest_range_slider {
      width: 100%;
      margin-top: 15px;
    }
  }

  .top_ii_profile_header {
    margin-bottom: 22px;
  }

  .people_WLdata_box {
    .cs_tab_menu {
      margin-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .highlights_post_box {
    padding: 15px 15px;

    .highlights_post_footer {
      .highlights_post_footer_item {
        margin-right: 30px;
      }
    }

    .highlights_post_contant {
      .highlights_post_text {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      img {
        border-radius: 8px;
      }
    }

    .highlights_post_header {
      margin-bottom: 15px;
    }
  }

  .add_boCO_form_wrapper {
    padding: 20px;

    .cs_form_double_row.input_default_currency.tw_line {
      &::before {
        top: 50px;
      }
    }

    .custom_checkbox_double_row {
      .custom_checkbox_row {
        width: 100%;
      }
    }

    .input_default_currency {
      .cs_form_label {
        width: 400px;
        max-width: 100%;
      }
    }

    .cs_form_random_3row {
      .cs_form_row {
        width: 100%;
        max-width: 100%;
        padding: 0px 6px;
      }
    }
  }

  // .setting_card {
  //   padding: 20px;
  // }

  .profile_pic_setting {
    .pp_setting_btn_wrapper {
      padding-left: 15px;
      width: calc(100% - 72px);
    }

    .pp_setting_img {
      width: 72px;
      height: 72px;
      .settingPageProfile {
        width: 72px;
        height: 72px;
        font-size: 30px;
      }
    }
  }

  .security_privacy_form {
    .cs_form_submit_row {
      input[type='submit'] {
        margin-top: 20px;
      }
    }
  }

  .ff_faq_box {
    .ff_faq_contant {
      .ff_faq_contant_inner {
        padding: 20px 12px 20px 15px;
      }
    }

    .ff_faq_header {
      padding: 16px 20px 16px 50px;

      &::before {
        top: 45%;
        width: 7px;
        height: 7px;
      }
    }
  }

  .add_new_boCOf_box {
    width: 100%;
    margin-bottom: 20px;
  }

  .employee_deal_modal {
    .cs_tab {
      padding: 25px 15px 30px 15px;
    }

    .cs_tab_menu {
      padding: 20px 15px 10px 15px;
    }

    .employee_deal_modal_close_btn {
      top: 27px;
      right: 15px;
    }

    .employee_deal_DATA_overflow {
      max-height: calc(100vh - 550px);
    }

    .add_ed_new_row {
      .add_ed_newF_wrapper {
        .add_ed_newFfield_box {
          width: calc(100% - 28px);
        }
      }
    }
  }

  .people_WLdata_box {
    .employee_deal_modal {
      .cs_tab_menu {
        ul {
          width: calc(100% - 20px);
          padding-right: 10px;
        }
      }
    }
  }

  .add_ed_new_row {
    .add_ed_newF_wrapper {
      position: relative;

      .add_ed_newFfield_box {
        input {
          border-radius: 10px;
          padding: 13px 15px;
        }
      }

      .add_ed_newFfield_cancel_btn {
        font-size: 0px;
        width: 16px;
        height: 16px;
        position: relative;
        border-radius: 50%;
        border: 1px solid $color_outer_space_approx;
        position: absolute;
        top: 33px;
        right: 0px;
        margin-top: 0px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: calc(100% - 5px);
          height: 1px;
          background-color: $color_outer_space_approx;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: calc(100% - 5px);
          height: 1px;
          background-color: $color_outer_space_approx;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  .cs_chatboard_wrapper {
    .cs_chatboard_right {
      padding-top: 68px;
      padding-bottom: 55px;

      .help_csm_chat_header {
        .back_btn {
          left: 15px;
        }
      }
    }

    .help_csm_chat_wrapper {
      .help_csm_chat_header {
        padding: 12px 15px 12px 30px;
        top: 5px;

        .cs_chatboard_chat_dots_menu {
          right: 15px;
          top: calc(50% - 5px);
        }

        .help_csm_chatH_contant {
          width: calc(100% - 80px);
          margin-top: -5px;
          padding-left: 5px;
        }
      }

      .help_csm_chat_footer {
        padding: 10px 15px;

        .help_csm_chat_send_btn {
          font-size: 12px;
          margin-left: 3px;
        }

        .help_csm_chat_textarea {
          width: calc(100% - 132px);
          padding: 0px 0px 0px 10px;
        }
      }

      .help_csm_chatMSG_box {
        padding: 20px 15px;
      }
    }
  }

  .cs_chatboard_uploadDropdown_wrapper {
    .cs_chatboard_uploadDropdown {
      bottom: 52px;
      right: -73px;
    }
  }

  .new_chat_modal {
    .cs_chatboard_searchHeader {
      .cs_chatboard_search_input_wrapper {
        width: 100%;
        margin-bottom: 10px;
      }

      .cs_chatboard_searchHeader_flex {
        .creat_group_btn {
          font-size: 13px;
          padding: 7px 18px;
        }
      }
    }
  }
}

.noData_found.noUserInvite {
  width: 400px;
  max-width: 100%;
  margin: 0px auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #ededed;

  img {
    width: 80px;
  }

  .no_data_found_text {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
  }
}

@media screen and (max-width: 374px) {
  .people_db_row {
    width: 258px;
  }

  .profile_pic_setting {
    .pp_setting_btn_wrapper {
      width: 100%;
      padding-left: 0px;
      margin-top: 15px;
    }
  }

  .site_header {
    .userD_wrapper {
      margin-left: 10px;

      .user_name {
        max-width: 80px;
      }
    }
  }
}

// responsive-css-end

iframe {
  position: static !important;
}
div#root ~ iframe {
  position: fixed !important;
  width: 0 !important;
  height: 0 !important;
  pointer-events: none;
}

// Notification Bar

.notification-btn {
  padding: 5px 10px;
  margin-left: 5px;
  font-weight: 600;
  background-color: #36ce8d;
  border-radius: 4px;
  color: $white;
  transition: 0.5s;

  &:hover {
    background-color: #27b176;
  }
}

.confirmation-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-bottom: 10px;
  color: #4e4646;
}

.DayMonthDatePicker {
  .react-datepicker__day-names {
    display: none !important;
  }
}

.sale-report-perform-left .sale-report-box {
  max-height: 100px;
  overflow: hidden;
  position: relative;
}

/* Report CSS*/

.report-folder {
  padding: 24px 26px;
  @include respond-to(phone-md) {
    padding: 20px 15px;
  }

  &__head {
    background: #f6f8fa;
    border-radius: 5px;
    height: 45px;
    padding: 13px 19px;
    box-sizing: border-box;
    color: $blue_denim;
    margin-bottom: 40px;
  }
}

.report-folder-item {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 20px;
  @media screen and (max-width: 575px) {
    padding: 0px;
  }

  .report-folder-block {
    width: 67px;
    margin-bottom: 43px;
    text-align: center;
    margin-right: 64px;
    @media screen and (max-width: 991px) {
      width: calc(25% - 25px);
      margin-right: 25px;
    }
    @media screen and (max-width: 575px) {
      width: calc(33.33% - 25px);
    }
    @include respond-to('phone-sm') {
      width: calc(50% - 25px);
    }

    img {
      height: 60px;
    }

    cursor: pointer;

    .report-folder-name {
      color: #206cb4;
      font-size: 12px;
      line-height: 19.2px;
      letter-spacing: 0.04em;
    }
  }
}

.years-lbl-main {
  padding: 0px 20px;
  cursor: pointer;

  .years-lbl-item {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 12px;
    margin-right: 20px;
    color: $blue_denim;
    font-weight: 500;
    padding: 9px 18px;
    margin-bottom: 5px;
    display: inline-block;
    letter-spacing: 0.04em;
  }
}

.report-folder-modal {
  .inner_box {
    width: 1089px;
    padding: 34px 32px;

    .report-folder {
      padding: 0;
    }
  }

  .report-folder-item {
    .report-folder-block {
      margin-right: 54px;
    }
  }
}

.DownloadReportBtn {
  background-color: $color_bright_sun_approx;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 15px;
  transition: 0.5s;

  img {
    margin-right: 10px;
  }

  &:hover {
    background-color: #28abb1;
  }
}

.reportS-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.BackReportBtn {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  height: 35px;
  line-height: 35px;
  justify-content: flex-start;
  color: #222b2e;

  img {
    margin-right: 10px;
    position: relative;
    top: -1px;
  }
}

.sale-report {
  padding: 50px 23px 30px 23px;
  border-radius: 0;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    padding: 25px 15px 20px 15px;
  }
}

.sale-report-title {
  font-size: 30px;
  color: $blue_denim;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 30px;
  margin-bottom: 34px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.sale-report-perform {
  display: flex;
  flex-wrap: wrap;

  .sale-report-perform-left {
    width: 33.33%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .sale-report-perform-right {
    width: 66.66%;
    padding-left: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 0px;
    }
  }
}

.sale-report-box {
  .h_details_box {
    padding: 9px 12px;

    .title {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 12px;
      color: #222b2e;
      margin-bottom: 6px;
      line-height: 19.2px;
    }
  }

  .value {
    height: 34px;
    line-height: 34px;
    margin-bottom: 5px;
  }

  .valueUpDown {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.04em;
    height: 19px;
    line-height: 19.2px;
  }

  .valueUpDown-red {
    color: #ef3e6d;
  }

  .valueUpDown-green {
    color: #43b886;
  }
}

.card_design.sale-report-box {
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
}

.report-sale-slider {
  max-width: 1330px;
  width: 100%;

  .hide {
    display: none;
  }
}

.sale-report-box-quarter {
  display: flex;
  justify-content: space-between;
}

.top-performing-products-chart {
  min-height: 250px;
  max-height: 250px;
  .apexcharts-legend-series {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    margin: 0 !important;
    width: 100%;
    margin-bottom: 10px !important;
    background: #f5f6fa;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #dedede;

    .apexcharts-legend-marker {
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
      height: 20px !important;
      width: 20px !important;
      margin-right: 10px;
    }
  }

  .apexcharts-legend {
    width: 240px;
    display: block;
    // -moz-column-count: 2;
    // column-count: 2;
    // -moz-column-gap: 2.5rem;
    // column-gap: 2.5rem;
  }
  .apexcharts-legend-series .apexcharts-legend-text {
    width: 100%;
  }
  .apexcharts-legend-name {
    font-weight: 400;
    margin-bottom: 5px;
    text-transform: capitalize;
    color: #2775bd;
    font-family: 'Roboto';
  }
  .apexcharts-per-name {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Roboto';
  }
  .apexcharts-per {
    font-weight: bold;
  }
}

.top-customers-chart {
  height: 210px;

  .apexcharts-tooltip {
    overflow: visible;
    box-shadow: unset;
  }

  .apexcharts-series {
    .apexcharts-bar-area {
      filter: unset !important;
    }
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    border: none;
    background-color: transparent;
  }

  .stp_chart_tooltip {
    background-color: $black;
    position: relative;
    border-radius: 6px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      bottom: -4px;
      z-index: 1;
      background-color: $black;
      border-radius: 0px;
    }

    .value {
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 10px;
      color: $white;
      display: block;
      text-align: center;
      padding: 5px 9px;
      position: relative;
      z-index: 2;
      height: auto;
      line-height: inherit;
      margin-bottom: 0;
    }
  }
}

.quarter_sales {
  padding: 9px 12px;
}

.dots-active {
  text-align: center;

  .dot-slide {
    width: 13px;
    height: 13px;
    background: #d9d9d9;
    display: inline-block;
    border-radius: 100%;
    margin: 0px 6px;
    cursor: pointer;

    &.active {
      background: $blue_denim;
    }
  }
}

.sip_table_wrapper {
  background: #ffffff;
  box-shadow: 0px 2.84368px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px 0px 5px 0px;

  .ps__rail-x,
  .ps__rail-y {
    display: none;
  }

  .pagination {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    tbody {
      tr {
        td {
          border-bottom: 2px solid #edefef;
        }
      }
    }
  }

  tbody {
    td {
      font-family: 'Roboto';
      font-weight: 700;
      color: $color_outer_space_approx;
      font-size: 12px;
      text-align: center;
    }

    tr:last-child td {
      border-bottom: none;
    }

    td:nth-child(1) {
      width: 80px;
      padding: 12px 0px;
    }

    td.profile_name {
      padding: 12px 0px;
      text-align: left;
      font-family: 'Roboto';
      font-weight: 700;
      color: $blue_denim;
      font-size: 12px;
      width: 135px;
    }
  }

  th {
    font-family: 'Roboto';
    font-weight: 400;
    color: $blue_denim;
    font-size: 12px;
    text-align: center;
    font-weight: normal;

    p {
      span {
        font-size: 10px;
      }
    }
  }

  table {
    th,
    td {
      padding: 3px 20px;
    }

    th {
      padding: 3px 32px;
    }
  }

  .profile_img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .profile_PN {
    display: flex;
    align-items: center;

    .profile_name {
      font-family: 'Roboto';
      font-weight: 700;
      color: $blue_denim;
      font-size: 14px;
      margin: 0px;
      margin-left: 14px;
    }
  }
}

.sale_data_setting {
  .bulkImportWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: rgba(32, 108, 180, 0.1);
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 24px;

    p {
      margin-bottom: 0px;
      width: calc(100% - 120px);
      padding-right: 20px;
      font-size: 15px;
      color: $green-blue;
    }

    &.bulkImportWrapper-sale {
      max-width: 597px;
      width: 100%;
      border-radius: 16px;
      padding: 16px;
      margin-bottom: 24px;
      justify-content: space-between;

      p {
        font-size: 14px;
      }

      .bulk-import-btn {
        padding: 8px 14px;

        img {
          margin-right: 8px;
        }
      }
    }
  }

  .bulk-import-btn {
    color: $white;
    font-weight: 500;
    font-size: 12px;
    background-color: $green-blue;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 7px 14px;

    img {
      display: inline-block;
      margin-right: 7px;
    }
  }

  .upload-file-img {
    border-radius: 10px;
    width: 510px;
    margin: 0px auto;
    margin-top: 20px;
    text-align: center;

    .inner-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 30px 10px 20px;
      border: 1px dashed #4294ff;
      background-color: rgba(66, 148, 255, 0.1);
      border-radius: 10px;
      position: relative;

      .close-btn {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        padding: 0px;
        background-color: $white;
        box-shadow: 0px 0px 10px #e3e3e3;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 7px;
          height: 1px;
          background-color: #000;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      img {
        display: inline-block;
        width: 30px;
      }

      .text {
        font-size: 16px;
        margin-bottom: 0px;
        width: calc(100% - 30px);
        padding-left: 10px;
      }
    }
  }

  .sale_data-info-txt {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: $blue_denim;
    margin-bottom: 20px;
    padding-bottom: 15px;
    line-height: 21.6px;
  }

  .cs_form_submit_row {
    margin-top: 40px;
  }
}

.roi-calculations-tab {
  max-width: 930px;
  width: 100%;

  p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: $blue_denim;
    margin-bottom: 30px;
    line-height: 21.6px;
  }

  .roi-profit {
    .roi-profit-lbl {
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.04em;
      color: $blue_denim;
      margin-bottom: 12px;
      line-height: 21.6px;
      display: block;
    }

    .roi-profit-btn {
      background: $white;
      border: 1px solid $bright_gray;
      border-radius: 16px;
      padding: 12px 23px;
      font-weight: 400;
      font-size: 16px;
      color: $blue_denim;
      box-sizing: border-box;
      min-width: 140px;
      text-align: left;
      height: 52px;
      max-width: 140px;
    }
  }

  .cs_form_submit_row {
    margin-top: 40px;
  }
}

.creatAC-wrapper-upload {
  margin-top: 20px;

  .create-account-btn-wrap {
    position: relative;
    display: inline-block;

    .process {
      z-index: 9;
      left: 10px;
    }

    .create-account-btn {
      color: $white;
      font-size: 14px;
      padding: 11px 34px 11px 34px;
      background: $submit_btn_green;
      border-radius: 4px;
      font-weight: 700;
    }
  }
}

iframe#printWindow {
  position: absolute !important;
}

/*csp_table_wrapper-end*/

/* Report CSS End*/

/* Model DashBord*/
.cs_moda_main_wrapper {
  &.welcome-modal {
    .inner_box {
      width: 534px;
      padding: 30px;
      min-height: 416px;
      top: 90px;

      .info-wrap {
        max-width: 364px;
        margin: 0 auto;
        width: 100%;
      }

      .modal-close-btn {
        position: absolute;
        top: 2px;
        right: -20px;

        &:after,
        &:before {
          display: none;
        }
      }
    }
  }

  &.trsn-history-model {
    .inner_box {
      width: 277px;
      padding: 30px;
      min-height: 394px;
      top: 487px;
      right: 70px;
      left: auto;
      position: absolute;

      .info-wrap {
        max-width: 364px;
        margin: 0 auto;
        width: 100%;
      }

      .modal-close-btn {
        position: absolute;
        top: 2px;
        right: -20px;

        &:after,
        &:before {
          display: none;
        }
      }
    }
  }

  &.edit-roi-cal-model {
    .inner_box {
      width: 277px;
      padding: 30px;
      min-height: 394px;
      top: 132px;
      right: 302px;
      left: auto;
      bottom: auto;

      .info-wrap {
        max-width: 364px;
        margin: 0 auto;
        width: 100%;
      }

      .modal-close-btn {
        position: absolute;
        top: 2px;
        right: -20px;

        &:after,
        &:before {
          display: none;
        }
      }
    }
  }

  &.invite-SalesTeam-model {
    .inner_box {
      width: 277px;
      padding: 30px;
      min-height: 394px;
      position: relative;
      top: 38px;
      right: 410px;
      left: auto;
      bottom: auto;

      .info-wrap {
        max-width: 364px;
        margin: 0 auto;
        width: 100%;

        p {
          max-width: 181px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .modal-close-btn {
        position: absolute;
        top: 2px;
        right: -20px;

        &:after,
        &:before {
          display: none;
        }
      }
    }
  }

  .info-wrap {
    text-align: center;

    img {
      margin-bottom: 17px;
    }

    .infoBtn {
      background-color: $submit_btn_green;
      color: $white;
      border-radius: 10px;
      padding: 13px 30px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.04em;
      line-height: 22.4px;
      min-width: 124px;

      &:hover {
        background-color: $submit_btn_green_hover;
      }
    }

    .info-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 25.6px;
      letter-spacing: 0.04em;
      margin-bottom: 17px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      color: #5c6363;
      letter-spacing: 0.04em;
      line-height: 19.2px;
      margin-bottom: 27px;
    }
  }
}

.invite-model-body {
  .site_header {
    z-index: unset;
    position: absolute;

    .right_wrapper {
      .inviteTeamBtn {
        z-index: 12 !important;
        color: $white;

        img {
          -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

/* Model DashBord end*/
.sale_TPM_card_design,
.emsp_table_data {
  position: relative;

  .SettingIcon_wrap {
    position: absolute;
    right: 88px;
    top: 14px;
    cursor: pointer;
  }
}

/* No Data Found people */
.people_card_row {
  .noData_found-pepole {
    img {
      width: 298px;
    }

    .no_data_found_text {
      margin-bottom: 0px;
      margin-top: 28px;
      font-size: 22px;
      color: #95a0b1;
      font-weight: 400;
      line-height: 35.2px;
      letter-spacing: 0.04em;
    }
  }
}

/* No Data Found End */

/* Bounas changes*/

.individualB_box_green {
  .ib_price_range {
    @include respond-to('phone-md') {
      flex-wrap: wrap;
    }

    .ib_price {
      // background: #e9f9ee;
      min-width: 150px;
      width: 100%;
      padding: 0px 0px 15px 0px;
      text-align: left;
      color: #48b98d;
      position: relative;
      top: -4px;
      display: inline-block;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 22px;
      font-weight: 400;

      @include respond-to('phone-md') {
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
      }
    }

    .ib_rangebounsFLS-wrap {
      width: 100%;
      padding-left: 0px;
      box-sizing: border-box;
      position: relative;
      @include respond-to('phone-md') {
        padding-left: 0px;
      }
    }
  }

  .bounsFLSbonusAmount_warp {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ib_time {
    font-size: 13px;
    position: relative;
  }
}

.people_single_Dright {
  .individualB_box_green {
    .ib_name_img_time {
      margin-bottom: 20px;
    }

    .bounsFLS {
      font-size: 14px;
      color: #babac8;
    }
  }
}

.bonusAmount-wrap {
  color: $green-blue;
  font-size: 14px;
  font-weight: 500;

  .bonusAmount_green {
    color: #48b98d;
    padding-right: 4px;
  }

  .bonusAmount_light {
    padding-left: 4px;
  }
}

.individual_bb_box {
  &.individual_bb_box-green {
    background: transparent;
    padding: 0;
    box-shadow: none;

    .individualB_row {
      .individualB_box {
        .bounsFLS {
          font-size: 14px;
          font-weight: 500;
          color: #c7c3c3;
          margin-top: 3px;
          p {
            font-weight: 500;
            color: #2775bd;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.new_design-green {
  .contest_card-info {
    .individual_bonuses_t_header {
      flex-wrap: nowrap;
      @include respond-to('phone-md') {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }
  }
  .sales_contest_row {
    padding-top: 24px;
  }
}

.sales_contest_wrapper.new_design.new_design-green {
  .contest_card_slide {
    background-color: transparent;
    padding: 0;
    box-shadow: unset;
  }

  .main_contest_card_box {
    .individual_bonuses_t_header {
      .sub_title {
        font-size: 12px;
        color: #b0b8c5;
        white-space: nowrap;
      }

      padding-right: 0;
      margin-bottom: 5px;
      align-items: flex-start;
      justify-content: space-between;

      .title_sec {
        font-size: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.contest_card-info {
  background: $white;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
  padding: 16px;
  border-radius: 16px;
}

.sales_contest_wrapper.new_design.contestPage_cc.new_design-green {
  .sales_contest_rank {
    color: $blue_denim;
  }

  .sales_contest_box {
    &:nth-child(1),
    &:nth-child(2) {
      .sales_contest_user_name {
        color: #222b2e;
      }
    }

    .cc_current_bookings,
    .cc_closed_Deals {
      .amount {
        color: #48b98d;
        font-size: 22px;
      }

      .label {
        color: #b0b8c5;
        font-size: 14px;
        @include respond-to('tablet-md') {
          font-size: 14px;
        }
      }
    }
  }
}

.sales_contest_box {
  padding: 20px;

  @include respond-to('phone-md') {
    padding: 15px;
  }
}

.sales_contest_wrapper.new_design.new_design-green {
  .sales_contest_box {
    background: $white;
    box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

    .cc_current_bookings,
    .cc_closed_Deals {
      .amount {
        color: #48b98d;
      }

      .label {
        color: #b0b8c5;
      }
    }
  }

  .sales_contest_user_name {
    color: #222b2e;
  }

  .sales_contest_rank {
    color: #b0b8c5;
  }
}

.sales_contest_note_text {
  margin-bottom: 0;
}

.people_single_page {
  .new_design-green .sales_contest_row {
    padding-top: 15px;
  }
}

.trsn-history-model.show,
.edit-roi-cal-model.show,
.invite-SalesTeam-model.show {
  position: absolute;
}

.invite-SalesTeam-model.show {
  justify-content: flex-end;
}

body.modalinfo-active {
  position: fixed;
  width: 100%;
}

.cs_moda_main_wrapper.trsn-history-model .inner_box .modal-close-btn {
  position: absolute;
  top: 166px;
  right: -20px;
}

.cs_moda_main_wrapper.trsn-history-model .inner_box {
  top: 319px;
  right: 70px;
}

.cs_moda_main_wrapper.edit-roi-cal-model .inner_box {
  top: 163px;
  right: auto;
  left: 513px;
  bottom: auto;
  position: absolute;
}

@media screen and (max-width: 1400px) {
  .cs_moda_main_wrapper.edit-roi-cal-model .inner_box {
    left: 475px;
  }
}

@media screen and (max-width: 1199px) {
  .cs_moda_main_wrapper.trsn-history-model .inner_box {
    top: 321px;
    right: 54px;
  }

  .cs_moda_main_wrapper.invite-SalesTeam-model .inner_box {
    right: 390px;
  }

  .cs_moda_main_wrapper.edit-roi-cal-model .inner_box {
    left: 201px;
  }
}

/*Subscription-Modal */

.download-subscription-modal {
  .inner_box {
    width: 533px;
    max-width: 100%;
    padding: 27px 63px;

    .cs_form_submit_row {
      position: relative;
      bottom: 0;
    }
  }

  .text-Subtitle {
    color: #222b2e;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 25.6px;
    letter-spacing: 0.04em;
    margin-bottom: 22px;
  }

  .UpgradeContinueImg {
    margin-bottom: 24px;
    text-align: center;
  }

  .cs_form_submit_row {
    .upgrade-btn {
      position: relative;
      background-color: $submit_btn_green;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      padding: 13px 13.5px;
      transition: all 0.3s ease;
      margin-right: 0;
      border-radius: 10px;
      letter-spacing: 0.04em;
      line-height: 22.4px;
      z-index: 1;

      &:hover {
        background-color: $submit_btn_green_hover;
      }
    }
  }
}

/* Modal Popups*/
.modal-head-wrap {
  display: flex;
  padding-right: 80px;

  .modal-head-titles {
    .modal-title {
      line-height: 32px;
      margin-bottom: 0 !important;
    }

    .modal-subtitle {
      font-size: 12px;
      color: #222b2e;
      opacity: 0.8;
      font-weight: 400;
      letter-spacing: 0.04em;
      line-height: 19.2px;
      margin-bottom: 0;
    }
  }

  .modal-head-img {
    margin-right: 27px;
    @media screen and (max-width: 575px) {
      margin-right: 15px;
      max-width: 30px;
    }
  }
  .modal-head-titles {
    @media screen and (max-width: 575px) {
      width: calc(100% - 45px);
    }
  }
}

.add_bonus {
  .modal-header.modal-header-space {
    align-items: flex-end;

    .select-all-btn {
      margin-bottom: 0px;
    }
  }
}

/* ChatRomm*/
.new_chat_modal {
  .cancel-btn {
    background-color: transparent;
    color: $color_gray_chateau_approx;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    padding: 13px 44px;
    transition: 0.5s;
    border: 1px solid #dee0e0;
    margin-left: 12px;
    text-decoration: none;
    letter-spacing: 0.04em;
    line-height: 20px;
    @include respond-to(phone-md) {
      padding: 11px 34px;
    }
    &:hover {
      background-color: #dee0e0;
      border: 1px solid #dee0e0;
      color: #000000;
      text-decoration: none;
    }
  }
}

.chat_room_model_title {
  width: calc(100% - 60px);
  padding-left: 15px;
  padding-top: 20px;

  h4 {
    color: $blue_denim;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 25px;
    border-bottom: 2px solid #f5f6fa;
    padding-bottom: 9px;
  }
}

/* Setting*/
.setting_page {
  .cs_form_row input,
  .cs_form_row select {
    border: 2px solid $green-blue;
    @include respond-to('phone-xl') {
      border: 1px solid $green-blue;
    }
  }
}

.home_chart_wrapper.manager {
  .sale_TPM_card_box {
    margin-top: 25px;
  }
}

.select-all-warp {
  .select-all-btn {
    color: $color_theme_blue_color;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $color_theme_blue_color;
    border-radius: 5px;
    padding: 8px 17px;
    transition: 0.5s;
    letter-spacing: 0.04em;
    @include respond-to('phone-md') {
      font-size: 13px;
    }

    &:hover {
      background-color: $color_theme_blue_color;
      color: $white;
    }
  }
}

.inviteUserHeader-wrap {
  position: relative;
  @include respond-to('phone-md') {
    margin-bottom: 30px;
  }
  .select-all-warp {
    position: absolute;
    top: 0;
    right: 0;
    @include respond-to('phone-md') {
      position: relative;
    }
  }
}

.add-sales-team-modal {
  &.add-sales-pp-modal {
    .add_sales-cn-wrapper {
      .bulkImportWrapper {
        margin-top: 30px;
        margin-bottom: 10px;
        width: calc(100% - 20px);
      }
    }
  }
}

.modal-head-wrap-people {
  display: flex;
  align-items: center;

  .team_setting_icon {
    margin-right: 15px;
  }

  .modal-head-titles {
    .modal-title {
      margin-bottom: 0;
      line-height: 32px;
    }

    .modal-subtitle {
      font-size: 12px;
      color: #222b2e;
      opacity: 0.8;
      font-weight: 400;
      letter-spacing: 0.04em;
      line-height: 19.2px;
      margin-bottom: 0;
    }
  }
}

/* Pepole Tab*/
.people_tab_menu {
  margin-bottom: 20px;
  padding: 0px;
  list-style: none;

  ul {
    display: flex;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
    list-style: none;
    white-space: nowrap;
    overflow: auto;

    li {
      margin-right: 10px;
    }
  }

  .people_tab_btn {
    display: inline-block;
    background-color: transparent;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 12px;
    padding: 6px 13px;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid $bright_gray;
    color: $color_outer_space_approx;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 8px;
    letter-spacing: 0.04em;
    line-height: 19px;

    &.active-p {
      background-color: $celtic-blue;
      border: 1px solid $celtic-blue;
      color: $white;
    }
  }
}

.addCustomer-form {
  .upload-file-img {
    border-radius: 10px;
    width: 510px;
    margin: 0px auto;
    margin-top: 20px;
    text-align: center;

    .inner-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 30px 10px 20px;
      border: 1px dashed #4294ff;
      background-color: rgba(66, 148, 255, 0.1);
      border-radius: 10px;
      position: relative;

      .close-btn {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        padding: 0px;
        background-color: $white;
        box-shadow: 0px 0px 10px #e3e3e3;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 7px;
          height: 1px;
          background-color: #000;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      img {
        display: inline-block;
        width: 30px;
      }

      .text {
        font-size: 16px;
        margin-bottom: 0px;
        width: calc(100% - 30px);
        padding-left: 10px;
      }
    }
  }
}

.adding-people-modal {
  .add_new_boCO_contant_wrapper {
    margin-top: 30px;
  }
}

.no_people {
  background: #f5f6fa;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 430px;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-top: 75px;

  img {
    width: 70px;
    margin-bottom: 10px;
    background: $white;
    padding: 5px;
  }

  .title {
    width: 100%;
    margin-bottom: 0;
    color: #222b2e;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}

.adding-customer-modal {
  .addCustomer-form {
    .upload-file-img {
      margin-top: 155px;
    }
  }
}

.invite-model-body {
  .site_header .userD_wrapper .user_name {
    width: 100px;
    max-width: 100px;
  }
}

// Empoloyee
.employee_dashboard_page .emsp_table_data .csp_table_wrapper table th span {
  display: block;
  font-size: 9px;
}
@media screen and (max-width: 1820px) {
  .employee_dashboard_page .emsp_table_data .csp_table_wrapper table th {
    padding: 3px 4px;
  }
}
@media screen and (max-width: 1479px) {
  .employee_dashboard_page .emsp_table_data .csp_table_wrapper table th {
    padding: 3px 0px;
  }
  .employee_dashboard_page .emsp_table_data .csp_table_wrapper table th span {
    font-size: 9px;
  }
}

.people_WLdata_box {
  .noData-new .noData-btn {
    background-color: $submit_btn_green;
    border: 1px solid $submit_btn_green;

    &:hover {
      background-color: $submit_btn_green_hover;
      border: 1px solid $submit_btn_green_hover;
    }
  }
}

// download-predsheet-modal-download

.download-predsheet-modal-download .text#text {
  color: #222b2e;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 32px;
  margin-bottom: 20px;
}

body .download-predsheet-modal-download .inner_box {
  background: $white;
}

.people_single_page {
  .employee_dashboard_page {
    padding-top: 20px;
  }
  .top_ii_profile_header {
    .left {
      position: fixed;
      @include respond-to('tablet-md') {
        position: relative;
      }
    }
    .rightBtn {
      width: 100%;
      text-align: right;
      min-height: 38px;
      @include respond-to('tablet-md') {
        width: auto;
      }
      .add_people_btn {
        @include respond-to('phone-md') {
          padding: 5px 8px;
          &.messageBtn,
          &.highlightBtn {
            font-size: 0;
            img {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

/* Salaries tabs Start */

.add_salary-head {
  display: flex;

  .sal_edit {
    opacity: 0;
  }
}

.sal_edit {
  margin-left: 5px;
  cursor: pointer;
}

.add_new_boCO_form__salary .add_new_boCO_details:hover .sal_edit {
  opacity: 1;
}

.add_new_boCO_form__salary {
  justify-content: flex-start;

  .add_new_boCOf_box {
    .add_new_boCO_details {
      width: calc(100% - 0px);
      padding-left: 0;
      cursor: pointer;
    }
  }

  .add_salary-adusted {
    display: flex;

    .add_salary-input {
      border: 2px solid $blue_denim;
      border-radius: 5px;
      font-weight: 400;
      padding: 6px 8px;
      width: 100%;
      max-width: 80px;
      color: $blue_denim;
      font-family: 'Roboto';
      height: 30px;
      min-width: 80px;
    }

    .add_salary-date {
      font-size: 12px;
      color: $blue_denim;
      margin-left: 10px;
      display: flex;
      align-items: center;

      span {
        padding-left: 6px;
      }
    }
  }
}

.add-salary-date-wrap {
  display: flex;
  align-items: center;

  .cs_form_label {
    display: none;
  }

  .custom_date_field {
    img {
      top: 3px;
      left: 10px;
    }
  }

  input {
    font-size: 10.5px;
    padding-left: 30px;
    border: 0;
    color: $blue_denim;
    cursor: pointer;

    &::placeholder {
      color: $blue_denim;
      opacity: 1;
    }
  }
}

/*SubScription Tab*/
.subscription-tab {
  max-width: 930px;
  width: 100%;

  p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: $blue_denim;
    margin-bottom: 30px;
    line-height: 21.6px;
  }

  .roi-profit {
    .roi-profit-lbl {
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.04em;
      color: $blue_denim;
      margin-bottom: 12px;
      line-height: 21.6px;
      display: block;
    }

    .roi-profit-btn {
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 16px;
      padding: 12px 23px;
      font-weight: 400;
      font-size: 16px;
      color: $blue_denim;
      box-sizing: border-box;
      min-width: 140px;
      text-align: left;
      height: 52px;
      max-width: 140px;
    }
  }

  .cs_form_submit_row {
    margin-top: 40px;
  }
}

// /* Laoding Full*/

.loading-full {
  background: rgba(1, 24, 61, 0.8);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-full .notificationProcess {
  border: 6px solid rgba(255, 255, 255, 0.2);
  border-right-color: $white;
  padding: 20px;
  border-radius: 100%;
}

.loading-full .notificationLoading {
  color: $white;
  font-weight: 600;
}

.update-sales-data-modal .cancel-btn.loader_active {
  position: relative;
  .process {
    top: 13px !important;
    border: 3px solid rgba(206, 206, 206, 0.4);
    border-right-color: #b9b9b9;
  }
}

.update-sales-data-modal .inner_box .cs_form_submit_row {
  position: unset;
}
.update-sales-data-modal .inner_box {
  padding: 50px 65px 45px 65px;
}
.cs_moda_main_wrapper .inner_box .modal-close-btn {
  position: absolute;
  top: -10px;
  right: -30px;
  @media screen and (max-width: 767px) {
    top: -22px;
    right: 0;
  }
}

.bulkImportWrapper-main {
  display: flex;
  flex-wrap: wrap;
}
.bulkImportWrapper-main .bulkImportWrapper-sale {
  margin-right: 20px;
}

@media screen and (min-width: 1279px) and (max-width: 1900px) {
  .sale_data_setting .bulkImportWrapper.bulkImportWrapper-sale {
    max-width: calc(50% - 20px);
  }
}

.commission_factored_modal button.add_new_btn {
  margin-top: 15px;
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 400;
  color: $white;
  border: 1px solid #36ce8d;
  border-radius: 5px;
  text-decoration: none;
  padding: 7px 13px;
  font-size: 12px;
  transition: 0.5s;
  cursor: pointer;
  background-color: #36ce8d;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
  &:hover {
    background-color: #27ae75;
    border: 1px solid #27ae75;
    color: $white;
    text-decoration: none;
  }
}
.commission_factored_modal {
  .deal_modal .data_table_wrapper .table_body .table_rowLS {
    padding-left: 10px;
  }
  .deal_modal .data_table_wrapper .table_body .delete_btn img {
    width: 15px;
  }
  .deal_modal .data_table_wrapper .default_rate_table .table_header .rate,
  .deal_modal .data_table_wrapper .default_rate_table .table_body .rate {
    width: 20%;
    text-align: center;
  }
  .deal_modal .data_table_wrapper .default_rate_table .table_header .company,
  .deal_modal .data_table_wrapper .default_rate_table .table_body .company {
    width: 80%;
  }
  .commission_factoredF_title {
    color: #222b2e;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 22px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
}

.wl_confirmation_modal.dealDeleteModal.cs_moda_main_wrapper .inner_box .modal-close-btn {
  right: -30px;
  display: block;
  top: -10px;
}

.download-predsheet-modal .cs_form_submit_row .cancel-btn .process,
button.loader_active.won_btn .process {
  top: 4px;
  left: 4px;
  mix-blend-mode: normal;
}

.customer-view-modal {
  .customerDetailsBox {
    &.email,
    &.address {
      word-break: break-all;
    }
  }
}

.roi-profit {
  .extra_icon_wrap .fm_error {
    width: 100%;
  }
}

.h_details_box_update {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.h_details_box_right .flag_value {
  background: #eaf9fc;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 0px;
}
.deal_modal_comistion .add_new_wrapper {
  padding-left: 230px;
  @include respond-to('tablet-md') {
    padding-left: 0;
  }
}

.employee_dashboard_page .sale_TPM_card_box {
  height: 80px;
  margin-top: 0;
}
.employee_dashboard_page .saRe_card_box {
  height: 80px;
}

.card_design_popeline .h_details_box {
  height: 100%;
}
.card_design_popeline .h_details_box_update .value {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  width: 100%;
}
.card_design_popeline .h_details_box_update .value-resize {
  font-family: 'Roboto';
  font-weight: 300;
  color: $green-blue;
}
.card_design_popeline .h_details_box_left {
  position: relative;
  width: 100%;
}
.card_design_popeline .h_details_box .value .m_value {
  font-size: 38px;
}

.setting_page .security_privacy_form {
  max-width: 340px;
  width: 100%;
}
.sale_TPM_card_design .h_details_box {
  text-align: left;
}
.h_details_box_update__sale_TPM .h_details_box_right .flag_value,
.h_details_box_Sales .h_details_box_right .flag_value,
.h_details_box_empSales .h_details_box_right .flag_value {
  margin-top: 0px;
}
.h_details_box_update__sale_TPM.h_details_box .flag_value,
.h_details_box_TotalSale.h_details_box .h_details_box_right .flag_value,
.h_details_box_Sales.h_details_box .h_details_box_right .flag_value,
.h_details_box_empSales.h_details_box .h_details_box_right .flag_value {
  font-size: 12px;
}
.h_details_box_TeamROI .title {
  margin-bottom: 6px;
}
.h_details_box_TeamROI .h_details_box_right .flag_value {
  margin-top: 0px;
}
.sale_TPM_wrapper_people .sale_TPM_card_design {
  height: calc(100% - 20px);
}
.people_single_page .people-chartM-wrapper .sale_TPM_wrapper .sale_TPM_card_box {
  height: 250px;
}
.h_details_box_PeoleROI .h_details_box_right .flag_value {
  margin-top: 0px;
}
.cs_chatboard_right_skel .help_csm_chatH_img {
  height: 50px;
  width: 50px;
}
.cs_chatboard_right_skel .help_csm_chatH_img {
  position: relative;
}

.skeletonLoader_active .skeleton_loader_line_wrapper span.line.line-50-LH {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.cs_chatboard_right_skel {
  .help_csm_chatMSG_box {
    position: relative;
  }
  .skeleton_load_space {
    padding: 0 !important;
  }
  .help_csm_chatH_contant {
    position: relative;
    height: 50px;
  }
  .help_csm_chat_own_msg {
    position: relative;
    height: 80px;
    width: 50%;
    margin-left: auto;
    text-align: right;
  }
  .help_csm_chat_front_msg {
    position: relative;
    height: 80px;
    width: 50%;
  }
  .help_csm_chatH_name {
    width: 50% !important;
    position: relative;
  }
  .help_csm_chat_footer {
    height: 70px;
    span.line {
      margin-bottom: 10px !important;
    }
  }
  .skel_dot {
    position: relative;
    width: 30px;
    height: 20px;
    .skeleton_loader_line_wrapper {
      padding: 0px;
      border-radius: 0;
    }
    .line {
      width: 20px !important;
      padding: 0;
      border-radius: 0;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1300px) {
  .employee_dashboard_page .emsp_table_data .csp_table_wrapper table th {
    padding: 3px 0px;
  }
}

/*Sales Data Tab*/
.sale_data_setting-main {
  display: flex;
  flex-wrap: wrap;
}
.sale_data_setting-block {
  background: #f8fafc;
  padding: 70px 30px 30px;
  border-radius: 24px;
  max-width: 410px;
  margin-bottom: 30px;
  margin-right: 30px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
  width: calc(50% - 30px);
  @include respond-to('phone-xl') {
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
  }
}
.sale_data_setting-icon {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.sale_data_setting-block h4 {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
}
.sale_data_setting-block p {
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0.04em;
  font-size: 14px;
  color: #222b2e;
  margin-bottom: 27px;
}
.get-started-btn {
  background: #27b7f0;
  border-radius: 5px;
  color: $white;
  padding: 9px 15px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.04em;
  border: 1px solid #27b7f0;
  transition: 0.5s;
}
.get-started-btn:hover {
  background: transparent;
  color: #27b7f0;
}
.sale_data_setting-block .get-started-wrap {
  margin-top: auto;
}

.deal_modal_comistion .add_new_formNewsm {
  padding-left: 230px;
  margin-top: 60px;
  @include respond-to('tablet-md') {
    padding-left: 0px;
  }
}
.employee_dashboard_page .deal_modal_comistion .dcn_wrapper {
  min-height: 318px;
}
.employee_dashboard_page .deal_modal_comistion .dcn_wrapper .tabsF_wrapper {
  min-height: auto;
}

.employee_dashboard_page .deal_modal_comistion .add_new_btn:hover {
  border: 1px solid #27ae75;
  background: #27ae75;
}
.deal_modal_comistion .add_new_formNewsm .done_btn {
  border: 1px solid #36ce8d;
  background: #36ce8d;
  color: $white;
}
.deal_modal_comistion .add_new_formNewsm .done_btn:hover {
  border: 1px solid #27ae75;
  background: #27ae75;
}

.add_bonus-contest .modal-head-wrap {
  width: 100%;
  padding-right: 0;
  margin-bottom: 20px;
}
.add_bonus-contest .add_new_boCO_form_Mwidth .add_new_boCO_form {
  justify-content: flex-start;
}
.add_bonus-contest .add_new_boCO_form_Mwidth .add_new_boCO_form .add_new_boCOf_box {
  width: 50%;
  max-width: 100%;
  @media screen and (max-width: 1199px) {
    width: calc(100% / 2);
  }
  @media screen and (max-width: 991px) {
    width: calc(100% / 2);
  }
  @media screen and (max-width: 575px) {
    width: calc(100%);
  }
}
.add_bonus-contest .add_new_boCO_form_Mwidth {
  max-width: 100%;
  padding-left: 75px;
  @media screen and (max-width: 575px) {
    padding-left: 40px;
  }
}
.add_bonus-contest .add_new_boCOf_box .add_new_boCO_details {
  padding-left: 15px;
}
.select-all-checkbox-wrap {
  position: relative;
}
.select-all-checkbox-wrap input {
  position: absolute;
  top: -2px;
  left: -2px;
  right: 0px;
  bottom: 0px;
  width: 18px;
  height: 18px;
  z-index: 2;
  opacity: 0.00000001;
  cursor: pointer;
}
.select-all-checkbox-wrap label:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 14px;
  height: 14px;
  z-index: 1;
  border: 2px solid $black_coral;
  border-radius: 2px;
  transition: 0.3s;
}
.select-all-checkbox-wrap input:checked + label:before {
  border: 2px solid $celtic-blue;
  background-color: $celtic-blue;
}
.select-all-checkbox-wrap label::after {
  content: '';
  width: 10px;
  height: 4px;
  position: absolute;
  top: 4px;
  left: 3px;
  transform: rotate(-45deg);
  border-left: 2px solid $white;
  border-bottom: 2px solid $white;
  z-index: 1;
  transition: 0.3s;
  opacity: 0;
}
.select-all-checkbox-wrap input:checked + label:after {
  opacity: 1;
}
.select-all-checkbox-main .select-all-lbl {
  padding-left: 30px;
  cursor: pointer;
}
.select-all-checkbox-main {
  padding-left: 75px;
  @media screen and (max-width: 575px) {
    padding-left: 40px;
  }
}

.contest_card-info.contest_card-info-sales {
  padding: 20px 16px 24px 16px;
}
.individual_bonuses_title_desc .title_sec {
  margin-bottom: 15px !important;
}
.individual_bonuses_title_desc .sales_contest_note_text {
  font-size: 16px;
  @include respond-to('phone-md') {
    font-size: 14px;
  }
}
.individual_bonuses_t_header_manger .subtitle-addbonus-wrap {
  margin-top: -5px;
  display: flex;
  flex-direction: column-reverse;
  @include respond-to('phone-md') {
    margin-bottom: 15px;
  }
}

.sales_contest_wrapper.new_design.new_design-green
  .main_contest_card_box
  .individual_bonuses_t_header.individual_bonuses_t_header_manger {
  justify-content: space-between;
}
.individual_bonuses_t_header_manger .add_bonus_btn {
  padding: 0 !important;
  padding-bottom: 8px !important;
}
.individual_bonuses_t_header_inner .sub_title {
  display: block;
}
.add_bonus_btn-day .add_bonus_btn {
  background: unset;
  box-shadow: unset;
  border: none;
  color: $blue_denim;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  border-radius: 50px;
}
.add_bonus_btn img {
  width: 16px;
}
.rday_count {
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  margin-left: 5px;
}
.add_bonus_btn-day {
  text-align: right;
}
.contest_card-info_inner .sales_contest_note_text {
  padding-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}

aside.left_sidebar .nav_item.orange_highlight .nav_link:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #ff7344;
  position: absolute;
  left: auto;
  right: 56px;
  border-radius: 100%;
}
aside.left_sidebar .nav_item.active .nav_link:after {
  opacity: 0;
}
.individualP_data .load_more_btn {
  margin-bottom: 20px;
}

.individual_bonuses_t_header_inner .add_bonus_btn-day .add_bonus_btn {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.individual_bonuses_t_header_inner .add_bonus_btn-day {
  text-align: left;
  margin-bottom: 2px;
}
.individual_bonuses_t_header_inner .subtitle-addbonus-wrap {
  @include respond-to('phone-xl') {
    margin-bottom: 15px;
  }
}
/* Team Hover Block */

.people_card_box_team .inner_box {
  border: 1px solid transparent;
  transition: 0.5s all;
}
.people_card_box_team .inner_box:hover {
  border: 1px solid #dedede;
  box-shadow: 0 2px 16px 0 rgba(35, 118, 196, 0.4);
}
.people_card_box_team .profile_p_title img {
  transform: translateX(0px);
  transition: all 0.3s ease;
}

.people_card_box_team .profile_p_details:hover .profile_p_title img {
  transform: translateX(4px);
  transition: all 0.3s ease;
}

/*Login Page**/
.login_header {
  z-index: 9;
  position: absolute;
  width: 100%;
  top: 0;
  padding: 15px 0px;
  @include respond-to(tablet-xl) {
    padding: 10px 0px;
  }
  .login_form_logo {
    img {
      @include respond-to(tablet-xl) {
        max-width: 110px;
      }
      @include respond-to(tablet-xl) {
        max-width: 90px;
      }
    }
  }
  .container {
    max-width: 1780px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 95%;

    @include respond-to(tablet-xl) {
      width: 100%;
    }
  }
  .login_header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .login_header_right_inner {
    display: flex;
    align-items: center;

    .btn_blue-login {
      background-color: $color_cornflower_blue_approx;
      color: $white;
      font-size: 18px;
      padding: 18px 38px;
      border-radius: 10px;
      letter-spacing: 0.5px;
      box-shadow: 0 10px 20px rgba(61, 126, 239, 0.3);
      transition: 0.3s all;
      text-decoration: none;
      @include respond-to(tablet-xl) {
        font-size: 15px;
        padding: 12px 20px;
        border-radius: 5px;
        box-shadow: 0 2px 15px rgba(61, 126, 239, 0.3);
      }

      &:hover {
        background: #467cd9;
        transition: 0.3s all;
      }
    }
    .login_header_right_txt {
      padding-right: 30px;
      margin-bottom: 0;
      color: #1b3664;
      font-size: 18px;
      @include respond-to(tablet-xl) {
        font-size: 15px;
        padding-right: 15px;
      }
      @include respond-to(phone-md) {
        display: none;
      }
    }
  }
}
.login_form,
.authBox {
  padding: 50px 65px;
  box-shadow: 0 3px 40px rgba(33, 33, 33, 0.11);
  @include respond-to(desktop-md) {
    padding: 40px 55px;
    margin-bottom: 20px;
  }
  @include respond-to(desktop-xs) {
    padding: 30px 45px;
    margin-bottom: 20px;
  }
  @include respond-to(tablet-md) {
    padding: 25px;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  @include respond-to(phone-xl) {
    padding: 20px 15px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  h3 {
    font-size: 45px;
    text-align: center;
    font-family: 'Roboto';
    font-weight: bolder;
    margin-bottom: 50px;
    @include respond-to(desktop-md) {
      font-size: 40px;
      margin-bottom: 30px;
    }
    @include respond-to(desktop-xs) {
      font-size: 35px;
      margin-bottom: 30px;
    }
    @include respond-to(phone-xl) {
      font-size: 26px;
    }
  }
  .cs_form_row input {
    border: 2px solid #33485f;
    padding: 16px 16px 16px 55px;
    border-radius: 8px;
    font-size: 17px;
    letter-spacing: 0.5px;
    font-weight: 400;
    @include respond-to(phone-xl) {
      padding: 14px 14px 14px 55px;
      font-size: 15px;
      border-radius: 5px;
      border: 1px solid #33485f;
    }
  }
  input::placeholder {
    color: #42556b;
    opacity: 1;
  }

  select {
    &::placeholder {
      color: #42556b;
      opacity: 1;
    }
  }
  .input-field {
    margin-bottom: 18px;
    position: relative;
    .field-icon {
      position: absolute;
      top: 24px;
      left: 20px;
    }
  }
  .forgotPassword-btn {
    font-size: 17px;
    color: $color_cornflower_blue_approx;
    position: absolute;
    top: 18px;
    right: 25px;
    padding-right: 0;
    @include respond-to(phone-xl) {
      font-size: 12px;
      top: 16px;
      right: 10px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .cs_form_submit_row input {
    width: 100%;
    background: #518ef8;
    padding: 17px 20px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;
    background-color: $color_cornflower_blue_approx;
    margin-right: 0;
    margin-top: 30px;
    transition: 0.3s all;
    @include respond-to(phone-xl) {
      padding: 14px 20px;
      font-size: 17px;
      border-radius: 5px;
      margin-top: 15px;
    }
    &:hover {
      background: #467cd9;
      transition: 0.3s all;
    }
  }
}

.login_page_box {
  max-width: 605px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  @include respond-to(phone-md) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .create-an-link {
    text-align: center;
    font-size: 17px;
    line-height: 30px;
    color: $white;
    text-decoration: none;
    @include respond-to(phone-xl) {
      color: #000000;
    }
    a {
      font-size: 17px;
      line-height: 30px;
      color: $white;
      font-weight: 600;
      text-decoration: none;
      @include respond-to(phone-xl) {
        color: #000000;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .field-icon-wrap {
    position: absolute;
    top: 16px;
    left: 20px;
    width: 24px;
    text-align: center;
    height: 24px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include respond-to(phone-xl) {
      top: 12px;
    }
  }
}

.loginRegister-wrap {
  min-height: calc(100vh - 73px);
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(images/login-bg.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100vw;
  height: 100%;

  .login-shape {
    position: absolute;
    z-index: -1;
    bottom: 0;
    top: auto;
  }
  .registerBox {
    width: 905px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
  }
  .double-wrapper {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    @include respond-to(phone-md) {
      flex-wrap: wrap;
    }
    .cs_form_row {
      padding: 0 10px;
      width: 50%;
      margin-bottom: 18px;
      @include respond-to(phone-md) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}
.authBox {
  .cs_form_row {
    .extra_icon_wrap {
      position: relative;
      input {
        border: 2px solid #33485f;
        padding: 16px 16px 16px 20px;
        border-radius: 8px;
        font-size: 17px;
        letter-spacing: 0.5px;
        font-weight: 400;
        @include respond-to(phone-xl) {
          border: 1px solid #33485f;
          padding: 14px 12px 14px 20px;
          border-radius: 5px;
          font-size: 15px;
          letter-spacing: 0.5px;
          font-weight: 400;
        }
        &.fcw_value + .cs_form_label {
          top: -7px;
          padding: 0 5px;
          font-size: 12px;
          background: $white;
        }
      }
      label {
        color: #222b2e;
        margin-bottom: 0;
        position: absolute;
        top: 17px;
        left: 20px;
        font-size: 17px;
        letter-spacing: 0.5px;
        font-weight: 400;
        pointer-events: none;
        @include respond-to(phone-xl) {
          top: 15px;
          left: 20px;
          font-size: 15px;
        }
      }
      .cs_form_label {
        transition: 0.5s;
      }
    }
  }
}
.create-account-btn {
  width: 100%;
  background: #518ef8;
  padding: 17px 20px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 10px;
  background-color: $color_cornflower_blue_approx;
  margin-right: 0;
  margin-top: 30px;
  transition: 0.3s all;
  color: $white;
  @include respond-to(phone-xl) {
    padding: 14px 20px;
    font-size: 17px;
    border-radius: 5px;
    margin-top: 15px;
  }

  &:hover {
    background: #467cd9;
    transition: 0.3s all;
  }
}
.form-unc-links {
  @include respond-to(phone-xl) {
    padding-bottom: 15px;
  }
  p {
    text-align: center;
    font-size: 17px;
    line-height: 30px;
    color: $white;
    margin-bottom: 5px;
    @include respond-to(phone-xl) {
      font-size: 14px;
      line-height: 20px;

      color: #000000;
    }
    a {
      font-size: 17px;
      line-height: 30px;
      color: $white;
      font-weight: 600;
      margin-left: 5px;
      text-decoration: none;
      @include respond-to(phone-xl) {
        font-size: 14px;
        color: #000000;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.registerForm {
  .cs_form_row {
    margin-bottom: 18px;
    @include respond-to(phone-xl) {
      margin-bottom: 15px;
    }
  }
  .signup-form-wrapper {
    .hsPassword {
      .hsImg {
        top: 20px;
        right: 20px;
        @include respond-to(phone-xl) {
          top: 16px;
        }
      }
    }
  }
}
.loginRegister-wrap {
  .forgotBox {
    margin-left: auto;
    margin-right: auto;
    max-width: 470px;
    width: 100%;
    .cs_form_row {
      margin-bottom: 18px;
    }
    .signup-form-wrapper .hsPassword .hsImg {
      top: 20px;
      right: 20px;
    }
  }
}
.wl_confirmation_modal.cs_moda_main_wrapper.forgotPasswordModal {
  .cs_form_row input {
    border: 2px solid #33485f;
    padding: 16px 16px 16px 20px;
    border-radius: 8px;
    font-size: 17px;
    letter-spacing: 0.5px;
    font-weight: 400;
    @include respond-to(phone-xl) {
      border: 1px solid #33485f;
      padding: 14px 12px 14px 20px;
      border-radius: 5px;
      font-size: 15px;
      letter-spacing: 0.5px;
      font-weight: 400;
    }
    &::placeholder {
      color: #000000;
    }
  }
  .cs_form_submit_row .submit-btn-with-process input[type='submit'] {
    width: 100%;
    padding: 17px 30px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    background-color: $color_cornflower_blue_approx;
    margin-right: 0;
    margin-top: 30px;
    transition: 0.3s all;
    color: $white;
    border: 2px solid $color_cornflower_blue_approx;
    @include respond-to(phone-xl) {
      padding: 14px 20px;
      font-size: 17px;
      border-radius: 5px;
      margin-top: 15px;
      border: 1px solid $color_cornflower_blue_approx;
    }
  }
  .inner_box .cs_form_submit_row .cancel-btn {
    padding: 17px 30px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    background-color: transparent;
    margin-right: 0;
    margin-top: 30px;
    transition: 0.3s all;
    color: #4b4c4e;
    border: 2px solid #33485f;
    @include respond-to(phone-xl) {
      padding: 14px 20px;
      font-size: 17px;
      border-radius: 5px;
      margin-top: 15px;
      border: 1px solid #33485f;
    }
  }
}

.schedule-demo-main {
  position: fixed;
  bottom: 0;
  z-index: 9;
  right: 0;
  top: auto;
  left: auto;
  @media screen and (max-width: 1800px) {
    right: 0;
  }
  @media screen and (max-width: 991px) {
    top: 0;
    transform: none;
    position: relative;
  }
}
.schedule-demo {
  max-width: 380px;
  background: #f5f6fa;
  box-shadow: 0px 3px 40px #2121211c;
  border-top: 15px solid #e247a8;
  text-align: center;
  padding: 28px 40px;
  @media screen and (max-width: 1800px) {
    max-width: 310px;
    padding: 28px 20px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 280px;
    padding: 30px 15px;
  }
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
  .pink-btn {
    background-color: #e247a8;
    color: $white;
    text-align: center;
    padding: 17px 35px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    transition: 0.3s all;
    &:hover {
      background-color: #c51d86;
    }
    @media screen and (max-width: 1800px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 16px;
      padding: 15px 20px;
    }
  }
}
.schedule-demo .title {
  font-size: 30px;
  font-weight: 700;
  color: #0a1120;
  @media screen and (max-width: 1800px) {
    font-size: 26px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 24px;
  }
}
.schedule-demo p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 1800px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    line-height: 24px;
  }
}
.add-sales-team-first {
  .add_sales-cn-wrapper {
    .left-cn {
      width: 100%;
      max-width: 940px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 1579px) {
        max-width: 880px;
      }
      @media screen and (max-width: 1480px) {
        max-width: 700px;
      }
      @media screen and (max-width: 1279px) {
        max-width: 630px;
      }
      @media screen and (max-width: 1199px) {
        margin-left: 5%;
      }
      @media screen and (max-width: 991px) {
        margin-left: auto;
        max-width: 880px;
      }
      .header {
        flex-direction: column-reverse;
        padding: 40px 0px 5px 0px;
        @media screen and (max-width: 991px) {
          padding: 20px 0px 5px 0px;
        }
        .title {
          color: #0a1120;
          font-size: 40px;
          letter-spacing: 0.04em;
          font-weight: 700;
          margin-bottom: 0px;
          padding-top: 26px;
          text-align: center;
          @media screen and (max-width: 1480px) {
            font-size: 36px;
          }
          @media screen and (max-width: 991px) {
            font-size: 32px;
          }
          @media screen and (max-width: 767px) {
            font-size: 28px;
          }
          @media screen and (max-width: 575px) {
            font-size: 24px;
          }
        }
      }
      .contant-wrapper {
        background: transparent;
        box-shadow: unset;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        height: unset;
        min-height: unset;
        .add-saleTeam-form {
          width: 780px;
        }
      }
      .processBar {
        &:before {
          opacity: 0;
        }
        li {
          width: 36px;
          height: 36px;
          border: 2px solid #c4c4c4;
          background-color: transparent;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 17px;
          color: #c4c4c4;
          margin-right: 15px;
          position: relative;
          z-index: 2;
          &.active {
            background: transparent linear-gradient(180deg, #e247a8 0%, #7452fb 100%) 0% 0% no-repeat;
            color: transparent;
            border: 1px solid #c4c4c4;
            &:before {
              content: '';
              position: absolute;
              top: 10px;
              left: 8px;
              width: 14px;
              height: 6px;
              border-left: 3px solid $white;
              border-bottom: 3px solid $white;
              transform: rotate(-45deg);
            }
          }
          &.current-active {
            border: 2px solid #e247a8;
            color: #e247a8;
          }
        }
      }
      .cs_form_row {
        .extra_icon_wrap {
          input {
            position: relative;
            z-index: 2;
            background-color: $white;
            border: 2px solid #33485f;
            font-size: 17px;
            padding: 16px 20px;
            border-radius: 8px;
            @include respond-to(phone-xl) {
              padding: 14px 12px 14px 20px;
              border-radius: 5px;
              font-size: 15px;
            }
            &.fcw_value + .cs_form_label {
              color: #001b38;
            }
          }
          label {
            color: #001b38;
            font-weight: 500;
            font-size: 17px;
            margin-bottom: 0px;
            padding: 0px 5px 0px 5px;
            width: auto;
            position: absolute;
            top: 17px;
            left: 20px;
            transition: 0.5s;
            z-index: 2;
            font-family: 'Red Hat Display';
            pointer-events: none;
            @include respond-to(phone-xl) {
              top: 15px;
              left: 20px;
              font-size: 15px;
            }
          }
        }
        .custom_date_label {
          input {
            position: relative;
            z-index: 2;
            background-color: $white;
            border: 2px solid #33485f;
            font-size: 17px;
            padding: 16px 20px;
            border-radius: 8px;
            @include respond-to(phone-xl) {
              padding: 14px 12px 14px 20px;
              border-radius: 5px;
              font-size: 15px;
            }
          }
          .cs_form_label {
            color: #001b38;
            font-weight: 500;
            font-size: 17px;
            margin-bottom: 0px;
            padding: 0px 5px 0px 5px;
            width: auto;
            position: absolute;
            top: 17px;
            left: 20px;
            transition: 0.5s;
            z-index: 2;
            font-family: 'Red Hat Display';
            pointer-events: none;
            @include respond-to(phone-xl) {
              top: 15px;
              left: 20px;
              font-size: 15px;
            }
          }
          &.fcw_value {
            .cs_form_label {
              color: #001b38;
            }
            input {
              border-color: #001b38;
            }
          }
        }
      }
      .text {
        color: #001b38;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 70px;
        text-align: center;
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          line-height: 28px;
        }
        @media screen and (max-width: 991px) {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 575px) {
          font-size: 15px;
        }
      }
      .bulkImportWrapper {
        .bulk-import-btn {
          color: $white;
          font-weight: 500;
          font-size: 16px;
          background-color: #2bc48a;
          border: 1px solid #2bc48a;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          padding: 16px 20px;
          transition: 0.5s;
          @include respond-to(phone-xl) {
            border-radius: 6px;
            padding: 14px 18px;
          }
          &:hover {
            background-color: #27ae75;
            border: 1px solid #27ae75;
          }
          img {
            display: none;
          }
        }
      }
      .add-more-btn,
      .add_people_Rabtn {
        color: #518ef8;
        font-size: 17px;
        @include respond-to(phone-xl) {
          font-size: 15px;
        }
      }
    }
    .bulkImportWrapper {
      margin-top: 20px;
      margin-bottom: 24px;
      p {
        margin-bottom: 0px;
        width: calc(100% - 123px);
        padding-right: 20px;
        font-size: 17px;
        color: $white;
        @include respond-to(phone-xl) {
          font-size: 16px;
        }
        @include respond-to(phone-md) {
          font-size: 15px;
          padding-right: 10px;
        }
      }
    }
  }
  .inner_box-first {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    height: 100%;
    min-height: 100vh;
    padding: 0px;
    background: #f7f8fd;
    @media screen and (max-width: 991px) {
      min-height: auto;
    }
  }
  .cs_form_submit_row {
    justify-content: center;
    padding-bottom: 20px;

    input[type='submit'] {
      background-color: #518ef8;
      color: $white;
      border: none;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 20px;
      border-radius: 10px;
      padding: 17px 60px;
      cursor: pointer;
      margin-right: 9px;
      transition: 0.3s all;
      &:disabled {
        background-color: #dee0e0;
        color: #222b2e;
        cursor: not-allowed;
        &:hover {
          background-color: #dee0e0;
        }
      }
      @include respond-to(phone-xl) {
        padding: 14px 30px;
        font-size: 17px;
        border-radius: 5px;
      }
      &:hover {
        background-color: #467cd9;
      }
    }
  }
}
body.sales-team-body {
  padding-top: 0 !important;
}
.add-sales-team-first ~ .main_wrapper,
.add-sales-team-first ~ .site_header,
.add-sales-team-first ~ .free-trial-bar {
  display: none;
}

.add-sales-team-first {
  &.add-sales-pp-modal {
    .add_sales-cn-wrapper {
      .left-cn {
        .text {
          max-width: 680px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .bulkImportWrapper {
        margin-bottom: 20px;
      }
    }
  }
}
/* Setting page*/
.setting_card {
  .innerCN {
    .inviteUserHeader-wrap {
      max-width: 912px;
      width: 100%;
      padding-right: 100px;
    }
    .inviteUserHeader {
      .title {
        color: $blue_denim;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 5px;
        font-family: 'Red Hat Display', sans-serif;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.04em;
        color: $blue_denim;
        margin-bottom: 30px;
        line-height: 21.6px;
      }
    }
    .submit-btn-with-process {
      &.loader_active input[type='submit'] {
        padding-left: 46px !important;
      }
      .process {
        top: 11px;
        left: 22px;
      }
    }
  }
  .add_new_boCO_form {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}

// Customer View Modal

.customer-view-modal {
  .customer-tabs-wrap {
    .title {
      width: 240px;
      margin-bottom: 0px;
      @include respond-to('phone-xl') {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    display: flex;
    align-items: center;
    @include respond-to('desktop-xs') {
      flex-wrap: wrap;
    }
    .customer-tabs {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          padding-left: 32px;
          @include respond-to('phone-xl') {
            padding-left: 0;
            padding-right: 20px;
          }
          button {
            font-size: 16px;
            font-weight: 300;
            font-family: 'Roboto';
            padding: 2px 0px;
            @include respond-to('phone-xl') {
              font-size: 14px;
            }
            &.active {
              border-bottom: 2px solid #276cbd;
              color: #276cbd;
            }
          }
        }
      }
    }
  }

  .transaction-history-table {
    max-width: 670px;
    margin-top: 20px;
    width: 100%;
    .customerTable {
      width: 100%;
      border-collapse: collapse;

      .teammate {
        color: $black;
      }

      .companyBtn {
        position: relative;
        padding-right: 16px;
        color: $blue_denim;
        margin-left: 10px;
      }

      .companyBtn:before {
        content: '';
        position: absolute;
        top: 5px;
        right: 0px;
        width: 6px;
        height: 6px;
        border-left: 2px solid $blue_denim;
        border-bottom: 2px solid $blue_denim;
        transform: rotate(-45deg);
        transition: 0.5s;
      }

      .companyBtn.active:before {
        transform: rotate(-225deg);
        top: 8px;
      }

      thead tr {
        td {
          color: #222b2e;
          font-size: 14px;
          font-weight: 400;
          padding: 12px 20px;
          background: #f6f8fa;

          &:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      tbody {
        .hide {
          display: none;
        }

        tr {
          &:nth-child(even) {
            background: #fafbfd;
          }

          td {
            padding: 7px 20px;
            color: $blue_denim;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.resend_link .email-verification-wrapper {
  padding-top: 0px;
}
.resend_link .authBox {
  padding: 20px 45px;
}
.add-sales-team-modal .submit-btn-with-process .process {
  top: 20px;
  @include respond-to(phone-xl) {
    top: 16px;
  }
}
.contest_page_head .add_bonus_btn {
  margin-left: 10px;
}
.contestPage_cc .submit-btn-with-process .process {
  top: 10px;
}
.contestPage_cc .cs_form_submit_row {
  justify-content: center;
}
.contestPage_header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .contest_page_head {
    margin-left: auto;
  }
}
.add_bonus_btn-trans {
  margin-left: 10px;
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 400;
  color: $color_theme_blue_color;
  border: 1px solid transparent;
  border-radius: 5px;
  text-decoration: none;
  padding: 8px 18px;
  font-size: 12px;
  transition: 0.5s;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    border-color: $color_theme_blue_color;
  }
}
.left-btn {
  color: $green-blue;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  position: relative;
  padding-left: 12px;
  margin-right: 15px;
  text-decoration: none;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 7px;
    height: 7px;
    border-left: 2px solid $green-blue;
    border-bottom: 2px solid $green-blue;
    transform: translateY(-50%) rotate(45deg);
  }
}
.login_form .cs_form_submit_row .process {
  top: 50px;
  left: 57%;
  @include respond-to('phone-xl') {
    top: 34px;
    left: 15px;
  }
}
.registerForm .create-account-btn,
.forgotForm .create-account-btn {
  position: relative;
}
.registerForm .create-account-btn .process,
.forgotForm .create-account-btn .process {
  top: 19px;
  left: 62%;
  @include respond-to('phone-xl') {
    top: 15px;
    left: 15px;
  }
}

.forgotPasswordModal .cs_form_submit_row .process {
  top: 50px;
  left: 35px;
  @include respond-to('phone-xl') {
    top: 31px;
    left: 15px;
  }
}
.login_form .cs_form_submit_row .process + input[type='submit'] {
  padding: 17px 20px;
}
.emsp_table_data {
  .table_default {
    thead {
      tr {
        th {
          @include respond-to('tablet-md') {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.inviteUserHeader-wrap .select-all-checkbox-main {
  padding-left: 0;
  margin-bottom: 15px;
}
.manager .sale_TPM_card_design .SettingIcon_wrap {
  opacity: 0;
}

.manager .sale_TPM_card_design:hover .SettingIcon_wrap {
  opacity: 1;
}
.customerTableSuperAdmin {
  .toggleTable {
    .select-all-checkbox-wrap {
      margin-left: calc(50% - 7px);
      @include respond-to('phone-xl') {
        margin-left: auto;
        display: inline-block;
      }
    }
  }
  .select-all-checkbox-wrap {
    position: relative;
    input[type='checkbox']:disabled + label:before {
      background: #d5d5d5;
      border: 2px solid #d5d5d5;
    }
    margin-left: calc(50% - 9px);
    @include respond-to('phone-xl') {
      margin-left: auto;
      display: inline-block;
    }
  }
}
.new-customer-delete {
  cursor: pointer !important;
  img {
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
}
.add-sales-team-modal {
  .bulk-import-btn-fixed {
    background-color: #1b3664;
    border-radius: 10px 0px 0px 10px;
    color: $white;
    position: fixed;
    top: 50%;
    right: 0px;
    padding: 20px;
    width: 150px;
    font-size: 16px;
    text-align: center;
    transform: translateY(-100%);
    z-index: 99;
    transition: 0.3s all;
    box-sizing: border-box;
    @include respond-to('tablet-md') {
      border-radius: 8px;
      padding: 15px;
      position: relative;
      transform: none;
      top: 0;
      width: 100%;
      z-index: unset;
    }
    .bulkImportWrapper-top {
      color: #2bc48a;
      display: block;
      margin-bottom: 10px;
      text-align: center;
    }
    p {
      margin-bottom: 0;
      line-height: 22px;
    }
    &:hover {
      background-color: #162c52;
    }
  }
}
.import_sale-block {
  max-width: 490px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: $white;
  border-radius: 16px;
  padding: 85px 20px 35px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  margin-top: 110px;
  .import_sale-icon {
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  h4 {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 15px;
    @include respond-to('phone-md') {
      font-size: 24px;
    }
  }
  p {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    line-height: 22px;
    margin-bottom: 30px;
  }
  .bulk-import-btn {
    color: $white;
    font-weight: 500;
    font-size: 16px;
    background-color: #2bc48a;
    border: 1px solid #2bc48a;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 15px 20px;
    transition: 0.5s;
    @include respond-to('phone-md') {
      border-radius: 8px;
      padding: 12px 15px;
    }
    &:hover {
      background-color: #27ae75;
      border: 1px solid #27ae75;
    }
  }
}

.emsp_table_data .csp_table_wrapper tbody td.profile_name {
  white-space: nowrap;
}
.wl_confirmation_modal.cs_moda_main_wrapper .text.text-deal-status {
  font-size: 18px;
}

/*14-12*/
.nodata-supperAdmin-inner {
  max-width: 450px;
  margin: 20px auto;
}

.nodata-supperAdmin {
  background: #ffffff;
}
.nodata-supperAdmin h5 {
  color: #95a0b1;
  font-weight: 600;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.nodata-supperAdmin p {
  font-size: 16px;
  margin-bottom: 0;
}
.nodata-supperAdmin-row td {
  background: #ffffff !important;
}

.loginRegister-wrap {
  &.loginRegister-wrap-forgot {
    .forgotBox {
      max-width: 580px;
      width: calc(100% - 30px);
    }
    .forgotBox h3 {
      font-size: 22px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 30px;
      @include respond-to(phone-md) {
        font-size: 18px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .react-datepicker__input-container input::placeholder {
      opacity: 0;
    }
    .custom_date_field {
      &.with_label {
        img {
          top: 11px;
          left: 12px;
        }
      }
    }
    .custom_date_label {
      input {
        position: relative;
        z-index: 2;
        background-color: $white;
        font-size: 17px;
        padding: 16px 20px;
        border-radius: 8px;
        @include respond-to(phone-xl) {
          padding: 14px 12px 14px 20px;
          border-radius: 5px;
          font-size: 15px;
        }
      }
      .cs_form_label {
        color: #001b38;
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 5px;
        width: auto;
        position: absolute;
        top: 17px;
        left: 20px;
        transition: 0.5s;
        z-index: 2;
        font-family: 'Red Hat Display';
        pointer-events: none;
        @include respond-to(phone-xl) {
          top: 15px;
          left: 20px;
          font-size: 15px;
        }
      }
      &.fcw_value {
        .cs_form_label {
          color: #001b38;
          top: -7px;
          background-color: #fff;
          z-index: 2;
          font-size: 12px;
        }
        input {
          border-color: #001b38;
        }
      }
    }
    @include respond-to(phone-xl) {
      .forgotBox .signup-form-wrapper .hsPassword .hsImg {
        top: 16px;
      }
    }
  }
}

.loginRegister-wrap .forgotBox .signup-form-wrapper .hsPassword .hsImg {
  z-index: 2;
}
h3 .no-card {
  font-weight: 400 !important;
  display: block;
  font-size: 20px;
  padding-top: 10px;
  @include respond-to(phone-xl) {
    font-size: 16px;
    padding-top: 10px;
  }
}
.registerBox {
  .registerForm {
    .package_cs_select_box > div > div:first-of-type {
      border: 2px solid #33485f;
      padding: 14px 10px 14px 20px;
      border-radius: 8px;
      font-size: 17px;
      letter-spacing: 0.5px;
      font-weight: 400;
      @include respond-to(phone-xl) {
        padding: 11px 12px 11px 20px;
        border: 1px solid #33485f;
        border-radius: 5px;
      }

      div {
        color: #222b2e;
        padding: 0px 0px 0px 0;

        svg {
          fill: #272c32;
        }
      }
    }
    .cs_form_row_select .cs_form_label {
      position: absolute;
    }
    .hsPassword .hsImg {
      z-index: 1;
    }
  }
}

.customer-page .customerTable-wrapper.customerTableEMP .customerTable tbody tr td.name button {
  font-weight: 400;
}
.customer-page .customerTable-wrapper.customerTableEMP .customerTable tbody tr td.name {
  font-weight: 400;
}
.setting_card {
  .package_cs_select_box > div > div:first-of-type {
    border: 2px solid #206cb4;
  }
  .package_cs_select_box > div > div:first-of-type > div:first-of-type > div#react-select-2-placeholder {
    color: #206cb4;
  }
  .package_cs_select_box > div > div:first-of-type div svg {
    color: #206cb4;
  }
  .cs_form_row_select .cs_form_label {
    position: absolute;
  }
}
.add_new_boCO_form__salary {
  padding-bottom: 210px;
  max-height: 440px;
}

.sales_contest_wrapper.contestPage_cc .contest_sliderM .contest_card_slide .edit_btn.edit_btn_delete {
  right: 32px;
}
.sales_contest_wrapper.contestPage_cc
  .contest_sliderM
  .contest_card_slide
  .contest_card-info:hover
  .subtitle-addbonus-wrap {
  padding-right: 50px;
}
.contestPage_cc .individual_bonuses_t_header_manger .subtitle-addbonus-wrap {
  transition: 0.5s all;
}

.no_data_main .noData_found img {
  width: 130px;
}
.no_data_main .noData_found p {
  color: #206cb4;
  font-weight: 500;
  margin-top: 10px;
}

.people_D_wrapper .infinite-scroll-component {
  overflow-x: hidden !important;
}

/*18-01-2023 manage access*/
.manageAccess-block {
  border: 1px solid #eeeeee;
  border-radius: 16px;
  display: inline-block;
  padding: 16px;
  margin-bottom: 20px;
  width: 100%;
}
.manageAccess-head-wrap {
  display: flex;
  width: 100%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.manageAccess-head {
  display: flex;
  align-items: center;
  color: #222b2e;
  font-size: 14px;
  font-weight: 500;
  width: 49%;
}
.manageAccess-head div {
  padding: 10px;
}
.manageAccess-head .manageAccess-head-name,
.manageAccess-head-name {
  width: 270px;
}
.manageAccess-head-manager,
.manageAccess-head-users {
  width: 100px;
  text-align: center;
}
.manageAccess-row {
  display: flex;
  width: 49%;
  border-radius: 10px;
  background: #f8f9fb;
  margin-bottom: 10px;
}

.manageAccess-head-name {
  display: flex;
  align-items: center;
}
.manageAccess-col {
  padding: 8px 10px;
}
.manageAccess-userInfo .add_new_boCOEX_data h4 {
  color: #222b2e;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.manageAccess-userInfo .add_new_boCO_img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
}
.manageAccess-userInfo .add_new_boCO_img .chatProfile,
.manageAccess-userInfo .add_new_boCO_img img {
  width: 34px;
  height: 34px;
  font-size: 14px;
  border-radius: 100%;
}
.manageAccess-userInfo .add_new_boCO_details {
  display: flex;
  align-items: center;
}
.manageAccess-row .manageAccess-head-manager,
.manageAccess-row .manageAccess-head-users {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manageAccess-row-wrap:before {
  width: 1px;
  height: 100%;
  background: #efe9e9;
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.manageAccess-row-wrap {
  position: relative;
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  scrollbar-color: $color_gray_chateau_approx transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    &:vertical {
      height: 30px;
      background-color: $color_gray_chateau_approx;
      border-radius: 10px;
    }
  }
}

.custom_checkbox_row_bonuses .custom_radio_box {
  display: inline-block;
  padding-right: 30px;
  margin-bottom: 15px;
}
.custom_checkbox_row_bonuses .custom_radio_box label {
  font-size: 16px;
}
.modal-head-wrap-select .modal-head-titles {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .manageAccess-block {
    width: 100%;
    padding: 10px;
  }
  .manageAccess-row,
  .manageAccess-head {
    width: 100%;
  }
  .manageAccess-row-wrap:before {
    display: none;
  }
  .manageAccess-head + .manageAccess-head {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .manageAccess-col {
    padding: 6px 4px;
  }
  .manageAccess-userInfo .add_new_boCO_img .chatProfile,
  .manageAccess-userInfo .add_new_boCO_img {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }
  .manageAccess-userInfo .add_new_boCOEX_data h4,
  .manageAccess-head {
    font-size: 12px;
  }
  .manageAccess-userInfo .add_new_boCO_img {
    margin-right: 5px;
  }
  .manageAccess-row {
    margin-bottom: 5px;
  }
}

.roi-hide .sale_TPM_wrapper {
  display: none;
}
.manager.roi-hide .saRe_card_wrapper {
  width: 390px;
}
.roi-hide .total_sale_wrapper {
  width: calc(100% - 390px);
}
.roi-hide .roi-people {
  display: none !important;
}
.roi-hide .sale_TPM_wrapper_people {
  display: none;
}
.roi-hide.people_single_page .employee_dashboard_page .saRe_card_wrapper {
  padding-left: 0;
  width: 100%;
}
.roi-hide.goals_dashboard_wrapper .total_sale_wrapper {
  width: 100%;
  padding-right: 0;
}

.cs_moda_main_wrapper.add_bonus-team .inner_box {
  min-height: 380px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 48px;
}
.add_new_boCO_contant_wrapper {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .cs_moda_main_wrapper.add_bonus-team .inner_box {
    padding: 15px;
  }
  .add_bonus-contest.add_bonus-team .add_new_boCO_form_Mwidth {
    padding-left: 0;
  }
}

.h_details_box .flag_value {
  &.green-bg {
    background: rgba(29, 193, 138, 0.1);
    color: #1dc18a !important;
  }

  &.red-bg {
    background: rgba(238, 64, 115, 0.1) !important;
    color: #ee4073 !important;
  }
}

.customer-page.superAdminHome .toggleTR .toggleTable td.phone.blue {
  color: #2775bd !important;
}

// .you-icon {
//   display: inline-block;
//   position: absolute;
//   top: -1px;
//   left: 0;
//   background: #2376c4;
//   color: #ffffff;
//   font-size: 13px;
//   padding: 3px 10px 3px 10px;
//   font-weight: 500;
//   border-radius: 16px 0px;
//   text-transform: capitalize;
//   width: 55px;
//   text-align: left;
//   &:after {
//     border: 6px solid #FFD700;
//     border-bottom: 6px solid transparent;
//     content: "";
//     position: absolute;
//     height: 0;
//     width: 0;
//     top: 0px;
//     right: 4px;
//   }
// }

.deal_modal-changes {
  &.deal_modal .dcn_wrapper .tabsF_wrapper {
    width: 66.66%;
    padding-left: 15px;
    margin-top: -30px;
    @media (max-width: 991px) {
      margin-top: 20px;
      width: 100%;
    }
    @media (max-width: 575px) {
      padding-left: 0px;
    }
  }
  &.deal_modal_comistion .add_new_wrapper {
    width: 100%;
    padding-left: calc(33.33% + 15px);
    display: flex;
    align-items: flex-end;
    .add_new_btn {
      height: 36px;
    }
  }
  &.deal_modal_comistion .add_new_formNewsm {
    padding-left: 0;
    margin-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &.deal_modal .add_new_form .radio_btn_wrapper {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  &.deal_modal .add_new_formNewsm .doubleF_wrapper {
    width: 100%;
  }
  &.deal_modal .add_new_formNewsm .company .deal_size,
  &.deal_modal .add_new_formNewsm .company .select {
    width: 100%;
  }
  .add_people_Rabtn {
    margin-top: 5px;
    width: auto;
    text-align: left;
    margin-left: 10px;
    display: inline-block;
  }
  &.deal_modal .add_new_formNewsm .form_row.company label.cs_form_label,
  .form_row.deal_size .cs_form_label,
  .select .cs_form_label {
    margin-bottom: 0;
    @media (max-width: 991px) {
      margin-bottom: 8px;
    }
  }

  .dcn_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    @media (max-width: 991px) {
      flex-direction: row;
    }
    > form {
      width: 33.33%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
  .package_cs_select_box > div > div:first-of-type {
    border: 2px solid #3f454d;
  }
  &.deal_modal .add_new_form input {
    border: 2px solid #3f454d;
  }
  &.deal_modal .package_cs_select_box svg {
    color: #3f454d;
  }
  &.deal_modal .package_cs_select_box {
    z-index: unset;
  }
  &.deal_modal .add_new_form .btn_wrapperDC {
    margin-top: auto;
    padding-bottom: 20px;
  }
  .add-deal-title {
    background-color: #f6f8fa;
    border-radius: 5px;
    color: #525e6b;
    font-size: 12px;
    font-weight: 400;
    padding: 14px 10px;
    margin-bottom: 15px;
  }

  &.deal_modal .cs_tab_menu {
    padding-left: 0;
  }
  &.deal_modal .data_table_wrapper .table_body .table_td {
    font-weight: 400;
  }
  &.deal_modal .add_new_formNewsm .doubleF_wrapper .form_row.company {
    width: 100%;
    margin-right: 7.5px;
  }
  &.deal_modal .add_new_form .form_row.deal_size {
    width: 140px;
  }
  &.deal_modal .add_new_formNewsm .form_row.company {
    width: calc(100% - 200px);
    align-items: start;
  }
  @media (max-width: 575px) {
    &.deal_modal .add_new_form .form_row.deal_size {
      width: 140px;
    }
    &.deal_modal .add_new_formNewsm .form_row.company {
      width: calc(100% - 7.5px);
    }
    .add_people_Rabtn {
      margin-top: 13px;
    }
  }

  .bold {
    font-weight: bold;
  }

  .create_customer {
    .package_cs_select_box > div > div:first-of-type {
      min-height: 40px !important;
    }
    svg {
      display: none !important;
    }
  }
}

.individual_bonuses_t_header_inner .subtitle-addbonus-wrap {
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 767px) {
    align-items: flex-start;
    min-width: 100%;
  }
}

.cs_moda_main_wrapper .inner_box .cs_form_submit_row .help input[type='submit'] {
  padding: 13px 24px;
}
.submit-btn-with-process.help .process {
  top: 15px;
  left: 12px;
}
.submit-btn-with-process.help.loader_active input[type='submit'] {
  padding-left: 24px !important;
}

.add-deal-new input {
  border: 2px solid #3f454d;
  border-radius: 5px;
  color: #222b2e;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 12px;
  width: 100%;
  font-family: 'Roboto';
}
.deals-size-wrap .add_people_Rabtn {
  margin-bottom: 0;
  padding-top: 15px;
  margin-left: 10px;
}
.add-deal-new .deals-size-wrap {
  display: flex;
  align-items: self-start;
}
.add-deal-new .btn_wrapperDC .done_btn {
  border: 1px solid #36ce8d;
  background: #36ce8d;
  color: #ffffff;
  font-size: 14px;
  padding: 9px 30px;
  transition: 0.5s;
  border-radius: 5px;
  position: relative;
}
.add-deal-new .btn_wrapperDC .done_btn .process {
  top: 9px;
  left: 6px;
}
.add-deal-new .btn_wrapperDC .done_btn:hover {
  border: 1px solid #27ae75;
  background: #27ae75;
}
.add-deal-new .btn_wrapperDC .cancel_btn {
  border: 1px solid transparent;
  border-radius: 5px;
  color: #b0b8c5;
  font-size: 14px;
  padding: 9px 30px;
  transition: 0.5s;
}
.add-deal-new .btn_wrapperDC {
  margin-top: 30px;
}
.add-deal-new .package_cs_select_box > div > div:first-of-type {
  border: 2px solid #3f454d;
}
.add-deal-new.cs_moda_main_wrapper .inner_box {
  min-height: 330px;
  position: relative;
  max-width: 550px;
  padding: 26px 48px 26px 48px;
  @media (max-width: 767px) {
    padding: 26px 15px 26px 15px;
  }
}
.add-deal-new .modal-head-titles {
  width: 100%;
}
.add-deal-new .add_new_formNewsm .radio_btn_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.add-deal-new .doubleF_wrapper {
  margin-top: 20px;
}
.add-deal-new .add_new_formNewsm .radio_btn_wrapper .form_row.company {
  width: 55%;
  padding-right: 15px;
  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0px;
  }
}
.add-deal-new .add_new_formNewsm .radio_btn_wrapper .deals-size-wrap {
  width: 45%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.add-deal-new .package_cs_select_box svg {
  color: #3f454d;
}
.add-deal-new.cs_moda_main_wrapper .modal-header {
  margin-bottom: 0;
}

.deal_modal.deal_modal-changes .btn_wrapperDC .done_btn.loader_active {
  padding-left: 30px;
}
.add-deal-new .btn_wrapperDC .done_btn:disabled {
  cursor: not-allowed;
}
.add-deal-title {
  font-size: 20px;
  font-weight: 400;
}
body .add-deal-new .package_cs_select_box > div > div:first-of-type {
  font-weight: 300;
}
.add-deal-new input {
  font-weight: 300;
  color: #2775bd;
}
.add-deal-new input::-webkit-input-placeholder {
  color: #2775bd;
  font-weight: 300;
}
.site_header .right_wrapper .addDealBtn {
  background-color: #36ce8d;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 10px;
  margin-right: 20px;
  transition: 0.5s;
  img {
    filter: grayscale(1) brightness(0) invert(1);
    margin-right: 8px;
    @media (max-width: 575px) {
      font-size: 0px;
      margin-right: 0px;
    }
  }
  &:hover {
    background-color: #27ae75;
    color: #fff;
  }
  @media (max-width: 575px) {
    font-size: 0px;
  }
}

.home_chart_wrapper.manager .card_design.saRe_card_design:not(.card_design_popeline) {
  height: 280px;
  @media (max-width: 575px) {
    height: 250px;
  }
  .sale_TPM_card_box {
    height: 124px;
    width: 100%;
    margin-top: -30px;
    @media (max-width: 575px) {
      height: 170px;
    }
  }
}
.home_chart_wrapper.manager .card_design.saRe_card_design.card_design_popeline {
  height: 110px;
  @media (max-width: 575px) {
    height: 130px;
  }
}

.home_chart_wrapper.manager .card_design.saRe_card_design:not(.card_design_popeline) .h_details_box {
  min-height: 50px;
}
.card_design_popeline .h_details_box_update .value-resize {
  transform: translateY(-50%) scale(0.65);
  transform-origin: left;
}
.home_chart_wrapper {
  &.manager {
    &.roi-hide {
      .total_sale_wrapper {
        @media (max-width: 575px) {
          width: 100%;
        }
      }
    }
  }
}
.cs_chatboard_uploadDropdown_wrapper {
  margin-left: auto;
}

.commission_setting_tab {
  width: 100%;
  max-width: 100%;
  .inviteUserHeader {
    text-align: center;
    .title {
      text-align: center !important;
      font-size: 26px !important;
    }
    .text {
      font-size: 14px !important;
    }
  }
}
.commission_setting-wrap {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 24px 15px;
  width: 100%;
}

.commission_setting-block {
  width: 50%;
  padding: 0px 10px;
  @media (max-width: 991px) {
    width: 100%;
    padding: 0px 0px;
  }
  &.right {
    position: relative;
    padding-left: 30px;
    @media (max-width: 991px) {
      padding-left: 0px;
      padding-top: 50px;
    }
    &:before {
      @media (max-width: 991px) {
        display: none;
      }
      content: '';
      width: 1px;
      height: 100%;
      background: #efe9e9;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      margin: 0 auto;
    }
  }
  .table_body {
    max-height: 430px;
    overflow: auto;
  }
}
.commission_setting-block .modify-rate {
  width: calc(100% - 160px);
  @media (max-width: 991px) {
    width: 100%;
  }
}
.commission_setting_tab .cs_form_submit_row {
  margin-top: 20px;
}
.help_csm_chat_wrapper .help_csm_chat_footer .help_csm_chat_send_btn {
  padding: 0;
  img {
    width: 22px;
  }
}

.sales_contest_wrapper.new_design.new_design-green .contest_sliderEMP .contest_card_slide {
  background-color: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);

  .contest_card-info {
    background: no-repeat;
    box-shadow: none;
    padding: 16px;
    border-radius: 0;
  }
}

.sales_contest_wrapper.new_design.new_design-green .contest_sliderEMP .sales_contest_box {
  background: #ffffff;
  box-shadow: none;
  border: 2px solid #ebf0ee;
  padding: 15px 10px;
}
.sales_contest_wrapper.new_design.new_design-green .contest_sliderEMP .sales_contest_row {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
}
.sales_contest_wrapper.new_design.new_design-green
  .main_contest_card_box
  .contest_sliderEMP
  .individual_bonuses_t_header {
  margin-bottom: 0;
}

.dropdown_v1 {
  position: relative;
  .dropdown_head {
    background: #ffffff;
    display: flex;
    border: 2px solid #1f2e43;
    border-radius: 3px;

    .dropdown_text {
      font-size: 12px;
      color: #31384f;
      line-height: 19.2px;
      padding: 8px 10px;
      cursor: pointer;
    }
    .dropdown_arrow {
      padding: 5px 8px;
      border-left: 1px solid #31384f;
      min-width: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 5px;
        background: #fff;
        position: absolute;
        top: 0;
        left: -5px;
      }
      &:after {
        content: '';
        width: 10px;
        height: 5px;
        background: #fff;
        position: absolute;
        top: auto;
        left: -5px;
        bottom: 0;
      }
    }
  }
}
.dropdown_list {
  position: absolute;
  top: calc(100% + 10px);
  width: auto;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 4px 8px 36px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 8px 0px;
  z-index: 9;
  @include respond-to('phone-md') {
    border-radius: 10px;
    padding: 10px;
    right: auto;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    li {
      padding: 8px 10px;
      text-align: center;
      border-bottom: 1px solid #dfdfdf;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      button {
        color: #222b2e;
        text-decoration: none;
        font-size: 12px;
        padding: 0;
        white-space: nowrap;
        &:hover {
          color: #206cb4;
        }
      }
    }
  }
}
.ml-10 {
  margin-left: 10px;
}
.header_action_select {
  .customer-selectBox > div {
    border: 2px solid #e6edf1;
    border-radius: 5px;
    font-weight: 400;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .customer-selectBox > div > div:nth-child(2) > span {
    display: none;
  }
  .customer-selectBox * {
    white-space: pre;
  }
}
.header_action_select {
  margin-left: 10px;
  margin-right: 20px;
}

.customTable_V1 {
  overflow: auto;
  .customTable {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        td {
          color: #222b2e;
          font-size: 12px;
          font-weight: 400;
          padding: 15px 20px;
          background: #f6f8fa;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        &:nth-child(even) {
          background-color: #f8f9fb;
        }
      }
    }
    tbody tr td {
      padding: 15px 20px;
      color: #222b2e;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      border-bottom: 2px solid #f5f6fa;
    }
  }
  .toggleTable {
    width: 90%;
    border-collapse: collapse;
    margin: 0 auto;
    border: 2px solid #f5f6fa;
    thead {
      tr {
        td {
          color: #222b2e;
          font-size: 12px;
          font-weight: 400;
          padding: 15px 20px;
          background: #f6f8fa;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
  .blue_text {
    color: #2775bd;
    cursor: pointer;
  }
}
.chipTbl {
  padding: 4px 8px;
  display: inline-block;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.04em;
  &__blue {
    background:#dae8f3;
    color: #2877ba;
  }
  &__grren {
    background: rgba(67, 184, 134, 0.2);
    color: #43b886;
  }
  &__red {
    background: rgba(239, 62, 109, 0.2);
    color: #ff445a;
  }
  &__yellow {
    background: rgba(255, 164, 18, 0.2);
    color: #ffa412;
  }
}
.lead-page .lead-card {
  padding: 35px 25px;
}
.tbl_select {
  .customer-selectBox > div {
    border: 2px solid #e6edf1;
    border-radius: 5px;
    font-weight: 400;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .customer-selectBox > div > div:nth-child(2) > span {
    display: none;
  }
  .customer-selectBox * {
    white-space: pre;
  }
}

.customTable_header_V1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 20px;
  .left {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .customer-selectBox {
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
  }
  .right {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .customer-selectBox {
    > div {
      border: 2px solid #e6edf1;
      border-radius: 5px;
      font-weight: 400;
      color: #000;
      font-family: 'Roboto';
      font-size: 14px;
    }
    > div > div:nth-child(2) > span {
      display: none;
    }
    * {
      white-space: pre;
    }
  }
  .search-box {
    width: 300px;
    max-width: 100%;
    margin-bottom: 0;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    input {
      padding: 9px 16px 9px 32px;
      &.fcw_value {
        border: 2px solid #206cb4;
      }
    }
  }
  .extra_icon_wrap {
    &.search {
      position: relative;
    }
    img {
      position: absolute;
      top: 12px;
      left: 12px;
    }
    .cs_form_label {
      color: #b0b8c5;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0;
      padding: 0 5px;
      width: auto;
      position: absolute;
      top: 11px;
      left: 30px;
      transition: 0.5s;
      pointer-events: none;
    }
    input.fcw_value + label {
      top: -7px;
      background-color: #fff;
      z-index: 2;
      font-size: 12px;
      color: #206cb4;
    }
  }
}

.customTable_btn {
  background: #206cb4;
  color: #fff;
  padding: 8px 16px;
  line-height: 19.2px;
  font-size: 12px;
  border-radius: 6px;
  transition: 0.5s;
  margin-left: 15px;
  &:hover {
    background-color: #3d85ca;
  }
}
.customTable_V1 .text-center {
  text-align: center;
}
.actionBtn-space {
  display: inline-block;
  min-width: 20px;
  .actionBtn {
    width: 20px;
    display: inline-block;
    cursor: pointer;
  }
}
.cs_modal_v1 {
  .inner_box {
    width: 520px;
    padding: 40px;
    @include respond-to('phone-md') {
      padding: 40px 15px;
    }
    &:before {
      height: 13px;
      display: inline-block;
      content: '';
      background: #2775bd;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      border-radius: 16px 16px 0px 0px;
      @include respond-to('phone-md') {
        border-radius: 8px 8px 0px 0px;
      }
    }
  }
  .modal-header {
    margin-bottom: 40px;
    .modal-head-inner {
      display: flex;
      align-items: center;
      .modal-head-icon {
        padding-right: 10px;
      }
      .modal-head-titles {
        .modal-title {
          margin-bottom: 0px;
          font-size: 24px;
        }
      }
    }
  }
}
.btn_green_v1 {
  background: #36ce8d;
  color: #ffffff;
  font-weight: 700;
  border-radius: 5px;
  padding: 9px 34px;
  line-height: 22px;
  letter-spacing: 0.04em;
  font-size: 14px;
  font-family: 'Roboto';
  transition: 0.5s;
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    background: #27ae75;
  }
  .process {
    left: unset;
    position: relative;
    transform: none;
    top: unset;
    margin-right: 6px;
  }
}
input.btn_green_v1 {
  border: 0;
}
.modal-footer {
  gap: 15px;
  padding: 6px 0px;
}
.modal-footer-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_input_blue {
  .extra_icon_wrap {
    position: relative;
    input {
      position: relative;
      z-index: 2;
      background-color: transparent;
      transition: 0.5s;
      border: 2px solid #206cb4;
    }
    label {
      color: #222b2e;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0;
      padding: 0 5px;
      width: auto;
      position: absolute;
      top: 10px;
      left: 10px;
      transition: 0.5s;
    }
    .fcw_value + label {
      top: -7px;
      background-color: #fff;
      z-index: 2;
      font-size: 12px;
      color: #206cb4;
    }
  }
  .cs_form_row {
    margin-bottom: 20px;
  }
}

//Action pages

.action-header {
  padding: 15px 20px 20px;

  &_top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;

    &__left {
      h3 {
        font-size: 22px;
        letter-spacing: 0.04em;
        color: #2775bd;
        margin-bottom: 0;
        line-height: 35px;
      }
    }
    &__right {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      align-items: center;
    }
    .action_item .action_item_link {
      font-size: 14px;
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      text-decoration: none;
      color: #222b2e;
      img {
        margin-right: 7px;
        width: 20px;
      }
    }
  }
}
.action-intrest {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  &__left {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
.underline {
  text-decoration: underline !important;
}
.action_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  border: 2px solid #e6edf1;
  border-radius: 6px;
  color: #222b2e;
  &__icon {
    display: inline-flex;
  }
  &__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  &.active {
    background-color: #36ce8d;
    color: #ffffff;
  }
}

.interested_btn {
  border: 1px solid #E6EDF1;
  border-radius: 3px;
  padding: 10px 10px;
  font-size: 12px;
  color: #222B2E;
  letter-spacing: 0.48px;
  font-weight: 400;
  font-family: 'Roboto';
  background: rgba(54, 206, 141, 0);
}
.action-tab-wrap {
  // border-top: 2px solid #f5f6fa;
  background: #ffffff;
  // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 12px 12px rgba(0, 0, 0, 0.05);
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .action-tab__right {
    min-width: 95px;
  }
}
.blue_gradient {
  background: linear-gradient(247.66deg, #27a8f0 -6.74%, #2775bd 104.86%);
}
.text-white {
  color: #ffffff;
}
.action-drop-btn {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 20px;
  border-radius: 6px;
  img {
    padding: 0px 4px;
  }
}
.action-tab_list {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 44px;
    @include respond-to('phone-md') {
      gap: 10px 20px;
    }
    li {
      .action-link {
        text-decoration: none;
        color: #222b2e;
        font-size: 14px;
        font-family: 'Roboto';
        letter-spacing: 0.04em;
        position: relative;
        &::after {
          content: '';
          width: 24px;
          display: inline-block;
          position: absolute;
          height: 3px;
          bottom: -5px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background: rgba(39, 117, 189, 0.3);
          border-radius: 5px;
          opacity: 0;
        }
        &.active {
          color: #2775bd;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.tab_contant__actions {
  padding: 40px;
}
.tab_contant__contact {
  padding: 40px;
}
.customerDetailsBox-wrap {
  padding: 35px 25px;
  background: #f6f8fa;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 70px 0px;
  @include respond-to('phone-md') {
    gap: 15px 0px;
  }
  .customerDetailsBox {
    width: calc(100% / 3);
    padding: 10px;
    @include respond-to('phone-xl') {
      width: calc(100% / 2);
    }
    @include respond-to('phone-md') {
      width: calc(100%);
    }
    .title {
      color: #222b2e;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .value {
      color: #2775bd;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
}
.backtop {
  padding-bottom: 20px;
  img {
    transform: rotate(90deg);
  }
}
.relative {
  position: relative;
}
.actiondrop_list {
  position: absolute;
  top: calc(100% + 10px);
  width: 194px;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 4px 8px 36px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px 12px 12px;
  gap: 8px;
  ul {
    padding: 0;
    list-style: none;
    width: 100%;
    margin: 0;
    li {
      padding-bottom: 8px;
      button {
        text-decoration: none;
        color: #59626b;
        font-size: 12px;
        line-height: 19.2px;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        padding: 5px;
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.tab_contant__notes {
  height: 530px;
}
.chatBox__notes {
  height: 100%;
  position: relative;
  .chatContant {
    height: calc(100% - 73px);
    padding: 32px 32px;
    overflow-y: scroll;
  }
  .chatMsg {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    .text {
      background-color: #276cbd;
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      border-radius: 5px;
      padding: 6px 15px;
      width: auto;
      max-width: calc(100% - 100px);
    }
    .time {
      color: #222b2e;
      font-size: 10px;
      font-weight: 400;
      margin-right: 10px;
      position: relative;
      top: -3px;
    }
  }
  .chatFooter {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 16px 24px;
    border-top: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 40px;
      width: calc(100% - 80px);
      border: none;
      box-shadow: unset;
    }
    .submitBtn {
      background-color: #36ce8d;
      color: #ffffff;
      border-radius: 5px;
      padding: 11px 20px;
      font-weight: 600;
      border: 1px solid #36ce8d;
      transition: 0.5s;
    }
  }
}
.tab_contant__transactions,
.tab_contant__actionsnew {
  padding: 30px;
}
.transactions_wrap {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  .transactions_sales,
  .transactions_pending {
    width: calc(100%);
    @include respond-to('phone-xl') {
      width: calc(100%);
    }
  }
}
.customTable_V1 {
  .tableProfile_wrap {
    display: flex;
    align-items: center;
    .profile_name {
      padding-left: 15px;
    }
    .tableProfile {
      width: 48px;
      height: 48px;
    }
  }
}
.customTable_V1 .customTable.pendingTable tbody tr td,
.customTable_V1 .customTable.pendingTable thead tr td {
  padding: 8px 20px;
}
.customTable thead p {
  margin-bottom: 0;
}
.salesTable td {
  vertical-align: top;
}
.personal-drop {
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  margin-top: 10px;
}
.text-green {
  color: #43b886;
}

// C
.cs_modal_v1 {
  &.model-m-410 {
    .inner_box {
      width: 410px;
      padding: 40px 20px 20px;
    }
    .connected-wrap {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 17px;
    }
    .connected-wrap {
      max-width: 207px;
      margin: 0 auto;
      width: 100%;
    }
    .modal-footer {
      margin-top: 20px;
    }
  }
  .sub_title {
    font-size: 14px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 22px;
  }
}
.btn_blue_gradient {
  background: linear-gradient(247.66deg, #27a8f0 -6.74%, #2775bd 104.86%);
  color: #ffffff;
}
.btn_yellow_gradient {
  background: linear-gradient(263.34deg, #ffc943 -7.41%, #f99f42 115.39%);
  color: #ffffff;
}
.btn_gray_text {
  font-size: 14px;
  color: #939ea3;
}
.btn_lg {
  padding: 16px 28px;
  border-radius: 10px;
  font-size: 14px;
}
.btn_green {
  background-color: #36ce8d;
  color: #ffffff;
}
.flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.text-center {
  text-align: center;
}
.pt-15 {
  padding-top: 15px;
}

// Schediuid

.model-m-635 {
  .inner_box {
    width: 655px;
    padding: 40px 30px 40px;
  }
}
.title_modal_v1 {
  font-size: 24px;
  margin-bottom: 24px;
}
.row_modal {
  display: flex;
  margin-bottom: 28px;
  .row_modal_field {
    .cs_form_row {
      margin-bottom: 0;
    }
  }
  .row_modal_title {
    min-width: 100px;
    font-size: 14px;
    padding-top: 12px;
  }
}
.cs_form_row_lbl-hide .cs_form_label {
  display: none;
}
.row_modal_field {
  width: 100%;
}
.cs_form_row_blue {
  input {
    border-color: #206cb4;
    color: #206cb4;
    &::-webkit-input-placeholder {
      color: #206cb4;
    }
  }
}
.row_modal_field_items {
  display: flex;
  gap: 10px;
}

.select_blue {
  .package_cs_select_box > div > div:first-of-type {
    border: 2px solid #206cb4;
  }
  .package_cs_select_box > div > div:first-of-type div svg {
    color: #206cb4;
  }
  .package_cs_select_box > div > div:first-of-type > div:first-of-type {
    padding: 0px 0px 0px 10px;
  }
}
.min-w-112px {
  min-width: 112px;
}
.min-w-124px {
  min-width: 124px;
}
.min-w-70px {
  min-width: 70px;
}
body .cs_form_row_blue {
  .cs_form_label {
    display: none;
  }
  .TextAreaInputBlue {
    border: 2px solid #2376c4;
    color: #206cb4;
    height: 82px;
    font-weight: 400;
    &::placeholder {
      color: #2376c4;
    }
  }
}
.row_modal .ff_notification_btn_wrapper {
  padding-top: 5px;
  width: 50px;
}

.timepicker input {
  padding: 9px 16px 9px 16px;
  background-color: $white;
  height: 39px;
}

.modal_people {
  width: 100%;
}

.sib {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 30px;
  .sib__inner {
    display: flex;
    gap: 12px;
    align-items: self-start;
    .company {
      width: 45%;
    }
    .deals-size-wrap {
      display: flex;
      align-items: self-start;
      gap: 10px;
      width: 55%;
      .deal_size {
        width: calc(100% - 46px);
      }
    }
  }

  .package_cs_select_box > div > div:first-of-type {
    border: 2px solid #2775bd;
  }

  .deals-size-wrap input {
    border: 2px solid #2775bd;
    border-radius: 5px;
    height: 40px;
    padding: 5px 10px;
    box-sizing: border-box;
    color: #222b2e !important;
    width: 100%;
    font-weight: 300;
    &::-webkit-input-placeholder {
      color: #222b2e;
    }
  }
  .btn-vpl {
    width: 36px;
    border: 2px solid #edefef;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    height: 40px;
    border-radius: 6px;
    min-width: 36px;
  }
}
.modal-head-titles {
  width: 100%;
}

.add_new_formNewsm {
  .package_cs_select_box > div > div:first-of-type {
    border: 2px solid #2775bd;
  }
}
.modal_people {
  .cs_form_label {
    display: none;
  }
  .doubleF_wrapper {
    margin-bottom: 20px;
  }
}

.deg-45 {
  transform: rotate(45deg);
}

.mb-0 {
  margin-bottom: 0 !important;
}

.proposal-modal {
  .cs_form_label {
    display: none;
  }
  .modal-header {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 575px) {
  .site_header {
    position: sticky;
  }
  .free-trial-bar {
    position: sticky;
  }
  body {
    padding-top: 0px;
  }
  .free-trial-bar-active .main_wrapper .rightC_wrapper {
    padding-top: 20px;
  }
  .free-trial-bar-active .main_wrapper .rightC_wrapper.cs_chatboard {
    padding-top: 20px;
  }
  .main_wrapper .rightC_wrapper.cs_chatboard {
    padding: 20px 0px 0px;
  }
}

.date_time .select_blue {
  width: 60%;
}

.timepicker {
  width: 100%;
}
body .rc-time-picker-clear {
  top: 9px;
}
.free-trial-bar-active .mt-30 {
  margin-top: 86px;
}
.submit-btn-with-process .process {
  z-index: 9;
}
.cs_moda_main_wrapper {
  &.select_bonus_type {
    .inner_box {
      width: 780px;

      .custom_checkbox_row {
        padding-left: 70px;
        margin-bottom: 0;
      }
    }
  }
}

.cs_moda_main_wrapper {
  &.addNew_bonus {
    .inner_box {
      width: 780px;

      .custom_checkbox_row {
        padding-left: 70px;
        margin-bottom: 0;
      }
    }
  }
}

.action-card {
  min-height: 70vh;
}
.text-blue {
  color: #206cb4;
}
.circle_tbl {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-right: 6px;
}
.circle_tbl__green {
  background: #43b886;
}
.circle_tbl__red {
  background: #ff445a;
}
.circle_tbl__orange {
  background: #ffa412;
}
.circle_tbl_wrap {
  display: flex;
  align-items: center;
}

// .customTable_V1 .customTable tbody tr:nth-child(4n - 1) {
//   background: #f5f6fb;
// }

// .customTable_V1 .customTable .customTable tbody tr:nth-child(4n - 1) {
//   background: #ffffff;
// }
.customTable_V1__odd {
  .customTable tbody tr:nth-child(4n - 1) {
    background: #fafbfd;
  }
  .customTable .customTable tbody tr:nth-child(4n - 1) {
    background: #ffffff;
  }
}
.customTable_V1__nonone .customTable tbody tr:nth-child(even) {
  background: #fafbfd;
}
.customTable_V1 .salesTable tbody tr:nth-child(even) {
  background: #fafbfd;
}
.customTable_V1__odd .customTable .customTable tbody tr:nth-child(even) {
  background: #fafbfd;
}

.filed_wrap {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  .lbl_filed {
    display: inline-block;
    color: #206cb4;
    padding-right: 10px;
  }
}
.cs_modal_v1 .modal-header_20 {
  margin-bottom: 20px;
}
.card_CTM {
  height: 110px;
}
.employee_dashboard_page .saRe_card_wrapper__YTD .sale_TPM_card_box {
  height: 120px;
}
.cs_moda_main_wrapper {
  &.new-contest {
    .inner_box {
      width: 700px;
    }
  }
}
.adding-bonus.cs_moda_main_wrapper.new-contest .inner_form_wrapper {
  width: 100%;
}
.contest-checkboxWrap {
  display: flex;
  flex-wrap: wrap;
  .contest-checkbox {
    width: 50%;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    &.cs_radio_checkbox_wrapper {
      padding-left: 18px;

      @media screen and (max-width: 575px) {
        padding-left: 0px;
      }
    }
  }
}
.adding-bonus.cs_moda_main_wrapper.new-contest .wm-wrapper {
  width: 100%;
}

//

.cs_moda_main_wrapper {
  &.adding-bonus-new {
    .inner_box {
      width: 700px;
    }
  }
}

.adding-bonus.cs_moda_main_wrapper.adding-bonus-new .wm-wrapper {
  width: 100%;
}

.cs_form_double_row {
  &.full {
    .cs_form_row {
      width: 100%;
    }
  }
}
.custom_checkbox_row-bonues {
  width: 50%;
  padding-left: 18px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
}
.custom_checkbox_row-bonues .cs_form_row {
  margin-left: 0 !important;
  padding-left: 26px !important;
  width: 100%;
}
.action-page .backtop button {
  color: #2775bd;
}
@media (max-width: 1199px) {
  .emsp_table_data .csp_table_wrapper .profile_img img {
    width: 40px;
    height: 40px;
  }
}
.emsp_table_data-manager {
  th.profile_img {
    position: relative;
    &:before {
      content: 'Leaderboard';
      position: absolute;
      font-weight: 400;
      font-size: 22px;
      color: #222b2e;
      margin: 0px;
      margin-bottom: 0;
      top: 8px;
      left: 20px;
    }
  }
  .upcoming_header {
    display: none;
  }
}

.commission_factored_modal {
  &.cs_moda_main_wrapper {
    .inner_box {
      width: 640px;
    }
  }
}

.free-trail-btn {
  font-size: 14px;
  color: #31384f;
  line-height: 19.2px;
  padding: 8px 10px;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
  border: 1px solid #31384f;
  border-radius: 8px;
}

.lead-page {
  .circle_tbl {
    width: 8px;
    height: 8px;
  }
}

.table_row_new {
  .confirm_btn {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    border-radius: 5px;
    padding: 5px 18px;
    transition: 0.5s;
    margin-left: 6px;
    background-color: #27ae75;
  }
  .delete_btn img {
    width: 22px;
  }
  .table_rowLS {
    display: flex;
    align-content: start;
  }
}

.bulkImport-btn_unique {
  background-color: #206cb4;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  padding: 8px 15px;
  margin-bottom: 0;
  letter-spacing: 0.04em;
  min-width: 126px;
  img {
    margin-right: 10px;
  }
}

.addCustomer-form.addCustomer-form-upload .upload-file-img {
  width: 100%;
  margin-bottom: 15px;
}
.adding-customer-modal .upload-file-img {
  width: 100%;
  margin-bottom: 10px;
}
.adding-customer-modal .addCustomer-form .upload-file-img {
  margin-top: 0;
}

.proposal-modal {
  .sib .btn-vpl {
    width: 40px;
    border: 0px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    height: 40px;
    min-width: 36px;
    border-radius: 100%;
    background: rgba(39, 117, 189, 0.3);
  }
}
.modal-footer-col {
  flex-direction: column;
  .modal-footer-col {
    margin-top: 15px;
  }
}
.add-deal-title-14 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 50px;
}

.proposal-modal .add_new_formNewsm .package_cs_select_box > div > div:first-of-type {
  border: 2px solid #525e6b;
}
.proposal-modal .sib .deals-size-wrap input {
  border: 2px solid #525e6b;
}
.proposal-hover {
  cursor: pointer;
}
.action_show {
  opacity: 0;
}
.proposal-hover:hover .action_show {
  opacity: 1;
}
.action_show {
  .won_btn {
    display: inline-block;
    background-color: #43b886;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 8px;
    transition: 0.5s;
    margin-right: 6px;
    &:hover {
      background-color: #27ae75;
    }
  }
  .lost_btn {
    display: inline-block;
    background: #e2e9ef;
    color: #59626b;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 8px;
    transition: 0.5s;
    &:hover {
      background-color: #59626b;
      color: #ffffff;
    }
  }
  .edit_btn {
    margin-right: 8px;
    img {
      display: inline-block;
      width: 15px;
    }
  }
}

.action_show_inner {
  display: flex;
  align-items: center;
  .sale-info {
    color: #36ce8d;
    font-weight: 500;
    padding-right: 40px;
    text-transform: capitalize;
  }
}
.tab_contant__notes__action {
  .chatBox__notes {
    .chatMsg {
      margin-bottom: 20px;
      flex-direction: row;
      gap: 12px;
      .text {
        font-size: 14px;
        line-height: 16px;
        background-color: transparent;
        color: #276cbd;
        padding: 0 0px 0 0;
      }
      .img_msg img {
        width: 100px;
        max-width: 100%;
      }
      .chat_upload_file_name {
        background-color: #276cbd;
        color: #ffffff;
        font-weight: 400;
        border-radius: 8px 0px 8px 8px;
        padding: 8px 8px;
        font-size: 14px;
        text-align: center;
        width: auto;
        max-width: 100%;
        .inner_wrapper {
          .file_name_text {
            margin-bottom: 0;
          }
          img {
            display: inline-block;
            width: 40px !important;
            filter: grayscale(1) brightness(10);
            border-radius: 0px !important;
          }
        }
      }
    }

    .chatM_upload_IV_box {
      position: absolute;
      bottom: 71px;
      left: 0px;
      right: 0px;
      width: 100%;
      .chatM_upload_IV_box_inner {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .img_cover {
        padding-bottom: 10px;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 200px;
          object-fit: contain;
          display: inline-block;
          padding: 20px;
          border-radius: 30px;
        }
        .upload_file_name {
          text-align: center;
          background-color: #206cb4;
          padding: 10px;
          color: #ffffff;
          border-radius: 10px;
          img {
            padding: 0px;
            border-radius: 10px;
            width: 80px;
            filter: grayscale(1) brightness(10);
          }
        }
        .file_text {
          margin-bottom: 0px;
          margin-top: 2px;
        }
      }
      .chatM_upload_IV_btn {
        width: 26px;
        height: 26px;
        background-color: #ffffff;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 20px;
        font-weight: 700;
        transform: rotate(45deg);
        font-size: 18px;
        box-shadow: 1px 1px 6px #b9b9b9;
      }
    }
  }
}
.action-page {
  .action-tab__right {
    .action-drop-wrap {
      text-align: right;
    }
  }
}
.transactions_sales_btn {
  position: relative;
}
.connect-tbl-btn {
  position: absolute;
  top: 7px;
  right: 10px;
  .interested_btn {
    border: 2px solid #000000;
    border-radius: 3px;
    padding: 6px 10px;
    font-size: 12px;
    color: #000000;
    background: #ffffff;
  }
}

.file-tbl {
  .selected-file-name {
    font-size: 12px;
    color: #2775bd;
  }
  .help_csm_chat_uploadpin_btn {
    margin: 0;
    padding: 0;
    position: relative;
    img {
      filter: grayscale(1) brightness(1);
    }
  }
  .selected-file-container {
    padding-right: 27px;
    &:hover {
      .close-button {
        opacity: 1;
      }
    }
  }
  .close-button {
    color: #ef3e6d;
    position: absolute;
    font-size: 18px;
    top: -5px;
    right: 0;
    opacity: 0;
  }
}
.btn_white-border {
  border: 2px solid #000000;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 12px;
  color: #000000;
  // background: linear-gradient(262deg, #fff -14.54%, #fff 114.3%);
  background: #ffffff;
  letter-spacing: 0.52px;
  font-weight: 400;
  font-family: 'Roboto';
}
.right-action {
  text-align: right;
  top: 38px;
  right: 8px;
}
.action-header_top__right {
  .cs_chatboard_uploadDropdown_wrapper .help_csm_chat_uploadpin_btn {
    height: initial;
  }
  .cs_chatboard_uploadDropdown_wrapper .cs_chatboard_uploadDropdown {
    bottom: auto;
    width: 160px;
    right: 0;
    top: 100%;
    padding: 10px;
    overflow: hidden;
    z-index: 9;
  }
  .cs_chatboard_uploadDropdown_wrapper
    .cs_chatboard_uploadDropdown
    .cs_chatboard_uploadD_item
    .cs_chatboard_uploadD_itemI:hover {
    border-radius: 0;
  }
  .cs_chatboard_uploadDropdown_wrapper .cs_chatboard_uploadDropdown .cs_chatboard_uploadD_itemI {
    align-items: center;
    img {
      margin-right: 12px;
      width: 18px;
      height: 18px;
    }
  }
}

.highlights_post_box {
  position: relative;
  .editTime.cursor {
    position: absolute;
    right: 10px;
    top: 13px;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    .editTime.cursor {
      visibility: visible;
      opacity: 1;
    }
  }
}

.pipe_title {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-right: 20px;
}
.select-blue {
  .package_cs_select_box > div > div:first-of-type {
    border: 2px solid #206cb4;
  }
  svg {
    fill: #206cb4;
  }
}

.tab_contant__notes__action {
  .chatBox__notes .chatFooter .submitBtn {
    margin-left: 10px;
  }
}

.package_cs_select_box * {
  font-weight: 400;
}

.contacts_row {
  .action_row__item {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  &:hover {
    .action_row__item {
      opacity: 1;
      visibility: visible;
    }
  }
}

.proposal-hover .action_show_inner .table_row_new .delete_btn img {
  width: 15px;
}
.proposal-hover .action_show .edit_btn {
  margin-left: 8px;
  margin-right: 0px;
}
.cs_modal_v1 .modal-header-space-30 {
  margin-bottom: 30px !important;
}
.nav-tabs-sub {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 10px;
}
.nav-tabs-sub li .nav-item {
  border: 1px solid #e8e9ed;
  padding: 8px 15px;
  font-size: 12px;
  font-family: 'Roboto';
  display: inline-block;
  color: #222b2e;
  border-radius: 6px;
}
.nav-tabs-sub li.nav-list.active .nav-item {
  background: #2775bd;
  accent-color: #2775bd;
  color: #fff;
}
.nav-tabs-sub-wrap {
  width: 100%;
  overflow: auto;
  margin-bottom: 30px;
}
.cs_form_double_row {
  &.cs_form_double_row_four {
    display: block;
    flex-wrap: wrap;
    margin-left: 0px;
    margin-right: 0px;
    .cs_form_submit_row {
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0px;
    }
    .cs_form_six_row .cs_form_row {
      @media (min-width: 1199px) {
        width: calc(100% / 4);
      }
    }
  }
}

/* PipeLine*/
.pipeline_box {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 16px 0 rgba(35, 118, 196, 0.4);
  }
}
.pipeline_top_wrap {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
  gap: 20px 0px;
  margin-bottom: 20px;
}

.pipeline_top_wrap .pipeline_top_col {
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 991px) {
    width: 33.33%;
  }
  @media (max-width: 667px) {
    width: 50%;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
.pipeline_top_wrap {
  .pipeline_box {
    padding: 20px;
    text-align: center;
    height: 100%;
    position: relative;
    .pipeline_title {
      font-weight: 400;
      font-size: 14px;
      color: #222b2e;
      margin-bottom: 20px;
    }
    .pipeline_value {
      font-weight: 300;
      color: #0069b5;
      font-size: 26px;
      @media (max-width: 1279px) {
        font-size: 24px;
      }
    }
  }
}
.pipeline_profile_details {
  text-align: left;
  font-weight: 400;
  color: #2775bd;
  font-size: 14px;
  width: calc(100% - 60px);
  padding-left: 10px;
  white-space: nowrap;
}
.pipeline_profile {
  .inner_wrapper {
    display: flex;
    align-items: center;
  }
}
.pipeline_list_item {
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  transition: all 0.5s;
  &:hover {
    box-shadow: 0 2px 16px 0 rgba(35, 118, 196, 0.4);
  }
  @media (max-width: 991px) {
    overflow: auto;
  }
}
.pipeline_list_block .pipeline_list_title {
  font-weight: 400;
  font-size: 14px;
  color: #222b2e;
  margin-bottom: 10px;
  font-family: 'Roboto';
  white-space: nowrap;
}

.pipeline_list_value {
  font-weight: 300;
  color: #0069b5;
  font-size: 16px;
}
.pipeline_list_wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.sale_data_contact .sale_data_setting-main {
  justify-content: center;
}
.pipeline_profile {
  min-width: 200px;
  cursor: pointer;
}
.pipeline_list_col {
  width: 100%;
}

.circle-main {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  margin: 0 auto;
}

.red-circle {
  background: #fd435c;
}
.green-circle {
  background: #47b789;
}
.orange-circle {
  background: #fea22f;
}
.blue-circle {
  background: #2877ba;
}
body .pipeline-modal .sales_page .left {
  width: 18%;
}
body .pipeline-modal .sales_page .right {
  width: 82%;
}
.deal_modal .pipeline-modal .data_table_wrapper .table_header .company,
.deal_modal .pipeline-modal .data_table_wrapper .table_body .company {
  width: 24%;
}

.deal_modal .pipeline-modal .data_table_wrapper .table_header .dayOfAging,
.deal_modal .pipeline-modal .data_table_wrapper .table_body .dayOfAging {
  width: 11%;
  text-align: center;
}
.table_row_total .table_td {
  color: #222b2e !important;
}

.table_row_total .table_td.company {
  font-weight: bold !important;
}
.table_row.table_row_total:hover {
  background: transparent !important;
}

.table_row.table_row_total {
  margin-top: 10px;
}

.cs_modal_v1 .modal-header-space-30 {
  margin-bottom: 30px !important;
}

.main_wrapper .rightC_wrapper-full {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  .backtop {
    padding-left: 15px;
    padding-right: 15px;
  }
  .action-card {
    margin-bottom: 0;
    min-height: 78vh;
    border-radius: 0;
  }
  .tab_contant__notes {
    height: 62vh;
  }
}
.commission_setting-wrap {
  &.offer-wrap {
    margin-top: 30px;
    flex-direction: column;
    max-width: 50%;

    .commission_setting-block {
      .modify-rate {
        width: 100%;
        font-size: 12px;
      }
    }
  }
}
.commission_setting_tab {
  .offer-wrap {
    .cs_form_submit_row {
      justify-content: center;
      width: 150px;
      .process {
        left: 6px;
      }
    }
    .inviteUserHeader {
      text-align: left;
      color: #222b2e;
      .title {
        text-align: left !important;
        font-size: 26px !important;
        color: #222b2e;
      }
      .text {
        color: #222b2e !important;
      }
    }
    .modify-rate {
      .cs_form_row input {
        margin-top: 0 !important;
        margin-right: 10px !important;
      }
    }
    .commission_setting-block {
      padding: 0px;
    }
  }
}
.setting_page {
  .offer-wrap {
    .cs_form_row input {
      margin-top: 0;
      margin-right: 10px;
    }
  }
}

.profile_image_for_pipeline {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}
.boxGap {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  .boxGap-filed {
    width: 100%;
  }
  .box-btn {
    display: flex;
    min-width: 110px;
    padding-left: 10px;

    button.add-more-btn {
      width: 40px;
      height: 56px;
      border: 2px solid transparent;
      margin-left: 10px;
      border-radius: 8px;
      // background: rgba(81,142,248, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 22px;
      }
    }
  }
}

.commission_setting-block.left button.won_btn.loader_active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commission_setting-block.left button.won_btn.loader_active .process {
  position: relative;
  top: 0;
  left: -3px;
}

/* Super Admin */
.teammate-edit {
  display: flex;
  align-items: center;
}
.teammate-edit button.edit_btn {
  width: 18px;
  height: 18px;
  padding: 0;
  margin-left: 7px;
}

.teammate-edit button.edit_btn img {
  width: 15px;
  display: inline-block;
}
.teammate-edit .table_rowLS {
  opacity: 0;
  pointer-events: none;
}
.customerTableSuperAdmin tr:hover .teammate-edit .table_rowLS {
  opacity: 1;
  pointer-events: all;
}

.customerTableSuperAdmin tr:not(.toggleTR):hover {
  cursor: pointer;
}

.teammate-edit {
  .tbl_datepicker input {
    border: 1px solid #dedede;
    padding: 5px 10px;
    max-width: 120px;
    border-radius: 3px;
    margin-right: 4px;
  }
  button.won_btn {
    display: inline-block;
    background-color: #43b886;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 8px;
    transition: 0.5s;
    margin-right: 6px;
    position: relative;
  }

  button.lost_btn {
    display: inline-block;
    background: #e2e9ef;
    color: #59626b;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 8px;
    transition: 0.5s;
  }
  .tbl_datepicker + .table_rowLS {
    opacity: 1;
    pointer-events: all;
  }
  button.won_btn.loader_active {
    padding-left: 23px;
  }
}
/* Super Admin */

body .download-error-modal .inner_box {
  padding: 40px;
}

.error-data {
  margin-bottom: 10px;
}
.pipeline_list_wrap_main {
  margin-left: -15px;
  margin-right: -15px;
}

.pipeline_list_wrap_main .infinite-scroll-component {
  padding: 15px;
}
.deal_modal-changes {
  .inner_box {
    min-height: 450px;
  }
}
.lead_space_right {
  .customTable_V1.customTable_V1__odd {
    margin-right: -25px;
  }
  .customTable_V1 .customTable thead tr td:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// Goals Setting
.goals_setting_header {
  padding: 24px 34px 34px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.annual_sales_box {
  background: #fafbfd;
  border-radius: 16px;
  display: inline-block;
  padding: 13px 30px;
  min-width: 96px;
  max-width: 100%;
  .label {
    color: #222b2e;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    font-family: Roboto;
    line-height: 160%; /* 22.4px */
    letter-spacing: 0.56px;
  }
  .amount {
    color: #206cb4;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 35.2px */
    letter-spacing: 0.88px;
    margin-bottom: 0;
  }
}
.goals_setting_header_left {
  h2 {
    color: #2775bd;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 32px */
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
  p {
    color: #222b2e;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.48px;
    margin-bottom: 0;
    opacity: 0.8;
  }
}
.goals_setting_inner {
  padding: 0 34px 20px 40px;
  .card__header__two {
    max-width: 738px;
    padding-left: 25px;
  }
}
.gls_profile_col {
  min-width: 180px;
  width: 180px;
  background: #ffffff;
  position: sticky;
  left: 0;
  z-index: 9;
}
.gls_profile_wrap {
  display: flex;
  align-items: center;
  .gls_profile_img {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    .tableProfile {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .gls_profile_name {
    text-align: left;
    font-weight: 400;
    color: #2775bd;
    font-size: 12px;
    width: calc(100% - 60px);
    padding-left: 10px;
    white-space: nowrap;
  }
}
.gls__input {
  input {
    border-radius: 5px;
    color: #222b2e;
    font-size: 12px;
    font-weight: 300;
    padding: 2px 4px;
    width: 100%;
    text-align: center;
    border: 2px solid #e6edf1;
    height: 36px;
    cursor: pointer;
  }
  .cs_form_label {
    margin-bottom: 0;
  }
  .input_values {
    border-radius: 5px;
    color: #222b2e;
    font-size: 12px;
    font-weight: 300;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    border: 2px solid #e6edf1;
    height: 36px;
    cursor: pointer;
  }
}
.goals__setting__tbl {
  display: flex;
  align-items: start;
  overflow: auto;
  max-height: 485px;
  border-radius: 16px;
}
.goals-admin .goals__setting__tbl {
  max-height: 350px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.goals__setting__tbl {
  scrollbar-width: auto;
  scrollbar-color: #b8d4c7 #ffffff;
}

/* Chrome, Edge, and Safari */
.goals__setting__tbl::-webkit-scrollbar {
  width: 16px;
}

.goals__setting__tbl::-webkit-scrollbar-track {
  background: #ffffff;
}

.goals__setting__tbl::-webkit-scrollbar-thumb {
  background-color: #b8d4c7;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.gls_profile_row {
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  &.gls_profile_row__title {
    margin-bottom: 0;
    height: 34px;
    padding-bottom: 7px;
    position: sticky;
    top: 0;
    background: #ffffff;
  }
}
.gls_month_row {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  &.gls_month__title {
    margin-bottom: 0;
    position: sticky;
    top: 0;
    background: #ffffff;
    .gls_month_col {
      padding-top: 14px;
      padding-bottom: 7px;
      height: auto;
    }
  }
  .title {
    color: #2775bd;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    display: block;
  }
}
.gls_month_row_wrap {
  // overflow: auto;
  // width: calc(100% - 270px);
  .gls_month_col {
    min-width: 100px;
    height: 42px;
    padding-top: 4px;
    width: 100px;
    &:last-child {
      margin-right: 12px;
    }
  }
}
.gls__annualGoal__col {
  min-width: 90px;
  border-radius: 16px 16px 16px 0px;
  background: #edf8f3;
  position: sticky;
  right: 0;
}

.gls__annualGoal__row {
  margin-bottom: 31.5px;
  color: #222b2e;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
  letter-spacing: 0.4px;
  min-height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.gls__annualGoal__row__title {
    width: 70px;
    margin: 0 auto;
    font-weight: 500;
    height: auto;
    line-height: normal;
    min-height: auto;
    padding-top: 9px;
    padding-bottom: 3px;
    position: sticky;
    top: 0;
    background: #edf8f3;
  }
  &.gls__annualGoal__row__total {
    margin-bottom: 0;
    background: #ddf2e8;
    border-radius: 0px 0px 16px 0px;
    height: 62px;
    position: sticky;
    bottom: 0;
  }
}

.gls__annualGoal__row:last-child {
  margin-bottom: 0;
  background: #ddf2e8;
}
.goals__setting__bottom {
  border-radius: 16px 0px 16px 16px;
  background: #edf8f3;
  padding: 10px 0px;
}
.company_monthly_title span {
  color: #222b2e;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: 0.4px;
  display: flex;
  max-width: 116px;
  height: 40px;
  align-items: center;
}
.company_monthly_title {
  min-width: 180px;
  width: 180px;
  position: sticky;
  left: 0;
  padding-left: 26px;
}
.goals__setting__bottom__wrap {
  display: flex;
  align-items: start;
  overflow: auto;
}
.gls_month_total__wrap {
  overflow: auto;
  width: calc(100% - 270px);
}
.gls_month_total__row {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
}
.gls_month_total__col {
  min-width: 100px;
  height: 42px;
  padding-top: 4px;
  width: 100px;
  color: #222b2e;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company_sales_goal {
  border-radius: 16px 0px 0 16px;
  position: sticky;
  bottom: 0;
  background: #edf8f3;
  padding: 11px 26px;
  .cmsg_bottom_title {
    color: #222b2e;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.4px;
    display: flex;
    max-width: 116px;
    height: 40px;
    align-items: center;
  }
}
.gls_month_row {
  &.gls__monthly__bottom {
    background: #edf8f3;
    padding: 10px 0px;
    position: sticky;
    bottom: 0;
    .gls_month_col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .gls__monthly__total__lbl {
      color: #222b2e;
      text-align: center;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
// .card_goals__setting {
//   min-height: 89vh;
// }
.gaolsInputNumber {
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.goal_setting {
  text-align: right;
  margin-bottom: 14px;
  &_icon {
    cursor: pointer;
  }
}

// Header Search DropDown

.search_box__dropdown {
  //  display: none;
  border-radius: 16px;
  border: 0px solid #edefef;
  background: #fff;
  width: 100%;
  padding: 8px 0px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  .search_box__dropdownList {
    list-style: none;
    padding: 0px 0px;
    margin: 0;
    max-height: 260px;
    scrollbar-width: auto;
    scrollbar-color: #b8d4c7 #ffffff;
    overflow-y: auto;
    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b8d4c7;
      border-radius: 10px;
      border: 1px solid #ffffff;
    }

    .search_box_title {
      color: #939ea3;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.48px;
      margin-bottom: 2px;
    }
    .search_box_item {
      color: #222b2e;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .search_box__dropdownListItem {
      padding: 12px 12px 12px 34px;
      border-bottom: 2px solid #f5f6fa;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

body .cs_form_row_black {
  margin-bottom: 20px;
  input {
    border-color: #5f5757;
    color: #000000;
  }
  .custom_date_field img {
    filter: saturate(0%) brightness(70%) contrast(1000%);
  }
}

.dealModal_header_pipeline {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.dealModal_header_pipeline .user_name {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.deal_modal .dealModal_header.dealModal_header_pipeline .left_profile_details .pf_detail {
  padding-left: 0;
}

.pipeline_skel {
  position: relative;
  height: 88px;
  margin-bottom: 20px;
  max-width: 98%;
  margin-left: 1%;
  display: none;
}
.skeletonLoader_active {
  .pipeline_skel {
    display: block;
  }
}
.add-saleTeam-form.goals-admin {
  width: 100% !important;
}
.left-cn.goals-admin-parent {
  max-width: 100% !important;
}
.goals-admin .gls_profile_col {
  width: 15%;
}
.dashboard-setting {
  max-width: 1700px;
  margin: 0 auto;
}

.add-sales-team-modal .add_sales-cn-wrapper .left-cn .add-setting-admin-changes .cs_form_row {
  width: 17%;
  margin-bottom: 60px;
}

.add-setting-admin-changes {
  .modify-rate-lbl {
    max-width: none !important;
    display: inline-flex;
    cursor: pointer;
    input {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }
    p {
      margin-top: 3px !important;
      padding-left: 4px;
    }
  }
}

.dealModal_header_pipeline {
  &::after {
    background-image: url(images/lineGrainat.svg);
    content: '';
    width: 100%;
    display: block;
    height: 2px;
    bottom: -10px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.pipeline-modal .table_row_total {
  border-radius: 5px;
  background: #f6f8fa !important;
  padding: 14px 0px !important;
  position: sticky !important;
  bottom: 0;
}
.pipeline-modal .table_row.table_row_total:hover {
  background: #f6f8fa !important;
}
.deal_modal-changes.pipeline-modal-main .inner_box {
  min-height: auto;
}

.home_chart_wrapper.manager {
  .saRe_card_wrapper {
    padding-left: 20px;
    padding-right: 0;
    @media screen and (max-width: 991px) {
      padding-left: 0px;
      margin-top: 20px;
    }
    @media screen and (max-width: 575px) {
      padding-left: 0px;
      margin-top: 0px;
    }
  }
  .sale_TPM_wrapper {
    padding-left: 20px;
    padding-right: 0;
    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 575px) {
      padding-left: 0px;
      margin-top: 0px;
    }
  }
}
// .action_btn {
// background-image: url(images/btn_shape.svg);
// mask: url(images/btn_shape.svg);
// mask-position: bottom;
// mask-repeat: no-repeat;
// mask-size: contain;
// -webkit-mask-image:  url(images/btn_shape.svg);
// -webkit-mask-position: bottom;
// -webkit-mask-repeat: no-repeat;
// -webkit-mask-size: contain;
// }

// .action_btn {
//   // background-image: linear-gradient(180deg,#081629,transparent);
//   mask-image: url(images/hello.svg);
//   -webkit-mask-image: url(images/hello.svg);
//   mask-position: top;
//   -webkit-mask-position: top;
//   mask-size: cover;
//   -webkit-mask-size: cover;
//   mask-repeat: no-repeat;
//   -webkit-mask-repeat: no-repeat;
//   position: relative;
//   }
//   .action_btn::before{
//     position: absolute;
//     content: '';
//     background-color: #0069ff;
//     width: calc(100% + 20px);
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     height: calc(100% + 20px);
//   }

// body {
//   .action-intrest__left {
//     gap: 2px;
//   .action_btn {
//     width: 146px;
//     height: 36px;
//     background: #E6EDF1;
//     clip-path: path("M1.73181 5.5161C0.0445801 3.51616 2.12222 1 5.46084 1H126.31C127.845 1 129.264 1.56478 130.039 2.4839L142.272 16.9839C143.063 17.9203 143.063 19.0797 142.272 20.0161L130.039 34.5161C129.264 35.4352 127.845 36 126.31 36H5.46084C2.12222 36 0.0445801 33.4838 1.73179 31.4839L10.5538 21.0268C11.8703 19.4662 11.8703 17.5338 10.5538 15.9732L1.73181 5.5161Z");
//     // background-image: url(images/btn_shape.svg);
//     border: 0;
//     padding: 4px 15px 4px 10px;
//     gap: 6px;
//     .action_btn__icon img {
//       filter: brightness(0);
//     }
//   }
//   .action_btn.active {
//     background: #36ce8d;
//     color: #ffffff;
//     .action_btn__icon img {
//       filter: brightness(100);
//     }
//   }

// }
// }

// body {
//   .action-intrest__left {
//     gap: 14px;
//   .action_btn {
//     position: relative;
//     border-radius: 6px 0px 0 6px;

//     &::before {
//       content: "";
//       left: -2px;
//       position: absolute;
//       height: 0;
//       border-top: 18px solid transparent;
//       border-left: 13px solid white;
//       border-bottom: 18px solid transparent;
//       border-radius: 0;
//       z-index: 1;
//     }
//     &::after {
//       content: "";
//       right: -13px;
//       position: absolute;
//       height: 0;
//       border-top: 16px solid transparent;
//       border-left: 13px solid #ffffff;
//       border-bottom: 16px solid transparent;
//       border-radius: 0;
//       pointer-events: none;
//     }
//     height: 36px;
//     border: 2px solid #e6edf1;
//     padding: 4px 10px 4px 20px;

//   }
//   .action_btn.active {
//     background: #36ce8d;
//     color: #ffffff;
//     &::after {
//       content: "";
//       right: -13px;
//       position: absolute;
//       height: 0;
//       border-top: 16px solid transparent;
//       border-left: 13px solid #36ce8d;
//       border-bottom: 16px solid transparent;
//       border-radius: 0;
//       pointer-events: none;
//     }
//     .action_btn__icon img {
//       filter: brightness(100);
//     }
//   }
//   .left__shape {
//     content: "";
//     left: 0;
//     position: absolute;
//     height: 0;
//     border-top: 18px solid transparent;
//     border-left: 14px solid #e6edf1;
//     border-bottom: 18px solid transparent;
//     border-radius: 0;
//     pointer-events: none;
//     z-index: 0;
//   }
//   .right__shape {
//     content: "";
//     right: -16px;
//     position: absolute;
//     height: 0;
//     border-top: 18px solid transparent;
//     border-left: 14px solid #e6edf1;
//     border-bottom: 18px solid transparent;
//     border-radius: 0;
//     pointer-events: none;
//     z-index: 0;
//   }
// }
// }

body {
  .action-intrest__left {
    gap: 20px;
    .action_btn {
      position: relative;
      border-radius: 6px 0px 0 6px;
      height: 37px;
      border: 0;
      padding: 4px 6px 4px 6px;
      background: #e6edf1;
      margin-left: 10px;

      &::before {
        content: '';
        left: -10px;
        position: absolute;
        width: 15.39px;
        height: 37px;
        background-image: url(images/greyBtnBack.svg);
        background-repeat: no-repeat;
        z-index: 1;
      }
      &::after {
        content: '';
        right: -14px;
        position: absolute;
        width: 17.64px;
        height: 37px;
        background-image: url(images/greyBtnFront.svg);
        background-repeat: no-repeat;
      }
      .action_btn__icon img {
        filter: brightness(0);
      }
    }
    .action_btn.active {
      background: #36ce8d;
      color: #ffffff;
      &::after {
        background-image: url(images/greenBtnFront.svg);
      }
      &::before {
        background-image: url(images/greenBtnBack.svg);
      }
      .action_btn__icon img {
        filter: brightness(100);
      }
      .right__shape {
        background: #36ce8d;
      }
    }
    .right__shape {
      content: '';
      right: -1px;
      position: absolute;
      height: 36px;
      border-top: none;
      border-left: none;
      border-bottom: none;
      border-radius: 0;
      pointer-events: none;
      z-index: 0;
      width: 2px;
      background: #e6edf1;
      opacity: 0;
    }
  }
}
body .action-intrest__left .left__shape {
  display: none;
}

.tab_contant__actionsnew .relative.right-action,
.tab_contant__transactions .relative.right-action {
  position: relative;
}

.tab_contant__actionsnew .relative.right-action .btn_white-border,
.tab_contant__transactions .relative.right-action .btn_white-border {
  position: absolute;
  right: 6px;
  top: -30px;
}

/*01-01-2024*/
.commissions-part-title {
  font-size: 20px;
  font-family: 'Roboto';
  letter-spacing: 0.8px;
}
.commissions-part {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 30px;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}
.select-commissions {
  text-align: center;
}
.select-commissions .checkbox_x {
  justify-content: center;
}
.checkbox_x {
  display: flex;
  gap: 15px 40px;
}
.checkbox_gray {
  .custom_radio_box {
    position: relative;
    margin-bottom: 0px;

    &:last-child {
      margin-bottom: 0px;
    }

    input {
      position: absolute;
      top: 5px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 18px;
      height: 18px;
      z-index: 2;
      opacity: 0.00000001;
      cursor: pointer;
    }

    label {
      color: $black_coral;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0px;
      position: relative;
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 18px;
        height: 18px;
        z-index: 1;
        border: 1px solid #606f80;
        background-color: transparent;
        border-radius: 2px;
        transition: 0.3s;
        border-radius: 50%;
        box-sizing: border-box;
      }

      &::after {
        content: '';
        width: 7px;
        height: 3px;
        position: absolute;
        top: 5px;
        left: 5px;
        transform: rotate(-45deg);
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        z-index: 1;
        transition: 0.3s;
        opacity: 0;
      }
    }

    input:checked + label {
      &::before {
        background-color: #606f80;
        border: 1px solid #606f80;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
.fixed-rate-commission .boxGap {
  max-width: 570px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.comission-new {
  margin-bottom: 50px;
}

.tiered-rate-commission .select-commissions {
  margin-bottom: 50px;
}
.timePeriodsTblwrap {
  max-width: 660px;
  margin: 0 auto;
  margin-bottom: 55px;
  padding-left: 35px;
}
.timePeriodsTbl-header-colwrap {
  background: #2775bd;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #2775bd;
  width: 100%;
  @media screen and (max-width: 1365px) {
    font-size: 14px;
  }
}
.timePeriodsTbl-header {
  display: flex;
}
.timePeriodsTbl-header-box-btn .header-btn {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.timePeriodsTbl-header-box-btn .header-btn,
.timePeriodsTbl-body-box-btn .body-btn {
  width: 40px;
  height: 54px;
  border: 2px solid transparent;
  margin-left: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.timePeriodsTbl-header .timePeriodsTbl-header-col {
  padding: 18px 15px;
  width: 100%;
  text-align: center;
  height: 55px;
}
.timePeriodsTbl-body .timePeriodsTbl-header-col {
  padding: 12px 30px;
  width: 100%;
  text-align: center;
}
.timePeriodsTbl-header-box-btn,
.timePeriodsTbl-body-box-btn {
  display: flex;
  min-width: 90px;
}
.timePeriodsTbl-body-colwrap {
  background: #ffffff;
  color: #000000;
  display: flex;
  font-size: 16px;
  border-color: #dedede;
  box-sizing: border-box;
  border: 1px solid #dedede;
  border-width: 0px 1px 0px 1px;
  width: 100%;
  @media screen and (max-width: 1365px) {
    font-size: 14px;
  }
}

.timePeriodsTbl-body-col {
  padding: 10px 6px;
  width: 100%;
  text-align: center;
  height: 55px;
  border: 1px solid #e6edf1;
  font-weight: 500;
  line-height: 36px;
}
.timePeriods-input-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  border-radius: 8px;
}

.timePeriods-input-inner .line-between {
  min-width: 24px;
}

.revenue-from input,
.revenue-top input {
  border: none;
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  height: 44px;
}
.revenue-top input {
  text-align: right;
}
.timePeriodsTbl-body-row {
  display: flex;
}
.timePeriodsTbl-body .timePeriodsTbl-body-row:last-child .timePeriodsTbl-body-colwrap {
  border-radius: 0px 0px 8px 8px;
  border-width: 0px 1px 1px 1px;
  .timePeriodsTbl-body-col:first-child {
    border-radius: 0px 0px 0px 8px;
  }
  .timePeriodsTbl-body-col:last-child {
    border-radius: 0px 0px 8px 0px;
  }
}
.all-biling-text {
  color: #0b1222;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
  letter-spacing: 0.64px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.add-sales-team-modal .add_sales-cn-wrapper .left-cn .single-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.add-sales-team-modal .add_sales-cn-wrapper .left-cn .single-wrapper .cs_form_row {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.boxGap-tiered {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.add-sales-team-modal .add_sales-cn-wrapper .left-cn .three-wrapper .cs_form_row {
  width: 33.33%;
  padding-left: 10px;
  padding-right: 10px;
}
.add-sales-team-modal .add_sales-cn-wrapper .left-cn .three-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.timePeriods-input {
  z-index: 9;
  position: relative;
  top: -40px;
}
.timePeriods-input:before {
  content: '';
  height: 100%;
  position: fixed;
  width: 100%;
  background: rgba(1, 24, 61, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.timePeriods-submit input {
  background-color: #518ef8;
  color: #ffffff;
  border: none;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  border-radius: 10px;
  padding: 15px 40px;
  cursor: pointer;
  margin-right: 0;
  transition: 0.3s all;
}
.timePeriods-submit {
  margin-top: 20px;
}
// Commistion Setting
.commission-structure-select {
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 15px;
}

.commission-structure-select h3 {
  font-size: 20px;
  font-family: Roboto;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 10px;
}
.commission-structure-wrap {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.commission-structure-wrap .commission-structure-inner {
  flex: 1;
  @media screen and (max-width: 991px) {
    flex: 100%;
  }
}

.commission-structure-inner .ff_faq_box {
  width: 100%;
  margin-bottom: 0;
}
.commission-structure-inner .ff_faq_box .inner_box,
.commission-structure-inner .ff_faq_box,
.commission-structure-inner .ff_faq_row,
.commission-structure-inner .ff_faq_box .inner_box .ff_faq_contant,
.commission-structure-inner .ff_faq_box .ff_faq_contant_inner,
.commission-structure-inner .ff_faq_box .ff_notification_ss_box {
  height: 100%;
}
.checkbox_center {
  justify-content: center;
}
.checkbox_blue-circle {
  .custom_radio_box {
    position: relative;
    margin-bottom: 0px;

    &:last-child {
      margin-bottom: 0px;
    }

    input {
      position: absolute;
      top: 5px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 18px;
      height: 18px;
      z-index: 2;
      opacity: 0.00000001;
      cursor: pointer;
    }

    label {
      color: $black_coral;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0px;
      position: relative;
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 18px;
        height: 18px;
        z-index: 1;
        border: 1px solid #606f80;
        background-color: transparent;
        border-radius: 2px;
        transition: 0.3s;
        border-radius: 50%;
        box-sizing: border-box;
      }

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        top: 2px;
        left: 2px;
        transform: rotate(-45deg);
        border-left: none;
        border-bottom: none;
        z-index: 1;
        transition: 0.3s;
        opacity: 0;
        background: transparent;
        border-radius: 100%;
        border: 1px solid #ffffff;
      }
    }

    input:checked + label {
      &::before {
        background-color: #276cbd;
        border: 1px solid #276cbd;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
.commission_product-wrap {
  display: flex;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}
.commission_product-wrap .commission_setting-wrap.offer-wrap {
  margin-top: 0;
  max-width: 100%;
}
.commission_setDefault-modifyRate-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}
.commission_setDefault-Block,
.commission_modifyRate-Block {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 24px 20px;
}
.commission_products-services-block {
  flex: 1;
  @media screen and (max-width: 991px) {
    flex: 100%;
  }
}
.commission_modifyRate-Block {
  .modify-rate {
    max-width: 360px;
    margin-bottom: 0;
  }
  .deal_modal.table_commission {
    margin-top: 30px;
  }
}
.commission-structure-select {
  .checkbox_blue-circle {
    flex-wrap: wrap;
    .custom_radio_box label {
      font-size: 10px;
    }
  }
}

.setting-time-periods {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 24px 15px;
  .select-commissions {
    margin-bottom: 30px;
  }
  .timePeriodsTblwrap {
    margin-bottom: 0;
  }
}
.tier-thresholds-block {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 24px 15px;
}
.tier-thresholds-text {
  font-size: 14px;
  line-height: 180%; /* 25.2px */
  letter-spacing: 0.56px;
  max-width: 442px;
}
.tiered-commissions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.products-services-block {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 24px 15px;
  .products-services-inside {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .boxGap {
      margin-bottom: 0;
      margin-left: 0;
      max-width: 350px;
      .cs_form_row {
        margin-bottom: 0;
      }
    }
    .cs_form_row .extra_icon_wrap {
      position: relative;
      input {
        position: relative;
        z-index: 2;
        background-color: #ffffff;
        border: 2px solid #33485f;
        font-size: 17px;
        padding: 16px 20px;
        border-radius: 8px;
      }
      label {
        color: #001b38;
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 0px;
        padding: 0px 5px 0px 5px;
        width: auto;
        position: absolute;
        top: 17px;
        left: 20px;
        transition: 0.5s;
        z-index: 2;
        font-family: 'Red Hat Display';
        pointer-events: none;
      }
      .custom_date_label.fcw_value .cs_form_label,
      input.fcw_value + .cs_form_label {
        top: -7px;
        background-color: $white;
        // background-image:linear-gradient(#f5f6fa,#fff);
        z-index: 2;
        font-size: 12px;
        color: $green-blue;
        padding: 0px 5px 0px 5px;
      }
    }
  }
}
.tiered-rate-commission {
  margin-bottom: 50px;
}
.timePeriodsTbl-body-box-btn button.add-more-btn {
  margin: 0 !important;
  width: 42px;
}
.timePeriodsTbl-body-col .timePeriodsTbl-body-col-input {
  border: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  font-weight: 500;
  line-height: 36px;
  font-size: 16px;
  background: #ffffff;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}
.timePeriodsTbl-body-col .timePeriodsTbl-body-col-input::-webkit-input-placeholder,
.timePeriodsTbl-body-col .timePeriodsTbl-body-col-input::placeholder {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.timePeriodsTbl-body-col .timePeriodsTbl-body-col-input::-internal-autofill-selected,
.timePeriodsTbl-body-col .timePeriodsTbl-body-col-input::-internal-autofill-selected {
  background-color: '#ffffff';
}

.boxGap-tiered button.add-more-btn {
  margin-bottom: 0 !important;
}

.add-sales-team-modal .add_sales-cn-wrapper .left-cn .boxGap-tiered .single-wrapper .cs_form_row {
  margin-bottom: 0;
}
.fixed-rate-commissions .select-commissions.second-text .all-biling-text {
  max-width: 465px;
  margin-left: 0;
  text-align: left;
  font-size: 14px;
  margin-bottom: 30px;
}
.fixed-rate-commissions .select-commissions.second-text .checkbox_x {
  justify-content: start;
}

.products-services-inside-new {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .boxGap {
    .cs_form_row {
      margin-bottom: 0;
    }
  }
  .cs_form_row .extra_icon_wrap {
    position: relative;
    input {
      position: relative;
      z-index: 2;
      background-color: #ffffff;
      border: 2px solid #33485f;
      font-size: 17px;
      padding: 16px 20px;
      border-radius: 8px;
    }
    label {
      color: #001b38;
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 0px;
      padding: 0px 5px 0px 5px;
      width: auto;
      position: absolute;
      top: 17px;
      left: 20px;
      transition: 0.5s;
      z-index: 2;
      font-family: 'Red Hat Display';
      pointer-events: none;
    }
    .custom_date_label.fcw_value .cs_form_label,
    input.fcw_value + .cs_form_label {
      top: -7px;
      background-color: $white;
      // background-image:linear-gradient(#f5f6fa,#fff);
      z-index: 2;
      font-size: 12px;
      color: $green-blue;
      padding: 0px 5px 0px 5px;
    }
  }
}

.tiered-commissions-setting {
  .tiered-rate-commission {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }
  .time-periods-wrap {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    padding: 24px 20px;
    .timePeriodsTblwrap {
      margin-bottom: 0;
      @media screen and (max-width: 1600px) {
        padding-left: 0;
      }
    }
  }
  .select-commissions.second-text {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    padding: 24px 20px;
    margin-bottom: 0;
    .all-biling-text {
      max-width: 465px;
      margin-left: 0;
      text-align: left;
      font-size: 14px;
      margin-bottom: 30px;
    }
    .checkbox_x {
      justify-content: start;
    }
  }
  .products-services-inside-new .boxGap.boxGap-tiered {
    margin-left: 0;
    margin-bottom: 0;
  }
  .products-services-inside-new {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    padding: 24px 20px;

    .boxGap.boxGap-tiered:last-child {
      margin-bottom: 0 !important;
    }
  }
  .cs_form_submit_row {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    padding: 24px 20px;
    margin-top: 0;
  }
}

.commission_factored_modal .deal_modal .data_table_wrapper .default_rate_table.margin-table .table_header .company,
.commission_factored_modal .deal_modal .data_table_wrapper .default_rate_table.margin-table .table_body .company {
  width: 40%;
}
.commission_factored_modal .deal_modal .data_table_wrapper .default_rate_table.margin-table .table_header .margin,
.commission_factored_modal .deal_modal .data_table_wrapper .default_rate_table.margin-table .table_body .margin {
  width: 40%;
}
.commission_factored_modal .deal_modal .data_table_wrapper .default_rate_table.margin-table .table_body input {
  max-width: 100%;
}
// individual team members Tired
.commission_factored_modal
  .deal_modal
  .data_table_wrapper
  .default_rate_table.default_rate_table-tired
  .table_header
  .company,
.commission_factored_modal
  .deal_modal
  .data_table_wrapper
  .default_rate_table.default_rate_table-tired
  .table_body
  .company {
  width: 50%;
  text-align: center;
  font-size: 14px;
}
.commission_factored_modal
  .deal_modal
  .data_table_wrapper
  .default_rate_table.default_rate_table-tired
  .table_header
  .rate,
.commission_factored_modal
  .deal_modal
  .data_table_wrapper
  .default_rate_table.default_rate_table-tired
  .table_body
  .rate {
  width: 50%;
  font-size: 14px;
}
.deal_modal .data_table_wrapper {
  .default_rate_table-tired {
    .table_header {
      margin-bottom: 0px;
      .table_row {
        &:before {
          background-color: #2775bd;
          border-radius: 8px 8px 0px 0px;
        }
        .table_td {
          color: #ffffff;
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .table_body {
      .table_row {
        padding: 0;
        border: 1px solid #e6edf1;
        border-width: 0px 1px 0px 1px;
        border-radius: 0;
        &:last-child {
          border-bottom: 1px solid #e6edf1;
          border-radius: 0px 0px 8px 8px;
          .table_td {
            &.company {
              border-radius: 0px 0px 0px 8px;
            }
            &.rate {
              border-radius: 0px 0px 8px 0px;
            }
          }
        }
        .table_td {
          height: 50px;
          border: 1px solid #e6edf1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000000;
          font-weight: 500;
        }
      }
    }
  }
}

.cs_moda_main_wrapper {
  .comission_setting-changes {
    .ff_faq_box {
      .inner_box {
        padding: 0;
        max-width: 100%;
        width: 100%;
      }
    }
    .commission-structure-wrap {
      .commission-structure-inner {
        flex: 100%;
      }
    }
    .commission-structure-inner .cs_form_submit_row {
      position: relative !important;
      bottom: 0 !important;
      left: 0;
      right: auto !important;
      justify-content: start !important;
      input[type='submit'] {
        padding: 5px 20px !important;
        font-size: 12px !important;
        border-radius: 5px;
      }
    }
  }
}

.deal-tiered-wrap .deal_modal .data_table_wrapper .default_rate_table .table_header .table_td,
.deal-tiered-wrap .deal_modal .data_table_wrapper .default_rate_table .table_body .table_td {
  width: 50%;
}
.commission-structure-wrap .commission-structure-select .submit-btn-with-process input {
  padding: 5px 20px !important;
  font-size: 12px !important;
  border-radius: 5px;
  background-color: #36ce8d;
  color: #ffffff;
  font-weight: 400;
  border: 1px solid #36ce8d;
  margin: 0px;
  font-family: 'Red Hat Display';
  letter-spacing: 0.04em;
  line-height: 20px;
  transition: 0.5s;
  &:hover {
    background-color: #27ae75;
    border: 1px solid #27ae75;
  }
}
.default_rate_table-tired button.loader_active {
  position: relative;
}

.sale_TPM_wrapper.sale_TPM_wrapper_people.sale_TPM_wrapper_people_full {
  width: 100%;
}
.custom_radio_box_default {
  font-size: 12px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0;
  }
  input[type='radio'] {
    margin: 0;
  }
}
.commission-structure-select .checkbox_blue-defult {
  margin-top: 20px;
  flex-wrap: wrap;
}
.commission_setting_tab .offer-wrap .inviteUserHeader .title {
  font-size: 20px !important;
  color: #222b2e;
  font-family: Roboto;
}

body .deal_modal .data_table_wrapper .table_body .table_rowLS .delete_btn img {
  width: auto;
  height: 16px;
}

body .deal_modal .data_table_wrapper .table_body .table_rowLS .delete_btn {
  padding: 0;
}

.task_filed_wrap .filed_wrap .detail_filed {
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.task_filed_wrap .filed_wrap {
  flex-wrap: wrap;
}
.task_filed_wrap .filed_wrap .lbl_filed {
  padding-bottom: 4px;
}
.lead-page .lead-card .customTable_V1 {
  max-height: 64vh;
  min-height: 64vh;
}
.lead-page .lead-card .customTable_V1 > table > thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.deal_modal-changes .inner_box.inner_box_task {
  min-height: auto !important;
}

.lead-page .lead-card .customTable_header_V1 {
  position: relative;
  z-index: 2;
}
//Setting Tab

.employee_dashboard_form_wrap {
  display: flex;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.employee_dashboard_form_wrap .employee_dashboard-block {
  flex: 1;
  @media screen and (max-width: 991px) { 
    flex: 100%;
  }
}
.employee_dashboard-block .ff_faq_box {
  width: 100%;
  margin-bottom: 0;
}
.sales-stage-block {
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 24px 15px;
  width: 100%;
}

.sales-stage-block .title {
  font-size: 20px;
  color: #222b2e;
  font-family: Roboto;
  margin-bottom: 5px;
  font-weight: 700;
}

.sales-stage-block .text {
  font-weight: 400;
  font-size: 14px;
}
.sales-stage-tbl .data_table_responsive .table_header .table_row{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 14px 0px;
  position: relative;
  width: 100%;
}
.sales-stage-tbl .table_header .table_td {
  color: #525e6b;
  font-size: 12px;
  font-weight: 400;
  position: relative;
}
.sales-stage-tbl .table_header .table_td.default-number,
.sales-stage-tbl .table_body .table_td.default-number,
.sales-stage-tbl .table_header .table_td.custom-number,
.sales-stage-tbl .table_body .table_td.custom-number {
  width: 20px;
}
.sales-stage-tbl .table_header .table_td , 
.sales-stage-tbl .table_body .table_td {
  width: calc(50% - 40px);
  padding: 0px 10px;
}
.sales-stage-tbl .data_table_responsive .table_header .table_row:before {
  content: '';
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f6f8fa;
  border-radius: 5px;
}
.sales-stage-tbl .table_body .table_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 0px;
  position: relative;
  font-size: 12px;
}
.sales-stage-tbl .table_td .sales-stage-input input {
  border: 2px solid #e6edf1;
  border-radius: 5px;
  color: #222b2e;
  font-size: 12px;
  font-weight: 300;
  padding: 8px 12px;
  width: 100%;
  text-align: left;
}
.sales-stage-tbl .table_header {
  margin-bottom: 5px;
}
// action Customers

.action_item-active-opp .active_txt {
  color: #2775bd;
}

.action_item-active-opp .circle {
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
}
.circle_dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.circle_dot__green {
  background: #43b886;
}
.circle_dot__red {
  background: #ff445a;
}
.circle_dot__orange {
  background: #ffa412;
}

//Trial Setting
.edit-same {
  .timePeriods-input__left {
    flex: 1;
    text-align: right;
  }
  .timePeriods-input__right {
    display: flex;
    flex: 1;
    align-items: center;
    .revenue-top input {
      font-weight: 500;
      font-family: 'Roboto';
      text-align: left;
      padding: 0px 1px;
      height: auto;
    }
  }
}
.range-show  {
  display: flex;
  justify-content: center;
  .line-between {
    min-width: 24px;
    display: inline-block;
  }
  .range-show__left {
      flex: 1;
      text-align: right;
  }
  .range-show__right {
    flex: 1;
    text-align: left;
  }
}

.renewal-modal {
  .inner_box {
    width: 360px;
  }
}



.deal_modal  .data_table_wrapper .data_table_margin .table_header .company,
.deal_modal  .data_table_wrapper .data_table_margin .table_body .company {
  width: 25%;
}
.deal_modal  .data_table_wrapper .data_table_margin .table_header .deal_size,
.deal_modal  .data_table_wrapper .data_table_margin .table_body .deal_size {
  width: 15%;
}
.deal_modal  .data_table_wrapper .data_table_margin .table_header .margin,
.deal_modal  .data_table_wrapper .data_table_margin .table_body .margin {
  width: 12%;
}

// propsal tab 
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_header .company,
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_body .company {
  width: 22%;
}
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_header .deal_size, 
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_body .deal_size {
  width: 12%;
}
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_header .margin, 
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_body .margin {
  width: 12%;
}
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_header .commission, 
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_body .commission {
  width: 14%;
}
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_header .date, 
.deal_modal .data_table_wrapper.proposals-tab .data_table_margin .table_body .date {
  width: 14%;
}
.deal_modal .data_table_wrapper .margin-table .table_body .won_btn {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.deal_modal  .margin-table button.loader_active.won_btn .process {
  position: relative;
  top: unset;
  left: unset;
  transform: none;
}
// Connected Modal

.cs_modal_v1.model-m-590 .inner_box {
  width: 560px;
  padding: 40px 20px 20px;
}
.connected-top-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.connected-top-btn .btn_lg {
  width: 100%;
  border-radius: 4px;
  background: #E6EDF1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 18px;
  justify-content: center;
  font-size: 12px;
}
.connected-top-btn .or-text {
  padding: 0px 34px;
  @media screen and (max-width: 575px) {
    padding: 6px 34px;
    width: 100%;
    text-align: center;
  }
  
}
.connected-top-btn .btn_lg img {
  filter: brightness(0);
}

.connected-top-btn .btn_lg.active {
  background: #2775BD;
  color: #ffffff;
}

.connected-top-btn .btn_lg.active img {
  filter: brightness(0) invert(1);
}
.conntected-note .note-input {
  width: 100% !important;
  border: 2px solid #222B2E;
  border-radius: 4px;
  height: 150px;
  padding: 15px;
  font-size: 14px;
  resize: none;
}
.conntected .conntected-note .note-input {
  border: 2px solid #222B2E;
}
.conntected-bottom button:disabled {
  background: #E6EDF1;
  pointer-events: none;
}
.conntected-note .note-input:disabled {
  background: #ffffff;
  border: 2px solid #E6EDF1;
}

.tab_contant__notes__action .chatBox__notes .chatMsg .chipTbl {
    width: 90px;
    text-align: center;
}
.cs_modal_v1.model-m-590 .inner_box.interested-modal-box {
  width: 410px !important;
}
.day-age-block {
  margin-top: 20px;
}
.day-age-block .start-range {
    display: flex;
    align-items: center;
    justify-content: center;
}

.day-age-block .start-range .start-range-left,.day-age-block .start-range .start-range-right {
    width: 50px;
}
.day-age-block .start-range .to-text {
  padding: 0px 14px;
}
.day-age-block .start-range .start-range-left .start-range-text {
  display: block;
  text-align: right;
  width: 100%;
}
.day-age-block .table_td.custom {
  text-align: center;
}
.day-age-block .start-range  .andup-text {
  padding: 0px 14px;
  width: 90px;
  text-align: left;
}
.employee_dashboard_form  .cs_form_submit_row.cs_form_submit_row-right {
  justify-content: right;
}

// Action
.button-set {
  display: flex;
  justify-content: end;
  gap: 10px;
}
.outline-black-btn {
  padding: 6px 10px;
  font-size: 13px;
  color: #000000;
  background: linear-gradient(262deg, #fff -14.54%, #fff 114.3%);
  letter-spacing: 0.52px;
  font-weight: 400;
  font-family: 'Roboto';
  gap: 10px;
  display: flex;
  align-items: center;
  border: 2px solid #1f2e43;
  border-radius: 3px;
}
.customTable_V1 .salesTable-middle tbody tr td {
  vertical-align: middle;
}
.customTable_V1 .green_text {
  color: #48b98d;
  cursor: pointer
}

// Sales Tab

// propsal tab 
.deal_modal .data_table_wrapper.sales-tab  .table_header .company,
.deal_modal .data_table_wrapper.sales-tab  .table_body .company {
  width: 24%;
}
.deal_modal .data_table_wrapper.sales-tab  .table_header .deal_size, 
.deal_modal .data_table_wrapper.sales-tab  .table_body .deal_size {
  width: 12%;
 
}
.deal_modal .data_table_wrapper.sales-tab  .table_header .proposal_Size, 
.deal_modal .data_table_wrapper.sales-tab  .table_body .proposal_Size {
  width: 15%;
 
}
.deal_modal .data_table_wrapper.sales-tab  .table_header .win_rate, 
.deal_modal .data_table_wrapper.sales-tab  .table_body .win_rate {
  width: 10%;
 
}
.deal_modal .data_table_wrapper.sales-tab  .table_header .commission, 
.deal_modal .data_table_wrapper.sales-tab  .table_body .commission {
  width: 13%;
}
.deal_modal .data_table_wrapper.sales-tab  .table_header .date, 
.deal_modal .data_table_wrapper.sales-tab  .table_body .date {
  width: 14%;
}

// .deal_modal .data_table_wrapper.sales-tab  .table_row_total .deal_size{
//   width: 15%;
// }
// .deal_modal .data_table_wrapper.sales-tab  .table_row_total .commission{
//   width: 12%;
// }


.deal_modal .data_table_wrapper.sales-tab .table_header .table_td, 
.deal_modal .data_table_wrapper.sales-tab .table_body .table_td {
  padding: 0px 8px;
  font-size: 12px;
}



/*commission_structure*/

.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_header .company, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_body .company {
  width: 26%;
  word-break: break-word;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_header .proposal_Size, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_body .proposal_Size {
  width: 15%;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_header .deal_size, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_body .deal_size {
  width: 15%;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_header .win_rate, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.commission_structure .table_body .win_rate {
  width: 12%;
}

/*commission_structure ON*/

.deal_modal .data_table_wrapper.sales-tab .data_table_responsive .table_header .company, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive .table_body .company {
  width: 24%;
  word-break: break-word;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive .table_header .proposal_Size, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive .table_body .proposal_Size {
  width: 15%;
}

/*commission_structure Data Margin ON*/

.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin  .table_header .proposal_Size, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_body .proposal_Size {
  width: 12%;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_header .margin, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_body .margin {
  width: 10%;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_header .company, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_body .company {
  width: 20%;
  word-break: break-word;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_header .rate, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_body .rate {
  width: 60px;
}
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_header .commission, 
.deal_modal .data_table_wrapper.sales-tab .data_table_responsive.data_table_margin .table_body .commission {
  width: 12%;
}
.outline-black-btn {
  border-radius: 3px;
  transition: all 0.3s;
}
// Orange BTN With IMG
.outline-orange-btn {
  color: #FFA412;
  border: 2px solid #FFA412;
}
.outline-orange-btn img {
  filter: invert(76%) sepia(47%) saturate(3385%) hue-rotate(348deg) brightness(101%) contrast(102%);
}
.outline-orange-btn:hover {
  background: #FFA412;
  color: #ffffff;
}
.outline-orange-btn:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

// Green BTN With IMG
.outline-green-btn {
  color: #43B886;
  border: 2px solid #43B886;
}
.outline-green-btn img {
  filter: invert(60%) sepia(60%) saturate(391%) hue-rotate(102deg) brightness(92%) contrast(89%);
}
.outline-green-btn:hover {
  background: #43B886;
  color: #ffffff;
}
.outline-green-btn:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.edit-del-btn {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.edit-del-btn .btn img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.action-bottom-tab {
  margin-top: 28px;
}
.action-bottom-tab .connect-tbl-btn {
  position: relative;
  right: 0;
  top: 0;
}

.fcw_value_select  label.cs_form_label {
  top: -5px;
  padding: 0px 5px 0px 5px;
  background: #fff;
  left: 10px;
  color: #206cb4;
  font-weight: 400;
}

.fcw_value_select {
  position: relative;
}