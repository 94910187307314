.nav-tabs-wrap {
    .nav-tabs {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        gap: 20px;
        
        .nav-item {
            font-size: 12px;
            font-family: "Roboto";
            display: inline-block;
            color: #222b2e;
            padding: 10px 0px;
            position: relative;
            border-bottom: 2px solid transparent;
        
            .count {
                color: #2775BD;
                margin-left: 5px;
                transition: .5s;
            }
          &.active-a {
            border-color:#2775BD;
          }
        }   
    }
    
}

.btnNew_plus {
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 2px solid #EDEFEF;
    img {
        width: 22px;
        height: 22px;
    }
}

.card {
    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 16px 20px 0px 27px;
        border-bottom: 2px solid #f5f6fb;
        margin-bottom: 20px;
    }
}

body .people_WLdata_box__padding {
    padding-top: 0;
    .nav-tabs-wrap {
        width: calc(100% - 38px);
        overflow: scroll;
        margin-bottom: -2px;
    }
}
.cursor-pointer {
    cursor: pointer;
}
.btnNew_plus_warp {
    position: relative;
    top: -8px;
}

.noscroll {
    scrollbar-width: none;
}
.noscroll::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Sales*/
.btnPlus_border {
    border-radius: 6px;
    border: 2px solid #EDEFEF;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-family: "Roboto";
    padding: 3px;
    transition: 0.5s;

   &:hover {
    border-color:#2775BD;
}
}

.dealModal__btns {
    max-width: 200px;
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 767px) {
        max-width: 100%;
    }
}
.bg-white {
    background-color: #ffffff;
}
.mt-30 {
    margin-top: 30px;
}

.card__header__two {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    border-bottom: 2px solid #f5f6fb;
    margin-bottom: 20px;

    .nav-tabs-wrap .nav-tabs {
        gap: 30px;
        @media (max-width: 1440px) {
            gap: 18px;
        }
    }
}
.table_body__scroll {
    max-height: 495px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 15px;
      }
      
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      
      &::-webkit-scrollbar-thumb:vertical {
        height: 30px;
        background-color: $color_link_water_approx;
        border-radius: 10px;
      }
}

.rightC_wrapper_sales {
 min-height: 100vh;
}
.card__header__two {
    .nav-tabs-wrap {
    width: calc(100% - 38px);
    overflow: scroll;
    margin-bottom: -2px;
  }
}

.cs_form_row_three {
    display: flex;
    @include respond-to('phone-md') {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        
    }
}

.alertBox {
    padding: 16px 16px 16px 28px;
    border-radius: 5px;
    background: rgba(249, 159, 66, 0.10);
    margin-bottom: 25px;
    .close {
        cursor: pointer;
    }
    .title {
        color: #F6862F;
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 0.56px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 11px;
    }
    p {
        color: #F6862F;
        font-size: 13px;
        font-family: "Roboto";
        line-height: 150%;
        letter-spacing: 0.52px;
        margin-bottom: 0;
    }
}
.lead-card .customTable_V1 {
    min-height: 67vh;
}

.react-datepicker-wrapper {
    width: 100%;
}
.nav-list {
    list-style: none;
}

// Setting Page 

.setting_card {
    .card__header__two {
        margin-bottom: 20px;
        padding: 10px 25px 0px;
        @media (max-width: 1440px) {
            padding: 10px 18px 0px;
        }
    }
}
.setting__container {
    padding: 25px;
    @media (max-width: 1440px) {
        padding:25px 18px;
    }
    @media (max-width: 575px) {
        padding:25px 10px;
    }
}

.setting_card .card__header__two .nav-tabs-wrap {
    width: 100%;;
}
// EMP Bounues

.totleBox__update {
    background-color: #ffffff;
    padding: 18px 30px;
    border-radius: 16px;
    margin-bottom: 30px;
    .totleBox__update__inner {
        .label {
            color: #222B2E;
            font-size: 16px;
            font-family: "Roboto";
            line-height: 160%;
            letter-spacing: 0.64px;
            margin-bottom: 0;
        }
        .number {
            color: #43B886;
            font-size: 48px;
            font-family: "Roboto";
            font-weight: 300;
            line-height: 160%;
            letter-spacing: 1.92px;
        }
    }
}
.mt-40 {
    margin-top: 40px;
}

body {
.sales_page {
    .left {
        width: 22%;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .right {
        width: 78%;
        padding-left: 0px;
        @media (max-width: 991px) {
            width: 100%;
            margin-top: 20px;
        }
    }
}
}


.user-select {
    width: 100%;
    min-width: 170px
}
.people_WLdata_box__padding {
    .card__header{
        align-items: baseline;
    }
}
.more-btn {
    color: #2775BD;
    font-size: 12px;
    letter-spacing: 0.2px;
}
.capitalize {
    text-transform: capitalize;
}

body {
.main_wrapper .rightC_wrapper-changes {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    .lead-card {
        border-radius: 0;
        margin-bottom: 0;
    }
}
}

.action-tab-TMC {
    align-items: center;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    grid-gap: 24;
    gap: 24px;
    margin-bottom: 20px;

    &__item {
        font-size: 14px;
        text-transform: capitalize;
        padding: 8px 10px;
        border-radius: 6px;
        cursor: pointer;

        &.active {
            background: #ebf0fc;
        }
    }
}

.leadTable {
    td.select_td_width {
        width: 290px;
    }
}